<template>
    <div class="g-tips"><span>{{ $t('other.notice') }}</span><span ref="parent"><strong :style="'left: '+ left +'px'" ref="content">{{text}}</strong></span></div>
</template>
<script>
import MiceChat from '@/assets/js/mice-chat'
export default {
    props: {
        tipText: String,
        isLive: Boolean
    },
    data(){
        return{
            text: '',
            timer: null,
            left: 0,
            miceChat: null,
        }
    },
    methods: {
        getText(){
            this.$http.post(this.HOST + '/cbre2021/lottery.state', {}).then(res => {
                const data = res.data
                this.text = localStorage.getItem('locale') == 'en' ? data.notify_en : data.notify
                this.run()
            })
        },
        run(){
            if(this.timer){
                this.left = 0
                clearInterval(this.timer)
                this.timer = null
            }
            setTimeout(() => {
                var parentWidth = this.$refs.parent.offsetWidth
                var contentWidth = this.$refs.content.offsetWidth
                if(contentWidth > parentWidth){
                    this.timer = setInterval(() => {
                        this.left -= 1
                        if(this.left <= contentWidth * -1){
                            this.left = parentWidth
                        }
                    },30)
                }
            }, 100)
        },
        chatInit(){
            this.miceChat = new MiceChat(653, 0, '');
            this.miceChat.on('connection', () => {

            }).on('other', (data) => {
                if(data.type == 50){
                    this.text = localStorage.getItem('locale') == 'en' ? data.data.notify_en : data.data.notify
                    this.run()
                }
            })

            this.miceChat.init();
        },
    },
    watch: {
        tipText(newText){
            this.text = newText
            this.run()
        }
    },
    mounted() {
        this.getText()
        if(!this.isLive){
            this.chatInit()
        }
        window.addEventListener('resize', () => {
            this.run()
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', function(){
            console.log('解除窗口变换监听')
        })
        if(this.timer){
            clearInterval(this.timer)
            this.timer = null
        }

    },
}
</script>
