<template>
    <div class="agenda-block">
        <swiper v-if="dataReady" class="agenda-swiper" ref="agendaSwiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) of agendaData" :key="'agenda' + index">
                <div class="item" v-if="item.type == 'speakers'">
                    <p class="time2">{{ item.time2 }}</p>
                    <p class="name">{{ item.name }}</p>
                    <p class="type">{{ item.speakers }}</p>
                    <ul class="speaker-items">
                        <li v-for="n of item.items" :key="n.key">
                            <img style="widht: 0; height: 0; opacity: 0" :src="n.avatar" alt="">
                            <div class="key">
                                <el-popover
                                    popper-class="agenda-popper-parent"
                                    placement="right-start"
                                    width="150"
                                    trigger="hover">
                                    <div class="agenda-popover2">
                                        <div class="cover" :style="'background-image: url('+ n.avatar +')'"></div>
                                        <p class="name">{{ n.key }}</p>
                                    </div>
                                    <router-link :to="'/speakers?index=' + n.index" slot="reference">{{ n.key }}</router-link>
                                </el-popover>
                            </div>

                            <div class="val">{{ n.val }}</div>
                        </li>
                    </ul>
                </div>
                <div class="item" v-else>
                    <!-- <p class="time1" style="opacity: 0;">{{ item.time1 }}</p> -->
                    <p class="time2">{{ item.time2 }}</p>
                    <p class="name">{{ item.name }}</p>
                    <ul class="breakouts-items">
                        <li v-for="n of item.items" :key="n.key">
                            <el-popover
                                placement="right-start"
                                width="260"
                                trigger="hover">
                                <div class="agenda-popover">
                                    <div v-for="(detail, detailIndex) of n.details" :key="'detail' + detailIndex">
                                        <template v-if="detail.children">
                                            <p class="title">{{ detail.title }}</p>
                                            <div v-for="(children, childrenIndex) of detail.children" :key="'children' + childrenIndex">
                                                <p class="title1">{{children.title}}</p>
                                                <div class="speaker-items">
                                                     <p v-for="(speaker, speakerIndex) of children.items" :key="'speaker' + speakerIndex" class="text">
                                                        <el-popover
                                                            popper-class="agenda-popper-parent"
                                                            placement="right-start"
                                                            width="150"
                                                            trigger="hover">
                                                            <div class="agenda-popover2">
                                                                <div class="cover" :style="'background-image: url('+ children.data[speakerIndex].avatar +')'"></div>
                                                                <p class="name">{{ speaker.split('-')[0] }}</p>
                                                            </div>
                                                            <router-link :to="'/speakers?index=' + children.data[speakerIndex].index" slot="reference">{{ speaker.split('-')[0] }}</router-link>
                                                        </el-popover>
                                                         <span> {{ speaker.split('-')[1] }}</span>
                                                     </p>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <p class="title">{{ detail.title }}</p>
                                            <div class="speaker-items">
                                                <p v-for="(speaker, speakerIndex) of detail.items" :key="'speaker' + speakerIndex" class="text">
                                                    <!-- <span>{{ speaker.split('-')[0] }}</span> -->
                                                     <el-popover
                                                        popper-class="agenda-popper-parent"
                                                        placement="right-start"
                                                        width="150"
                                                        trigger="hover">
                                                        <div class="agenda-popover2">
                                                            <div class="cover" :style="'background-image: url('+ detail.data[speakerIndex].avatar +')'"></div>
                                                            <p class="name">{{ speaker.split('-')[0] }}</p>
                                                        </div>
                                                        <router-link :to="'/speakers?index=' + detail.data[speakerIndex].index" slot="reference">{{ speaker.split('-')[0] }}</router-link>
                                                    </el-popover>
                                                    <span> {{ speaker.split('-')[1] }}</span>
                                                </p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="key" slot="reference">{{ n.key }}</div>
                            </el-popover>
                        </li>
                    </ul>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <i class="iconfont icon-left" @click="swiperPrev"></i>
        <i class="iconfont icon-right" @click="swiperNext"></i>
    </div>
</template>
<script>
export default {
    computed: {
        swiper() {
            return this.$refs.agendaSwiper.swiper
        }
    },
    data(){
        return {
            dataReady: false,
            activeIndex: 0,
            swiperOptions: {
                slidesPerView: 3,
                speed: 350,
                // centeredSlides : true,

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    transitionEnd: () => {
                        if(this.$refs.agendaSwiper.swiper){
                            const index = this.$refs.agendaSwiper.swiper.realIndex
                            this.activeIndex = index
                        }
                    }
                }
            },
            agendaData: [
                {
                    type: 'speakers',
                    time1: this.$t('agenda.item1.time1'),
                    time2: this.$t('agenda.item1.time2'),
                    name: this.$t('agenda.item1.name'),
                    speakers: this.$t('agenda.item1.type'),
                    items: [
                        {
                            index: 1,
                            key: this.$t('agenda.item1.key1'),
                            val: this.$t('agenda.item1.val1'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210810145326.jpg'
                        },
                        {
                            index: 2,
                            key: this.$t('agenda.item1.key2'),
                            val: this.$t('agenda.item1.val2'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172257.jpg'
                        },
                        {
                            index: 3,
                            key: this.$t('agenda.item1.key3'),
                            val: this.$t('agenda.item1.val3'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172311.jpg'
                        },
                        {
                            index: 4,
                            key: this.$t('agenda.item1.key4'),
                            val: this.$t('agenda.item1.val4'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172322.jpg'
                        }
                    ]
                },
                {
                    type: 'speakers',
                    time1: this.$t('agenda.item2.time1'),
                    time2: this.$t('agenda.item2.time2'),
                    name: this.$t('agenda.item2.name'),
                    speakers: this.$t('agenda.item2.type'),
                    items: [
                        {
                            index: 5,
                            key: this.$t('agenda.item2.key1'),
                            val: this.$t('agenda.item2.val1'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172341.jpg'
                        },
                        {
                            index: 6,
                            key: this.$t('agenda.item2.key2'),
                            val: this.$t('agenda.item2.val2'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172353.jpg'
                        },
                        {
                            index: 7,
                            key: this.$t('agenda.item2.key3'),
                            val: this.$t('agenda.item2.val3'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172405.jpg'
                        },
                        {
                            index: 8,
                            key: this.$t('agenda.item2.key4'),
                            val: this.$t('agenda.item2.val4'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172417.jpg'
                        }
                    ]
                },
                {
                    type: 'speakers',
                    time1: this.$t('agenda.item3.time1'),
                    time2: this.$t('agenda.item3.time2'),
                    name: this.$t('agenda.item3.name'),
                    speakers: this.$t('agenda.item3.type'),
                    items: [
                        {
                            index: 9,
                            key: this.$t('agenda.item3.key1'),
                            val: this.$t('agenda.item3.val1'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172553.jpg'
                        },
                        {
                            index: 10,
                            key: this.$t('agenda.item3.key2'),
                            val: this.$t('agenda.item3.val2'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172542.jpg'
                        },
                        {
                            index: 11,
                            key: this.$t('agenda.item3.key3'),
                            val: this.$t('agenda.item3.val3'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172534.jpg'
                        },
                        {
                            index: 12,
                            key: this.$t('agenda.item3.key4'),
                            val: this.$t('agenda.item3.val4'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172612.jpg'
                        },
                        {
                            index: 13,
                            key: this.$t('agenda.item3.key5'),
                            val: this.$t('agenda.item3.val5'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172618.jpg'
                        },
                        {
                            index: 14,
                            key: this.$t('agenda.item3.key6'),
                            val: this.$t('agenda.item3.val6'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172639.jpg'
                        },
                        {
                            index: 15,
                            key: this.$t('agenda.item3.key7'),
                            val: this.$t('agenda.item3.val7'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172651.jpg'
                        },
                        {
                            index: 16,
                            key: this.$t('agenda.item3.key8'),
                            val: this.$t('agenda.item3.val8'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172705.jpg'
                        },
                        {
                            index: 17,
                            key: this.$t('agenda.item3.key9'),
                            val: this.$t('agenda.item3.val9'),
                            avatar: 'https://img.mofyi.com/Ayto/image/20210902172712.jpg'
                        }
                    ]
                },
                {
                    type: 'breakouts',
                    time1: this.$t('agenda.item4.time1'),
                    time2: this.$t('agenda.item4.time2'),
                    name: this.$t('agenda.item4.name'),
                    items: [
                        {
                            key: this.$t('agenda.item4.key1'),
                        },
                        {
                            key: this.$t('agenda.item4.key2'),
                        },
                        {
                            key: this.$t('agenda.item4.key3'),
                        },
                        {
                            key: this.$t('agenda.item4.key4'),
                        },
                        {
                            key: this.$t('agenda.item4.key5'),
                        }
                    ]
                }
            ],
            brekoutsDetailsZh: [
                // 分会场1
                [
                    {
                        title: '主旨演讲',
                        items: [
                            '李凌-CBRE中国区总裁'
                        ],
                        data: [
                            {
                                index: 2,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910135732.jpg'
                            }
                        ]
                    },
                    {
                        title: '圆桌讨论',
                        items: [
                            '王晶-投资及资本市场部负责人，CBRE华东区',
                            '周志峰-执行董事，投资及资本市场部 | 资本顾问团队，CBRE中国区',
                            '伍佩菡-凯德集团（中国）商用中国基金总经理',
                            '朱刚-华泰证券资产管理不动产基金部执行负责人'
                        ],
                        data: [
                            {
                                index: 18,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140703.jpg'
                            },
                            {
                                index: 19,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140718.jpg'
                            },
                            {
                                index: 20,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210915133259.jpg'
                            },
                            {
                                index: 21,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140831.jpg'
                            }
                        ]
                    }
                ],
                // 分会场2
                [
                    {
                        title: '主旨演讲',
                        items: [
                            '莫非-顾问及交易服务部董事总经理，CBRE大中华区'
                        ],
                        data: [
                            {
                                index: 10,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140954.jpg'
                            },
                        ]
                    },
                    {
                        title: '圆桌讨论',
                        children: [
                            {
                                title: '主持人',
                                items: [
                                    '张越-顾问及交易服务|办公楼部负责人，CBRE华东区'
                                ],
                                data: [
                                    {
                                        index: 22,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141058.jpg'
                                    }
                                ]
                            },
                            {
                                title: '圆桌嘉宾',
                                items: [
                                    '张冀苏-顾问及交易服务部|办公楼|租户部负责人，CBRE华北区',
                                    '吴竞伯-安永大中华区房地产主管',
                                    '李艳娟-明略科技集团高级行政总监',
                                    '王红-中国新天地商业管理办公业务总经理，INNO创智项目总经理',
                                    '郝磊-建投控股总经理',
                                    '金子千夏-顾问及交易服务|工作场所策略部负责人，CBRE北亚区',
                                ],
                                data: [
                                    {
                                        index: 23,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141136.jpg'
                                    },
                                    {
                                        index: 24,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141340.jpg'
                                    },
                                    {
                                        index: 25,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141254.jpg'
                                    },
                                    {
                                        index: 26,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141328.jpg'
                                    },
                                    {
                                        index: 27,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141244.jpg'
                                    },
                                    {
                                        index: 28,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141151.jpg'
                                    }
                                ]
                            }
                        ]
                    }
                ],
                // 分会场3
                [
                    {
                        title: '主旨演讲',
                        items: [
                            '孙洁-顾问及交易服务部和投资及资本市场部产业地产负责人，CBRE中国区'
                        ],
                        data: [
                            {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            }
                        ]
                    },
                    {
                        title: '先锋对话 | 聚焦生物医药',
                        items: [
                            '孙洁-顾问及交易服务部和投资及资本市场部产业地产负责人，CBRE中国区',
                            '刘平-上海市经济和信息化委员会副主任',
                        ],
                        data: [
                             {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            },
                            {
                                index: 30,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141534.jpg'
                            }
                        ]
                    },
                    {
                        title: '先锋对话 | 共话产业地产新机遇',
                        items: [
                            '孙洁-顾问及交易服务部和投资及资本市场部产业地产负责人，CBRE中国区',
                            '王帅-易商华东区总经理、中国区副总裁',
                        ],
                        data: [
                            {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            },
                            {
                                index: 31,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141603.jpg'
                            }
                        ]
                    }
                ],
                // 分会场4
                [
                    {
                        title: '主旨演讲',
                        items: [
                            '陈学海-战略顾问部负责人，CBRE中国区'
                        ],
                        data: [
                            {
                                index: 32,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210922134141.jpg'
                            }
                        ]
                    },
                    {
                        title: '先锋对话——城市的广度：城市群 | 都市圈',
                        items: [
                            '陈宪 教授-上海交通大学安泰经济与管理学院教授，上海全球城市研究院特聘研究员',
                            '肖伟-战略顾问部负责人，CBRE华南区'
                        ],
                        data: [
                            {
                                index: 33,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141905.jpg'
                            },
                            {
                                index: 34,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141703.jpg'
                            }
                        ]
                    },
                    {
                        title: '先锋对话——城市的深度：城市更新 | 城市再生',
                        items: [
                            '徐磊青 教授-同济大学建筑与城市规划学院教授，同济大学国家现代化研究院城市更新中心主任',
                            '陆韬-战略顾问部副董事，CBRE中国区'
                        ],
                        data: [
                            {
                                index: 35,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141943.jpg'
                            },
                            {
                                index: 36,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141721.jpg'
                            }
                        ]
                    },
                    {
                        title: '先锋对话——城市的温度：城市场景 | 城市气质',
                        items: [
                            '沈从乐-第一财经·新一线城市研究所主编、《第一财经YiMagazine》杂志编委',
                            '王飞-战略顾问部负责人，CBRE江苏区域'
                        ],
                        data: [
                            {
                                index: 37,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141958.jpg'
                            },
                            {
                                index: 12,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141802.jpg'
                            }
                        ]
                    }
                ],
                // 分会场5
                [
                    {
                        title: '圆桌讨论——香港地区商业地产市场 |格局演变 / 风云变幻',
                        children: [
                            {
                                title: '主持人',
                                items: [
                                    '陈锦平-研究部负责人，CBRE香港'
                                ],
                                data: [
                                    {
                                        index: 38,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144424.jpg'
                                    }
                                ]
                            },
                            {
                                title: '圆桌嘉宾',
                                items: [
                                    '冯慧诗-顾问及交易服务|办公楼部负责人，CBRE香港',
                                    '黎尚文-顾问及交易服务|工业及物流部负责人，CBRE香港',
                                    '温运强-顾问及交易服务|商铺部负责人，CBRE香港',
                                    '甄浚岷-资本市场部负责人，CBRE香港',
                                ],
                                data: [
                                    {
                                        index: 39,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144525.jpg'
                                    },
                                    {
                                        index: 40,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144512.jpg'
                                    },
                                    {
                                        index: 41,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144635.jpg'
                                    },
                                    {
                                        index: 42,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144438.jpg'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: '圆桌讨论 ——混合工作模式 – 您准备好了吗？',
                        children: [
                            {
                                title: '主持人',
                                items: [
                                    '冯慧诗-顾问及交易服务|办公楼部负责人，CBRE香港'
                                ],
                                data: [
                                   {
                                        index: 39,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144525.jpg'
                                    },
                                ]
                            },
                            {
                                title: '圆桌嘉宾',
                                items: [
                                    '郭富礼-CBRE香港区域董事总经理',
                                    '蔡敏怡-顾问及交易服务|办公场所策略部负责人，CBRE香港'
                                ],
                                data: [
                                    {
                                        index: 3,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910135823.jpg'
                                    },
                                    {
                                        index: 43,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144336.jpg'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            ],
            brekoutsDetailsEn: [
                // 分会场1
                [
                    {
                        title: 'Keynote Speech',
                        items: [
                            'Alan Li-President, China, CBRE'
                        ],
                        data: [
                            {
                                index: 2,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910135732.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Panel Discussion',
                        items: [
                            'Candice Wang-Head of Capital Markets, Eastern China, CBRE',
                            'Joe Zhou-Executive Director, Capital Markets | Capital Advisor Group, China, CBRE',
                            'Stephanie Ng-Capitaland (China), General Manager of Retail China Fund',
                            'Zhu Gang-Executive Head of Asset Management Real Estate Fund Department of Huatai Securities'
                        ],
                        data: [
                            {
                                index: 18,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140703.jpg'
                            },
                            {
                                index: 19,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140718.jpg'
                            },
                            {
                                index: 20,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210915133259.jpg'
                            },
                            {
                                index: 21,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140831.jpg'
                            }
                        ]
                    }
                ],
                // 分会场2
                [
                    {
                        title: 'Keynote Speech',
                        items: [
                            'Luke Moffat-Managing Director, Advisory & Transaction Services, Greater China, CBRE'
                        ],
                        data: [
                            {
                                index: 10,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910140954.jpg'
                            },
                        ]
                    },
                    {
                        title: 'Panel Discussion',
                        children: [
                            {
                                title: 'Moderator',
                                items: [
                                    'Fion Zhang-Head of Advisory & Transaction Services I Office, Eastern China, CBRE'
                                ],
                                data: [
                                    {
                                        index: 22,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141058.jpg'
                                    }
                                ]
                            },
                            {
                                title: 'Panelists',
                                items: [
                                    'Rayman Zhang-Head of Advisory & Transaction Services | Office | Occupier, Northern China, CBRE',
                                    'Wu Jingbo-Greater China Real Estate Leader | Procurement - Real Estate Services, EY',
                                    'Cindy Li-Senior Administrative Director, Mininglamp Technology',
                                    'Betty Wang-General Manager of Commercial Management and Office Business China Xintiandi and General Manager of INNO KIC',
                                    'Hao Lei-General Manager of JIC Holding',
                                    'Chinatsu Kaneko-Head of Advisory & Transaction Services | Workplace Strategy, North Asia, CBRE',
                                ],
                                data: [
                                    {
                                        index: 23,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141136.jpg'
                                    },
                                    {
                                        index: 24,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141340.jpg'
                                    },
                                    {
                                        index: 25,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141254.jpg'
                                    },
                                    {
                                        index: 26,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141328.jpg'
                                    },
                                    {
                                        index: 27,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141244.jpg'
                                    },
                                    {
                                        index: 28,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910141151.jpg'
                                    }
                                ]
                            }
                        ]
                    }
                ],
                // 分会场3
                [
                    {
                        title: 'Keynote Speech',
                        items: [
                            'Cindy Sun-Head of Advisory & Transaction Services and Capital Markets | Industrial & Logistics Investment, China, CBRE'
                        ],
                        data: [
                            {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Fireside Chat | Life Science ',
                        items: [
                            'Cindy Sun-Head of Advisory & Transaction Services and Capital Markets | Industrial & Logistics Investment, China, CBRE',
                            'Liu Ping-Chief Engineer of Shanghai Municipal Commission of Economy and Informatization',
                        ],
                        data: [
                             {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            },
                            {
                                index: 30,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141534.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Fireside Chat | Industrial & Logistics',
                        items: [
                            'Cindy Sun-Head of Advisory & Transaction Services and Capital Markets | Industrial & Logistics Investment, China, CBRE',
                            'Simon Wang-General Manager of ESR East China and Vice President of ESR China',
                        ],
                        data: [
                            {
                                index: 29,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141519.jpg'
                            },
                            {
                                index: 31,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141603.jpg'
                            }
                        ]
                    }
                ],
                // 分会场4
                [
                    {
                        title: 'Keynote Speech',
                        items: [
                            'Alex Chen-Head of Consulting, China, CBRE'
                        ],
                        data: [
                            {
                                index: 32,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210922134141.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Fireside Chat  - A Wider City: Urban Agglomeration | Metropolitan Area',
                        items: [
                            'Professor Chen Xian-Professor of Antai College of Economics and Management, Shanghai Jiaotong University; distinguished researcher at the Shanghai Institute for Global City.',
                            'Allen Xiao-Head of Consulting, Southern China, CBRE'
                        ],
                        data: [
                            {
                                index: 33,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141905.jpg'
                            },
                            {
                                index: 34,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141703.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Fireside Chat  - A Deeper City: Urban Renewal | Urban Reborn',
                        items: [
                            'Professor Xu Leiqing-Professor, College of Architecture and Urban Planning, Tongji University; Director, Urban Renewal Center, National Institute of Modernization, Tongji University',
                            'Tony Lu-Associate Director, Consulting, China, CBRE'

                        ],
                        data: [
                            {
                                index: 35,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141943.jpg'
                            },
                            {
                                index: 36,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141721.jpg'
                            }
                        ]
                    },
                    {
                        title: 'Fireside Chat  - A Warmer City: Urban Scene | Urban Temperament',
                        items: [
                            'Jolly Shen-Project leader of The Rising Lab and Member of editorial board of YiMagazine',
                            'Fred Wang-Head of Consulting, Jiangsu, CBRE'
                        ],
                        data: [
                            {
                                index: 37,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141958.jpg'
                            },
                            {
                                index: 12,
                                avatar: 'https://img.mofyi.com/Ayto/image/20210910141802.jpg'
                            }
                        ]
                    }
                ],
                // 分会场5
                [
                    {
                        title: 'Panel Discussion - Hong Kong CRE I Dynamics of Change',
                        children: [
                            {
                                title: 'Moderator',
                                items: [
                                    'Marcos Chan-Head of Research, Hong Kong, CBRE'
                                ],
                                data: [
                                    {
                                        index: 38,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144424.jpg'
                                    }
                                ]
                            },
                            {
                                title: 'Panelists',
                                items: [
                                    'Ada Fung-Head of Advisory & Transaction Services | Office, Hong Kong, CBRE',
                                    'Samuel Lai-Head of Advisory & Transaction Services | Industrial & Logistics, Hong Kong, CBRE',
                                    'Lawrence Wan-Head of Advisory & Transaction Services | Retail, Hong Kong, CBRE',
                                    'Reeves Yan-Head of Capital Markets Hong Kong, CBRE',
                                ],
                                data: [
                                    {
                                        index: 39,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144525.jpg'
                                    },
                                    {
                                        index: 40,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144512.jpg'
                                    },
                                    {
                                        index: 41,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144635.jpg'
                                    },
                                    {
                                        index: 42,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144438.jpg'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        title: 'Panel Discussion - Ready for Hybrid work model? ',
                        children: [
                            {
                                title: 'Moderator',
                                items: [
                                    'Ada Fung-Head of Advisory & Transaction Services | Office, Hong Kong, CBRE'
                                ],
                                data: [
                                   {
                                        index: 39,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144525.jpg'
                                    },
                                ]
                            },
                            {
                                title: 'Panelists',
                                items: [
                                    'Tom Gaffney-Regional Managing Director, Hong Kong, CBRE',
                                    'Manyi Choi-Head of Advisory & Transaction Services | Occupier Consulting and Workplace Strategy, Greater China, CBRE'
                                ],
                                data: [
                                    {
                                        index: 3,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910135823.jpg'
                                    },
                                    {
                                        index: 43,
                                        avatar: 'https://img.mofyi.com/Ayto/image/20210910144336.jpg'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            ]
        }
    },
    methods: {
        avatarLoad(){
            this.agendaData.forEach(n => {
                if(n.type == 'speakers'){
                    n.items.forEach(m => {
                        let img = new Image()
                        img.src = m.avatar
                        img.onload = function(){

                        }
                    })
                }
            })
        },
        setBreakoutsDetails(){
            let data = localStorage.getItem('locale') === 'en' ? this.brekoutsDetailsEn : this.brekoutsDetailsZh

            data.forEach((item, index) => {
                this.agendaData[3].items[index].details = item

            })

            setTimeout(() => {
                this.dataReady = true
            }, 300)
        },
        swiperPrev(){
            const swiper = this.$refs.agendaSwiper.swiper
            swiper.slidePrev()
        },
        swiperNext(){
            const swiper = this.$refs.agendaSwiper.swiper
            swiper.slideNext()
        }
    },
    mounted() {
        this.setBreakoutsDetails()
        this.avatarLoad()
    },
}
</script>
