<template>
    <div class="time">
        <p v-if="timeDiff !== null && timeDiff >= 0">{{ $t('header.timeInfo') }}</p>
        <ul v-if="timeDiff !== null && timeDiff >= 0">
            <li>
                <p><strong>{{ days }}</strong></p>
                <p><span>{{ $t('header.days') }}</span></p>
            </li>
            <li>
                <p><strong>{{ hours }}</strong></p>
                <p><span>{{ $t('header.hours') }}</span></p>
            </li>
            <li>
                <p><strong>{{ minutes }}</strong></p>
                <p><span>{{ $t('header.minutes') }}</span></p>
            </li>
            <li>
                <p><strong>{{ seconds }}</strong></p>
                <p><span>{{ $t('header.seconds') }}</span></p>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
    name: "CountDown",
    computed: {
        ...mapState('main', ['countdownTime'])
    },
    data() {
        return {
            // 时间差
            timeDiff: null,
            days: '-',
            hours: '-',
            minutes: '-',
            seconds: '-',
        }
    },
    methods: {
        ...mapMutations('main', ['setLiveEntranceShow']),
        // 创建定时器监听活动是否开始
        countTime(){
            if(this.timeDiff <= 0){
                this.setLiveEntranceShow(true)
                this.seconds = '00'
                this.minutes = '00'
                this.hours = '00'
                this.days = '00'
                return
            }
            const timer = setInterval(() => {
                let stemp = new Date().getTime()
                this.timeDiff = this.countdownTime - stemp

                this.seconds = this.supplement(parseInt(this.timeDiff / 1000 % 60))
                this.minutes = this.supplement(parseInt(this.timeDiff / 1000 / 60 % 60))
                this.hours = this.supplement(parseInt(this.timeDiff / 1000 / 60 / 60 % 24))
                this.days = this.supplement(parseInt(this.timeDiff / 1000 / 60 / 60 / 24))

                if(this.timeDiff <= 0){
                    this.setLiveEntranceShow(true)
                    clearInterval(timer)
                }

            }, 500)

        },
        supplement(_num) {
            return _num < 10 ? '0' + _num : _num.toString()
        },

    },
    mounted() {
        this.timeDiff = this.countdownTime - new Date().getTime()
        this.countTime()
    },
};
</script>
