export default {
    zh: {
        header: {
            register: '注册',
            login: '登录',
            logout: '退出登录',
            welcome: '您好',
            timeInfo: '距离活动开始还有',
            days: '天',
            hours: '时',
            minutes: '分',
            seconds: '秒',
        },
        nav: {
            home: '首页',
            intro: '活动介绍',
            agenda: '日程',
            speakers: '嘉宾',
            report: '报告',
            live: '直播回放',
            local: '本地洞见'
        },
        footer: {
            about: '关于CBRE',
            contact: '联系我们',
            faq: '常见问题',
            disclaimer: '免责声明',
            terms: '法律条款',
            share: '分享'
        },
        other: {
            enter: '进入',
            reportSurvey: '报告及调查问卷',
            download: '下载',
            showAll: '显示全部',
            submit: '提交',
            loginSuccess: '登录成功',
            loginError: '登录失败',
            registerSuccess: '注册成功',
            registerError: '注册失败',
            logoutSuccess: '退出成功',
            comingSoon: '敬请期待！',
            success: '提交成功',
            reqError: '网络错误，请稍后再试',
            needLogin: '请点击右上角登录',
            notice: '通知：',
            noticeText: ' 更多CBRE Forum相关内容敬请期待',
            ieTips: '您的浏览器版本过低，已停止维护。请使用{0}、{1}等浏览器访问获得最佳体验。{2}',
            ieTips0: 'Chrome',
            ieTips1: '火狐',
            ieTips2: '关闭提示',
            liveAssistant: 'CBRE直播小助手'
        },
        user: {
            title: '2021 CBRE论坛',
            loginTitle: '用户登录',
            forgotPassword: '忘记密码',
            loginSubmit: '立即登录',
            registerSubmit: '立即注册',
            registerTips: '尚未注册？请点击 {0}',
            registerTips0: '注册',
            registerTitle: '用户注册',
            name: '姓名',
            email: '邮箱',
            password: '密码',
            company: '公司名称',
            jobTitle: '工作职位',
            city: '城市',
            emailTips: '此邮箱将作为论坛唯一登录凭证',
            firstCheck: '点击此框并提交以上信息，表示您确认已阅读{0}，并同意个人信息将按照隐私声明的规定处理。',
            firstCheck0: '隐私声明',
            secondCheck: '点击此框并提交信息，即表示您同意通过该个人信息接收世邦魏理仕发送给您的业务宣传材料。',
            loginTips: '已有账号？请点击{0}',
            loginTips0: '登录',
            loginText: '请使用注册邮箱登录',
        },
        disclaimer: {
            title: '免责声明',
            content: '除特别注明或提示，所有内容之相关知识产权均属世邦魏理仕所有，所有权利受法律保护。基于信息发布时所掌握的情况，以世邦魏理仕所知，所刊载信息及预测真实且无误导，但世邦魏理仕未对此信息进行核实验证，亦未对上述信息做出任何保证或陈述。阅读者如参考、使用或依赖上述信息，应对上述信息的准确性、真实性和完整性进行独立审查。世邦魏理仕不对阅读者和任何第三方使用或依赖以上信息, 或者/以及作出商业决策而导致任何损失和费用或作出的决策导致的任何其他后果承担任何责任。'
        },
        home: {
            enter: '点击查看详情',
            intro: '活动介绍',
            introText: '2021年，中国经济和商业地产市场在新冠疫情后焕发出勃勃生机。上半年，写字楼、零售物业和仓储物流的租赁需求均大幅增长，大宗物业交易金额达1,521亿元，创上半年大宗交易额新高。',
            agenda: '活动日程',
            agendaText: '这是一段假文字，需要后期修改和添加内容，以匹配板块引导，也需要考虑字段数。这是一段假文字，需要后期修改和添加内容，以匹配板块引导，也需要考虑字段数。',
            speakers: '演讲嘉宾',
            speakersText: '今年，将有哪些业界专家将围绕“焕新万象”这一主题，为大家带来独到见解呢？',
            report: '报告与调查问卷',
            reportText: '',
            reportButtonText: '下载报告'
        },
        intro: {
            content: '2021年，中国经济和商业地产市场在新冠疫情后焕发出勃勃生机。上半年，写字楼、零售物业和仓储物流的租赁需求均大幅增长，大宗物业交易金额达1,521亿元，创上半年大宗交易额新高，城市更新开启下半场，机遇与挑战并存。在商业地产新的上升周期开启之际，一些重大的转变和趋势正在成型。它们在提示租户和投资者做出应对的同时，也必将创造新的机遇。'
        },
        report: {
            crumbs: '活动满意度调查',
            title: '活动满意度调查',
            info: '感谢大家参与CBRE FORUM 2021！今年由于疫情的反复所带来的各种不确定性，CBRE的年度旗舰活动依然选择在云端举办。我们期望得到您对这次活动的反馈，以帮助我们不断进步。\n\n按您的满意度进行评分',
            formTitle1: '活动议程',
            formText1: '请为活动议程进行评分',
            formTitle2: '整体内容',
            formText2: '请为整体内容进行评分',
            formTitle3: '选题与您所在企业的相关性',
            formText3: '请为选题与您所在企业的相关性进行评分',
            formTitle4: '焕新万象 | 大中华区主题报告分享',
            formText4: '请为主题报告分享环节进行评分',
            formTitle5: '通时达变分会场I：投资 | 变局·新局',
            formText5: '请为投资分会场进行评分',
            formTitle6: '通时达变分会场II：办公楼 | 下一站办公',
            formText6: '请为请为办公楼分会场进行评分',
            formTitle7: '通时达变分会场分会场III：产业地产 | 产业新生',
            formText7: '请为产业地产分会场进行评分',
            formTitle8: '通时达变分会场IV：战略+ | 应变而“城”',
            formText8: '请为战略+分会场进行评分',
            formTitle9: '通时达变分会场V：香港地区商业地产市场 I 风云变幻',
            formText9: '请为香港地区商业地产市场分会场进行评分',
            formTitle10: '活动平台体验',
            formText10: '请为活动平台体验进行评分',
            formTitle11: '活动时间与时长',
            formText11: '请为活动时间与时长进行评分',
            formText12: '请告知您想获得哪方面的行业洞见，我们将与您取得联络。',
            formText13: '请填写您的任何改进建议。',
            buttonText: '提交并下载',
            radioOptions1: '非常不满意',
            radioOptions2: '不满意',
            radioOptions3: '一般',
            radioOptions4: '满意',
            radioOptions5: '非常满意',
            submitSuccess: '提交成功',
            download: '下载报告'
        },
        speakers: {
            speaker1: {
                name: '戴柏勤',
                intro: '世邦魏理仕北亚区总裁',
                info: '戴柏勤负责管辖世邦魏理仕分布在中国内地、香港、台湾、日本、韩国的25家分公司，并与北亚各国的世邦魏理仕管理层致力于缔造卓越的客户服务，从而进一步帮助公司实现成为真正的世界级公司的战略目标。在戴柏勤先生27年的房地产职业生涯里，他先后供职于伦敦、香港和东京三大国际金融中心，为遍及14个国家的各类地产项目提供顾问服务。自1996年起，他已于亚太地区工作了25年，并在项目租赁、战略处置、资产管理等业主服务，以及资产组合管理、业主代表、战略租用规划等租户服务方面积累了丰富的经验。'
            },
            speaker2: {
                name: '李凌',
                intro: '世邦魏理仕中国区总裁',
                info: '李凌现任世邦魏理仕中国区总裁，负责领导并推动覆盖全业务线和区域的协作计划和综合方案，促进世邦魏理仕在中国商业地产服务和投资业务的发展。凭借其在业务和运营层面的丰富经验，李凌在公司的本土化战略以及关键投资和计划中都发挥了核心作用，同时也主导并积极参与维护主要客户、政府和商业伙伴关系，在其中担任着举足轻重的角色。'
            },
            speaker3: {
                name: '郭富礼',
                intro: '世邦魏理仕香港区域董事总经理',
                info: '郭富礼负责监督世邦魏理仕在香港区域的所有商业及运营活动，包括与不同团队和股东合作制定和实践世邦魏理仕的战略，并监督所有主要业务线的运营、客户关系及满意度项目，以及个人发展与人才招募等。郭先生具有广泛的房地产经验，在加入世邦魏理仕前，他曾于投资、商业交易及顾问服务业出任行政职位，领导才能在业内备受尊崇。'
            },
            speaker4: {
                name: '朱幸儿',
                intro: '世邦魏理仕台湾区域董事总经理',
                info: '朱幸儿现带领世邦魏理仕台湾团队，负责台湾分公司整体的运营及管理，并监督台湾及全球的投资市场与商业地产中介服务。她拥有超过26年以上的商业、工业、零售及住宅不动产专业投资买卖及顾问服务经验。'
            },
            speaker5: {
                name: '金纬博士',
                intro: '世邦魏理仕全球资本市场投资以及亚太区研究部负责人',
                info: '金纬博士所领导管理的研究团队，范围涵盖超过40个市场。加入世邦魏理仕之前，金博士曾在保德信金融集团的房地产投资公司担任亚太区研究及策略部主管，并且在德意志银行集团担任多个高级职位。金纬博士还致力于在学术圈贡献所长，目前任职台湾国立台北大学助理教授、英国牛津布鲁克斯大学客座教授、香港大学演讲嘉宾。'
            },
            speaker6: {
                name: '蔡詠嘉',
                intro: '世邦魏理仕亚太区租户市场研究部以及数据讯息与管理负责人',
                info: '蔡詠嘉负责世邦魏理仕在亚太区的租户市场研究，并监督该地区的数据战略。自2005年加入世邦魏理仕以来，她担任过各种领导职务，包括亚太区资本市场研究部负责人和大中华区研究部负责人。在加入世邦魏理仕之前，蔡詠嘉在香港交易及结算所的规划和研究团队从事金融市场研究工作，她负责研究金融市场的发展趋势，并将香港与其他全球领先的金融中心进行比较。她在亚太区房地产市场有15年的研究经验，并拥有香港城市大学的金融工程和商业定量分析硕士学位，以及香港中文大学的工商管理学士学位。她是特许金融分析师，也是香港金融分析师协会的成员。'
            },
            speaker7: {
                name: '谢晨',
                intro: '世邦魏理仕中国区研究部负责人',
                info: '谢晨所领导的中国区研究团队追踪中国区18个主要城市房地产市场，提供针对各类物业市场的研究咨询、市场预测和策略意见，以帮助客户更明智地制定和执行相关的房地产策略。2020及2021年出版的主要商业地产专题报告包括：《中国战疫十策：商业地产指南》、《亚太区零售租户调查 - 中国内地》、《大中华区2030年展望报告 - 应运而胜》、《2021年中国房地产市场展望》、《2021年中国投资者意向调查报告》、《未来办公：中资企业办公场所调查 | 如何打造未来办公空间》、《T25中国科创中心租金指数》等。'
            },
            speaker8: {
                name: '李嘉玶',
                intro: '世邦魏理仕台湾研究部负责人',
                info: '李嘉玶小姐现担任世邦魏理仕台湾研究部负责人，负责研究台湾地区商用不动产市场走向，并定期发表对总体经济、投资市场及租赁市场的预测。李小姐带领团队分析各类型不动产的市场表现与长期发展趋势，据以撰写相关研究报告，内容涵盖商用不动产投资市场、办公室及零售物业租赁市场、旅馆市场及不动产投资信托基金等面向。熟稔中英文，拥有美国康乃尔大学都市与区域计划研究所硕士学位。'
            },
            speaker9: {
                name: '王晶',
                intro: '世邦魏理仕中国区投资及资本市场部执行董事兼华东区负责人',
                info: '王晶专注于提供投资咨询服务。她主要负责维护部门与境内外投资机构的关系，为卖方提供专业的项目管理咨询等服务。她拥有多年政府及企业工作经历，具有丰富的运营、管理及销售经验。投身房地产市场后，她专注于为国内外投资机构，提供辐射全国的地产投资咨询服务。她所促成的项目较为多元，包括写字楼、商业、产业园区、物流用地等资产类别。'
            },
            speaker10: {
                name: '周志锋',
                intro: '世邦魏理仕中国区投资及资本市场部 | 资本顾问团队执行董事',
                info: '周志锋作为世邦魏理仕中国区投资及资本市场部 | 资本顾问团队执行董事，与中国区各条业务线紧密合作，推动业务发展并参与相关决策。作为世邦魏理仕的差异化优势和核心价值，周先生在投资咨询业务、资产包销售业务、资本重组业务以及基金融资业务等多领域为客户提供建设性的专业意见。'
            },
            speaker11: {
                name: '朱刚',
                intro: '华泰证券资产管理不动产基金部执行负责人',
                info: '朱刚主导并建立了华泰证券不动产开发基金、不动产证券化与公募REITs为一体的全业务链体系。曾领导团队成功发行包括 “保利置业CMBS”、 “白玉兰广场CMBS”等传统商业不动产及“宝湾物流一期类REITs”等基础设施领域多单在行业颇具影响力的证券化产品。多次受邀参与国家发改委、中国证监会主持的公募REITs行业调研、政策研讨、法规起草等工作。'
            },
            // 莫非
            speaker12: {
                name: '莫非',
                intro: '世邦魏理仕华东区区域董事总经理兼大中华区顾问及交易服务部负责人',
                info: '莫非自2014年起担任世邦魏理仕华东区区域董事总经理一职，在5年内带领华东区团队业务增长2倍以上。自2020年起，莫非兼任管理大中华区顾问及交易服务部。他拥有超过20年的商业地产从业经验，是上海和新加坡办公楼市场的专家。莫非负责管理华东区主要业务，同时维护管理大客户关系，以办公楼租赁、投资、零售、工业物流、专业咨询及项目管理等为工作重点。'
            },
            // 张越
            speaker13: {
                name: '张越',
                intro: '世邦魏理仕华东区顾问与交易服务|办公楼部负责人',
                info: '张越现任世邦魏理仕华东区顾问及交易服务|办公楼部负责人，拥有20年的商业房地产工作经验，主要负责上海以及周边华东城市的业务。她代表当今市场上大型复杂的中外资企业处理他们的租赁交易，积累了包括企业中长期策略的制定与发展及其实施、选址、财务模块、资产管理、调研、物业购置、处置、重组等的丰富经验，也协助目前市场上最知名的开发商以及投资商在项目前期定位咨询、市场研究、营销推广、租赁和销售代理、资产管理等业主服务方面积累了丰富经验。'
            },
            // 张冀苏
            speaker14: {
                name: '张冀苏',
                intro: '世邦魏理仕华北区顾问及交易服务 | 办公楼 | 租户部负责人',
                info: '张冀苏先生拥有14年商业地产顾问经验，目前他负责世邦魏理仕在中国的主要业务线。他针对不同客户的需求，为其量身定制办公室租赁策略及解决方案，并完成整个顾问及交易服务的流程。'
            },
             // 吴竞伯
            speaker15: {
                name: '吴竞伯',
                intro: '安永大中华区房地产主管',
                info: '吴竞伯现任安永大中华区房地产主管，拥有超过15年的商业房地产工作经验，目前主要负责大中华区（中国内地与港澳台地区）及蒙古房地产有关事务。他作为企业房地产相关人士，积累了包括企业选址、商业地产租赁、资产管理、租赁策略制定、资产处置、供应商管理协同等的丰富经验。'
            },
            // 李艳娟
            speaker16: {
                name: '李艳娟',
                intro: '明略科技集团高级行政总监',
                info: '李艳娟负责明略科技集团行政部和采购部的全面管理工作。她在行政领域深耕十余年，对企业选址策略、空间策略、工程实施、空间运营管理有着十分丰富的经验。她成功主导多个企业办公场地建设项目，尤其关注办公场所对员工健康的影响，针对办公场所对企业文化的渗透、员工的高效协作、激发创新的活力等方面深入研究并成功地将多维度视角融合应用在明略科技集团北京总部办公室建设项目中。'
            },
            // 王红
            speaker17: {
                name: '王红',
                intro: '中国新天地商业管理办公业务总经理，INNO创智项目总经理',
                info: '王红负责瑞安房地产及中国新天地旗下办公业务板块。拥有近20年房地产从业经历。经验涵盖住宅、办公、商业以及资产管理领域，打造了企业天地、创智天地、虹桥天地等多个办公项目，她带领团队创建了“瑞安办公SHUI ON WORKX”品牌，并创立了INNO办公产品线。“玉汝于成，功不唐捐”的座右铭也让王红一直不断挑战自我，坚持从客户角度出发，持续创新。'
            },
            // 郝磊
            speaker18: {
                name: '郝磊',
                intro: '建投控股有限责任公司总经理',
                info: '郝磊，毕业于中国人民大学房地产专业，现任建投控股有限责任公司董事、总经理。郝磊先生曾在国有控股商业银行房地产金融部门，国有大型投资企业长期工作，熟悉房地产金融，不动产投资及运营等相关行业。2015年至今，郝磊先生深入挖掘城市存量不动产市场，陆续完成了北京嘉昱紫金中心，北京嘉昱庄盛广场，上海外滩嘉昱中心，杭州嘉昱建银中心，广州嘉昱中心等不动产再生投资项目，积累了丰富的不动产项目改造及运营经验。'
            },
            // 金子千夏
            speaker19: {
                name: '金子千夏',
                intro: '世邦魏理仕北亚区顾问及交易服务|工作场所策略部负责人',
                info: '金子千夏在商业建筑室内设计和工作场所咨询方面拥有24年的经验，她坚信高效的工作环境将对员工表现及企业文化产生深远影响，并积极致力于为客户打造与其发展战略相一致的办公环境。她助力全球范围内众多客户打造出能够提高了生产效率、员工满意度及丰富工作文化的办公空间。而作为世邦魏理仕顾问及交易服务|工作场所策略部的全球领导者之一，她对工作场所演变的最新趋势有着独到的见解。'
            },
            speaker20: {
                name: '孙洁',
                intro: '世邦魏理仕中国区顾问及交易服务部和投资及资本市场部产业地产负责人',
                info: '孙洁女士于2007年加入工业地产咨询行业，拥有超过14年的工业选址，投资咨询经验。专注于为许多跨国公司的制造业和终端用户提供选址、搬迁谈判，为众多机构投资者、产业地产开发商提供工业项目收购或处置服务，并与投资人就项目收购或募集基金进行密切合作等。她代表众多企业累计完成了130万平米的工业土地交易，近两年主要负责的并购项目金额接近130亿元。'
            },
            speaker21: {
                name: '刘平',
                intro: '上海市经济和信息化委员会副主任',
                info: ''
            },
            speaker22: {
                name: '王帅',
                intro: '易商华东区总经理、中国区副总裁',
                info: '王帅2009-2011年供职于普洛斯（中国）华东区，2011年初就任于工银安盛，同年底加入ESR易商集团，工作至今。王帅在易商曾担任中国区大客户部总监、中西区总经理，现任易商华东区总经理、中国区副总裁，目前主要负责ESR易商在华东大区的项目投资，开发建设，对外租赁和物业管理等全方面的运营管理工作。'
            },
            speaker23: {
                name: '陈学海',
                intro: '世邦魏理仕中国区战略顾问部负责人',
                info: '陈学海先生在战略咨询领域有超过十五年的从业及管理经历，在城市发展战略、片区开发、城市更新、TOD等领域具有丰富的实操经验，多年来作为各级政府、国有平台公司、大型开发商的咨询顾问，成功主导的项目遍布全国。陈学海先生主亦作为一位市场中的思想领导者，在公共及媒体领域发表深刻见解及观点；凭借在行业内积累的丰富经验和卓越声誉，他目前也担任上海市人民政府发展研究中心、全球城市研究院等政府智库机构之研究成员。'
            },
            speaker24: {
                name: '陈宪 教授',
                intro: '上海交通大学安泰经济与管理学院教授，上海全球城市研究院特聘研究员',
                info: '陈宪为享受国务院政府特殊津贴专家，主要的教学与研究领域是宏观经济学，公共经济学，服务经济与贸易。主要社会兼职是中国市场学会副会长，上海社会科学院博士生导师，上海市经济学会副会长。近年来，关注创业创新和区域经济一体化。曾出席李克强总理主持的经济形势专家和企业家座谈会，做了题为“经济企稳、动能转换与‘双创’发力”的发言。'
            },
            speaker25: {
                name: '肖伟',
                intro: '世邦魏理仕华南区战略顾问部负责人',
                info: '肖伟先生在战略咨询领域有十五年的从业及管理经历，并长期担任政府及大型平台公司咨询顾问，在产业发展战略、新城及片区开发、TOD、城市更新及“一带一路”等领域有着丰富的实操经验。同时肖先生曾在英国和其他地区拥有工作经验，尤其专注于深耕粤港澳大湾区市场，与市场主流开发商、规划设计机构及产业运营资源保持紧密互动。长期担任深圳市多个区政府产业专家智库成员，为区域产业发展建言献策。他同时担任英国皇家测量师协会（RICS）中国区考官成员。'
            },
            speaker26: {
                name: '徐磊青 教授',
                intro: '同济大学建筑与城市规划学院教授，同济大学国家现代化研究院城市更新中心主任',
                info: '徐磊青教授的研究领域包括城市更新，社区更新，城市设计，建筑设计，环境设计等。他的研究触及了人性化城市空间的重要主题，包括健康、安全、效率、舒适、美观、满意和多样性等等。他是城市空间环境与行为、心理、身体关系的重要学者和代表人物。同时也是当前中国城市和社区更新领域的知名思想家、研究者和设计师。'
            },
            speaker27: {
                name: '陆韬',
                intro: '世邦魏理仕中国区战略顾问部副董事',
                info: '陆韬拥有10年的城市战略咨询工作经验，多年来作为全国各地政府部门、国有平台公司、大型开发商的咨询顾问，在城市发展战略、政府决策咨询、区域综合开发、城市更新、企业战略等领域积累了丰富的实操经验，服务的项目和客户遍布全国。'
            },
             speaker28: {
                name: '沈从乐',
                intro: '第一财经·新一线城市研究所主编、《第一财经YiMagazine》杂志编委',
                info: '毕业于复旦大学新闻系，从2013年起参与《第一财经周刊》新一线城市栏目的内容策划和生产，深入成都、杭州、武汉、长沙等新一线城市进行长时间的商业报道和调研。2015年以项目负责人身份主导成立新一线城市研究所，主攻以大数据研究方式探索和分析中国城市的商业魅力及生活方式发展与变迁。该机构整合线上线下全面的城市商业大数据，用新鲜视角为城市管理者、城市开拓者和城市人提供丰富有趣、有价值的数据内容和数据服务。'
            },
            speaker29: {
                name: '王飞',
                intro: '世邦魏理仕江苏区域战略顾问部负责人',
                info: '王飞拥有10年的战略咨询、地产咨询、及专项顾问服务经验，尤其擅长城市更新、产业规划、商业地产等业务领域，主要负责苏皖及周边城市业务，为当地开发商及政府提供从产业发展到宣传推广保障的全流程顾问服务，并多次代表世邦魏理仕参加各地政府的专题活动、论坛并发表主旨演讲。'
            },
            speaker30: {
                name: '陈锦平',
                intro: '世邦魏理仕香港研究部负责人',
                info: '陈锦平于2014年加入世邦魏理仕，专责带领香港研究部团队，研究区内各范畴的房地产市场。他经常与中国内地、亚太区及全球研究团队紧密合作，积极推动世邦魏理仕的长远承诺—为客户提供专业的业内见解、研究报告和分析。陈先生是世邦魏理仕亚太区研究行政委员会的成员之一。作为皇家特许测量师学会的成员，陈先生拥有逾22年物业市场研究经验，其中20年任职于包括国际级测量师行，于香港及中国内地不同研究部团队担任领导角色。他早期受雇于一家香港地产发展商，从事项目投资前期分析和可行性研究工作，发掘本地及海外房地产市场的投资机会。陈先生对大中华地区的房地产市场有深入的见解，凭借其丰富的房地产市场研究背景，陈先生对租户行业动态和业主需求等了如指掌。陈先生亦活跃于本地和国际媒体，分享多元化的房地产评论。'
            },
            speaker31: {
                name: '甄浚岷',
                intro: '世邦魏理仕香港资本市场部负责人',
                info: '甄浚岷先生负责世邦魏理仕资本市场部在香港的业务发展，带领团队为客户提供专业的投资方案。甄先生在物业投资市场拥有超过15年的丰富经验。他以物业估价师身份开始职业生涯，其后转投了资本投资市场，凭借着他对客户的尽责及对专业的要求，现已成为行内知名的物业投资顾问，并拥有丰富的投资经验及可靠的成功交易案例。他为客户，如地产发展商、机构性投资基金、家族办公室、个人富豪投资者、中国内地及海外投资者等等，提供高质量的专业服务。凭借其对行业的卓越贡献，他于2013年勇夺RICS皇家特许测量师学会香港颁奖礼年度大奖“青年测量师大奖”。而于2019-2021年间， 他更为RICS皇家特许测量师学会香港的考评官。'
            },
            speaker32: {
                name: '黎尚文',
                intro: '世邦魏理仕香港顾问及交易服务|工业及物流部负责人',
                info: '黎尚文先生处理工业物业交易经验广泛，包括特大仓库租赁及项目营销，对行业的热诚及专业服务广为业界认同。通过与研究部团队紧密合作，黎先生多次参与制作行业白皮书和前瞻性主题文章，还经常与公众交流行业热门话题。他负责带领团队发展，并凭借其与香港顾问及交易服务|工业及物流部团队的热忱，该团队从2012至2015年连续四年获得RICS英国皇家测量师学会年度工业代理奖项。黎先生亦勇夺嘉民物流年度最佳成交奖2014/15。黎先生于香港房地产界拥有逾17年经验，其中16年专注于工业物业。他于2008年加入世邦魏理仕，并屡创佳绩，其中一个项目是嘉民的特大仓库项目 – 嘉民领达中心，他于2012年物业落成之前就预租99%空间予客户，获得空前成功。'
            },
            speaker33: {
                name: '冯慧诗',
                intro: '世邦魏理仕香港顾问及交易服务|办公楼部负责人',
                info: '冯慧诗是负责管理香港顾问及交易服务|办公室楼、交易管理和工作场所战略团队，共计100多名房地产专业人士。由她带领的团队擅长为租户或业主提供一系列有关商业办公楼的服务。她拥有20多年的房地产经验，曾在房地产行业内多个层级工作，并建立了强大的行业网络。目前，她专注于为企业缔造成绩，并积极为公司建立出色的团队。在此之前，冯小姐曾领导世邦魏理仕大中华区销售管理和议案管理团队，也是项目管理团队的业务发展总监。她也是一名注册城市规划师，曾在政府部门工作，亦曾于不同房地产公司从事销售和营销工作，以展开其职业生涯。'
            },
            speaker34: {
                name: '温运强',
                intro: '世邦魏理仕香港顾问及交易服务|商铺部负责人',
                info: '温运强先生负责带领世邦魏理仕顾问及交易服务|商铺团队为客户带来卓越解决方案。温先生在零售租赁市场拥有逾15年的丰富经验。在加入世邦魏理仕之前，他于迪生创建（国际）有限公司担任集团物业及行政部经理，负责为奢侈品牌管理位于中国内地、香港及澳门约30万平方呎的房地产资产组合，品牌包括Harvey Nichols、Brooks Brothers、劳力士及Tommy Hilfiger。'
            },
            speaker35: {
                name: '蔡敏怡',
                intro: '世邦魏理仕大中华区顾问及交易服务|办公场所策略部负责人',
                info: '蔡敏怡拥有超过十年的房地产战略顾问经验，她专为企业客户提供量身定制的工作场所战略和变革管理服务，曾为中国内地和香港地区不同范畴的企业客户提供咨询服务，包括金融机构、科技公司、保险及其他专业服务、物流及食品及饮料公司，成功协助客户创造适合其业务、文化和员工的工作环境。 在加入世邦魏理仕之前，蔡小姐曾在香港一家知名的设计公司担任战略和变革顾问，并在2017年至 2019年期间担任一家独立工作场所顾问公司的核心成员。蔡小姐还拥有从项目启动到入驻的丰富实践经验，范围包括早期的战略阶段到测试拟合、设计概念、设计细节、现场施工到最终交付。'
            }
        },
        agenda: {
            item1: {
                type: '嘉宾',
                time1: '上午在线',
                time2: '10:00-10:15',
                name: '远见 | 开场演讲',
                key1: '戴柏勤',
                val1: 'CBRE北亚区总裁',
                key2: '李凌',
                val2: 'CBRE中国区总裁',
                key3: '郭富礼',
                val3: 'CBRE香港区域董事总经理',
                key4: '朱幸儿',
                val4: 'CBRE台湾区域董事总经理',
            },
            item2: {
                type: '嘉宾',
                time1: '上午在线',
                time2: '10:15-10:50',
                name: '焕新万象 | 大中华区主题报告分享',
                key1: '金纬博士',
                val1: '全球资本市场投资以及亚太区研究部负责人，CBRE',
                key2: '蔡詠嘉',
                val2: '租户市场研究部以及数据讯息与管理负责人，CBRE亚太区',
                key3: '谢晨',
                val3: '研究部负责人，CBRE中国区',
                key4: '李嘉玶',
                val4: '研究部负责人，CBRE台湾',
            },
            item3: {
                type: '嘉宾',
                time1: '上午在线',
                time2: '10:50-11:00',
                name: '谈新说变',
                key1: '刘焕丽',
                val1: 'CBRE华北区区域董事总经理',
                key2: '莫非',
                val2: 'CBRE华东区区域董事总经理',
                key3: '何达',
                val3: 'CBRE杭州分公司副总经理',
                key4: '王飞',
                val4: 'CBRE江苏区域战略顾问部负责人',
                key5: '王祎',
                val5: 'CBRE武汉分公司董事总经理',
                key6: '谢凌',
                val6: 'CBRE西南区董事总经理',
                key7: '李健',
                val7: 'CBRE西安及郑州分公司董事总经理',
                key8: '严思慧',
                val8: 'CBRE广州分公司董事总经理',
                key9: '吴伟冬',
                val9: 'CBRE深圳分公司董事总经理',
            },
            item4: {
                type: 'breakouts',
                time1: '上午在线',
                time2: '11:00-12:15',
                name: '通时达变 | 分会场 ',
                key1: '分会场I：投资 | 变局·新局',
                key2: '分会场II：办公楼 | 下一站办公',
                key3: '分会场III：产业地产 | 产业新生',
                key4: '分会场IV：战略+ I 应变而“城”',
                key5: '分会场V： 香港地区商业地产市场 I 风云变幻',
            },
        },
        live: {
            send: '发送',
            switching: '切换中...',
            switched: '切换完成',
            items: {
                item1: '投资',
                val1: '变局·新局',
                item2: '办公楼',
                val2: '下一站办公',
                item3: '产业地产',
                val3: '产业新生',
                item4: '战略+',
                val4: '应变而“城”',
                item5: '香港地区商业地产市场',
                val5: '风云变幻',
            }
        },
        faq: {
            more: '更多问题请发送电子邮件至',
            problem: {
                '0': '如何获得最佳观看体验？',
                '1': '如何切换网页语言？',
                '2': '如何切换主屏幕语言？',
                '3': '如何切换直播画面与演示文稿？',
                '4': '如何全屏观看？',
                '5': '如何切换分会场？',
                '6': '直播时，是否可以向嘉宾提问？',
                '7': '同一个账号是否可以在电脑端和手机端同时观看？ ',
                '8': '通过微信进入如何保存网站？',
                '9': '如何索取相关演示文稿？',
                '10': '如何下载报告？',
                '11': '如何咨询业务？',
                '12': '错过了直播是否可以回看？'
            },
            answer: {
                '0': '请使用Chrome浏览器观看。',
                '1': '您可通过点击网站右上角的语言切换功能键切换网页语言。',
                '2': '您可点击主屏幕下方的“语言”进行声道切换。',
                '3': '您可点击右侧副屏幕上的转换键进行切换。',
                '4': '您可点击主屏幕上的全屏按钮放大画面。',
                '5': '您可直接点击下方小屏幕上的播放按钮切换分会场。',
                '6': '您可在聊天框里发送问题。',
                '7': '建议通过电脑端进入获得最佳观看体验。 ',
                '8': '您可通过微信自带的保存浮窗功能进行保存。',
                '9': '您可发邮件至marcomms@cbre.com.cn索取。',
                '10': '您可点击左侧菜单栏“相关报告”完成调查问卷后进行下载。',
                '11': '您可通过网站左下角的“联系我们”进行咨询。',
                '12': '您可于如需回看请于9月28日，周二，上午9点后访问本网站。'
            }
        },
        local: {
            local: '本地洞见',
            items: {
                title1: '北京',
                title2: '上海',
                title3: '杭州',
                title4: '南京',
                title5: '武汉',
                title6: '西安 & 郑州',
                title7: '西南区',
                title8: '广州',
                title9: '深圳',
                title10: '香港'
            }
        },
        draw: {
            item1: '绽放吧！万象的神奇',
            item2: '来一口？补充万物原动力',
            item3: '来吧！包治百病',
            item4: '喷涌吧！思维的灵感',
            item5: '释放吧！年轻人的压力',
            item6: '走起！世界万象all in one',
            item7: '不能错过？时间的流转',
            item8: '恭喜！请留下你的快递收件信息，CHANGE盲盒等你来揭秘',
            error: '来晚啦！CHANGE盲盒已抢完。',
        },
        write: {
            title1: '恭喜! ',
            title2: '请留下你的快递收件信息，CHANGE盲盒等你来揭秘',
            address: '地址',
            mobile: '手机号',
            firstCheck: '点击此框并提交以上信息，表示您确认已阅读{0}，并同意个人信息将按照隐私声明的规定处理。',
            firstCheck0: '隐私声明',
            submit: '提交',
            success: '盲盒将于近期寄出，快递发出后我们将通过邮件通知您。',
            error: '提交失败，请填写正确的信息'
        }
    },
    en: {
        nav: {
            home: 'Home',
            intro: 'Intro',
            agenda: 'Agenda',
            speakers: 'Speakers',
            report: 'Report',
            live: 'Replay',
            local: 'Local lnsights'
        },
        header: {
            register: 'Register',
            login: 'Login',
            logout: 'Logout',
            welcome: 'Welcome',
            timeInfo: 'We are launching in',
            days: 'Days',
            hours: 'Hours',
            minutes: 'Minutes',
            seconds: 'Seconds',
        },
        footer: {
            about: 'About CBRE',
            contact: 'Contact us',
            faq: 'FAQ',
            disclaimer: 'Disclaimer',
            terms: 'Terms of use',
            share: 'Share'
        },
        other: {
            enter: 'Enter',
            reportSurvey: 'Report & Survey',
            download: 'Download',
            showAll: 'Show all',
            submit: 'Submit',
            loginSuccess: 'Login succeeded',
            loginError: 'Login failed',
            registerSuccess: 'Register succeeded',
            registerError: 'Register failed',
            logoutSuccess: 'logout succeeded',
            comingSoon: 'Coming soon!',
            success: 'Submit succeeded',
            reqError: 'Network error',
            needLogin: 'Please login first',
            notice: 'Notice: ',
            noticeText: 'Stay tuned for more information',
            ieTips: 'Please use {0}, {1} or other browsers to access for the best experience. {2}',
            ieTips0: 'Chrome',
            ieTips1: 'Firefox',
            ieTips2: 'Close',
            liveAssistant: 'CBRE Live Assistant'
        },
        user: {
            title: 'CBRE FORUM 2021',
            loginTitle: 'LOGIN FOR',
            forgotPassword: 'Forgot password',
            loginSubmit: 'Login now',
            registerSubmit: 'Register now',
            registerTips: 'Not registered yet? {0}',
            registerTips0: 'Register here',
            registerTitle: 'REGISTER FOR',
            name: 'Name',
            email: 'Email',
            password: 'Password',
            company: 'Company',
            jobTitle: 'Job title',
            city: 'City',
            emailTips: 'This will be the only verification to log in',
            firstCheck: 'By clicking this checkbox and submitting the information above, you acknowledge that you have read this Privacy Policy and that your personal information could be used in accordance with the terms of the {0}',
            firstCheck0: 'Privacy Policy.',
            secondCheck: 'By clicking this checkbox and submitting the information above, you have consented to the use of your personal information by CBRE to send marketing communications.',
            loginTips: 'Existing account?  Please {0}',
            loginTips0: 'click login',
            loginText: 'Please use registered email to log in',
        },
        disclaimer: {
            title: 'DISCLAIMER',
            content: 'All materials presented, unless specifically indicated otherwise, are under copyright and proprietary to CBRE. Information contained herein, including projections, has been obtained from materials and sources believed to be reliable at the date of publication. While we do not doubt its accuracy, we have not verified it and make no guarantee, warranty or representation about it. Readers are responsible for independently assessing the relevance, accuracy, completeness and currency of the information shown above. CBRE will not be liable for any loss, damage, cost or expense incurred or arising by reason of any person using or relying on information presented above.'
        },
        home: {
            enter: 'READ MORE',
            intro: 'INTRO',
            introText: 'With China’s economy and the commercial real estate market maintaining a strong post-pandemic recovery in 2021, the first half of the year saw leasing demand across the office, retail and logistics sectors register significant growth, with en-bloc transactions reaching a record-breaking high of RMB152.1 billion.',
            agenda: 'AGENDA',
            agendaText: 'Lorem ipsum dolor sit amet, consectetur adipiscing sed do eiusmod tempor  Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. ',
            speakers: 'SPEAKERS',
            speakersText: 'This year, who will share their professional insights on CBRE Forum – CHANGE? ',
            report: 'REPORT & SURVEY',
            reportText: '',
            reportButtonText: 'Download Report'
        },
        intro: {
            content: 'With China’s economy and the commercial real estate market maintaining a strong post-pandemic recovery in 2021, the first half of the year saw leasing demand across the office, retail and logistics sectors register significant growth, with en-bloc transactions reaching a record-breaking high of RMB152.1 billion. Entering into the next chapter in China’s urban renewal, challenges and opportunities abound. As the next up-cycle comes into sight, the commercial property market is expected to experience major transformations and new trends, generating new business opportunities. Occupiers and investors will have to adapt accordingly in order to capitalize on the changes.'
        },
        report: {
            crumbs: 'CBRE Forum 2021 post event survey',
            title: 'CBRE FORUM 2021 POST EVENT SURVEY',
            info: 'Thank you for joining us at CBRE FORUM 2021! This event would usually be held in person, but it switched online this year due to the COVID-19 pandemic. We’d love to hear your thoughts on this online forum as your feedback will certainly help us improve future events. \n\nHow satisfied were you with the followings?',
            formTitle1: 'Event program',
            formText1: 'Please rate the event program',
            formTitle2: 'Overall content',
            formText2: 'Please rate the overall content',
            formTitle3: 'Relevance of the topics to your business',
            formText3: 'Please rate the relevance of the topics to your business',
            formTitle4: 'CHANGE | Greater China Theme Report Sharing',
            formText4: 'Please rate the report sharing session',
            formTitle5: 'LEADING THE CHANGE Break-out I: Investment | Change vs. Chance',
            formText5: 'Please rate the Investment break-out',
            formTitle6: 'LEADING THE CHANGE Break-out II: Office | The Next Normal',
            formText6: 'Please rate the Office break-out',
            formTitle7: 'LEADING THE CHANGE Break-out III: Industrial & Logistics | New Forces',
            formText7: 'Please rate the Industrial & Logistics break-out',
            formTitle8: 'LEADING THE CHANGE Break-out IV: Consulting Plus | Towards Future City',
            formText8: 'Please rate the Consulting Plus break-out',
            formTitle9: 'LEADING THE CHANGE Break-out V: Hong Kong CRE | Dynamics of Change',
            formText9: 'Please rate the Hong Kong CRE break-out',
            formTitle10: 'Event platform experience',
            formText10: 'Please rate the event platform experience',
            formTitle11: 'Event timetable and duration',
            formText11: 'Please rate the event timetable and duration',
            formText12: 'Which business area would you like to get more insights into? We will contact you as soon as we receive your feedback.',
            formText13: 'Please share any suggestions or feedback for improvement.',
            buttonText: 'Submit & download',
            radioOptions1: 'Extremely dissatisfied',
            radioOptions2: 'Dissatisfied',
            radioOptions3: 'Commonly',
            radioOptions4: 'Satisfied',
            radioOptions5: 'Extremely satisfied',
            submitSuccess: 'Submitted successfully',
            download: 'Download Report'
        },
        speakers: {
            speaker1: {
                name: 'Ben Duncan',
                intro: 'President of North Asia, CBRE',
                info: 'Ben Duncan is responsible for 25 CBRE offices in Mainland China, Hong Kong, Taiwan, Japan and South Korea, and works with our country leadership on enhancing our client offering and furthering our strategic goal of becoming a truly world class organization. During Ben Duncan’s 27 years of real estate experience, he has worked in three of the world’s leading financial markets — London, Hong Kong and Tokyo — and advised clients on projects in 14 countries. In his 25 years of service in Asia Pacific since 1996, his comprehensive experience covers the full spectrum of advisory services from project leasing, strategic dispositions and asset management for owners, to portfolio management, tenant representation and strategic occupancy planning for tenants. '
            },
            speaker2: {
                name: 'Alan Li',
                intro: 'President of China, CBRE',
                info: 'Alan Li is the President of CBRE China. In this worldwide commercial real estate services and investment firm, he is responsible for driving the China market through collaborative and integrated programs across all business lines and geographies in the region. With his deep experience at both business and operational level, he has played a central role in CBRE’s localization strategy, as well as many of the company’s most successful investments and initiatives in China. He is also the leading presence in interacting with the company’s key clients and furthering important relationships with government and business partners.'
            },
            speaker3: {
                name: 'Tom Gaffney',
                intro: 'Regional Managing Director, Hong Kong, CBRE',
                info: 'Tom Gaffney oversees business and operational activities within CBRE Hong Kong, working with a wide range of teams and stakeholders to develop and implement strategy. His primary areas of responsibility include operational management of all major business lines, client relationship and engagement programs, personnel development and recruitment. Before joining CBRE he held executive positions in investment, commercial transactions, and advisory, and he is recognized in the industry for his extensive experience and leadership skills.'
            },
            speaker4: {
                name: 'Cynthia Chu',
                intro: 'Regional Managing Director, Taiwan, CBRE',
                info: 'Cynthia Chu currently heads CBRE Taiwan, and is responsible for the overall operation and management of the office, as well as supervising investment market and commercial agency services both in Taiwan and the worldwide market. Cynthia has over 26 years of professional experience in Taiwan’s commercial, retail, residential, commercial and industrial real estate market.'
            },
            speaker5: {
                name: 'Dr. Henry Chin',
                intro: 'Global Head of Investor Thought Leadership & Head of Research, APAC, CBRE',
                info: 'Dr. Henry Chin leads CBRE’s Research team providing a full range of research, analysis and consultancy services in more than 40 markets. Prior to joining CBRE, Dr. Chin was Head of Asia Pacific Research and Strategy at Pramerica Real Estate Investors (PGIM), Prudential Financials Inc., and also held several senior roles with Deutsche Bank Group. Dr. Chin is an Assistant Professor at National Taipei University (Taiwan); Guest Professor at Oxford Brookes University (UK); and Guest Speaker at Hong Kong University (HK).'
            },
            speaker6: {
                name: 'Ada Choi',
                intro: 'Head of Occupier Research & Head of Data Intelligence and Management, APAC, CBRE',
                info: 'Ada Choi leads CBRE’s occupier research in Asia Pacific and also oversees data strategy for the region. Since joining CBRE in 2005, Ada has held a variety of leadership roles, including head of capital markets research for Asia Pacific and head of research for Greater China. Prior to joining CBRE, Ada worked in financial markets research for the Hong Kong Exchanges and Clearing’s Planning and Research team, where she led research into financial market development trends and benchmarking Hong Kong against other leading global financial centres. Ada has 15 years research experience in the Asia Pacific real estate market. She holds a MSc in Financial Engineering and Quantitative Analysis for Business from the City University of Hong Kong and a BA in Business Administration from the Chinese University of Hong Kong. She is a Chartered Financial Analyst and a member of the Hong Kong Society of Financial Analysts.'
            },
            speaker7: {
                name: 'Sam Xie',
                intro: 'Head of Research, China, CBRE',
                info: 'Sam Xie currently leads the China Research team, tracking 18 major cities in China and providing market intelligence, forecast and advice to help clients formulate and execute their real estate strategies. Some of the 2020 and 2021 major reports published by Mr. Xie’s team include: China’s Response to COVID-19: Lessons for Landlords and Occupiers, Asia Pacific Retail Flash Survey - Mainland China, China 2030: New Decade, New Cycle, China Real Estate Market Outlook 2021, China Investor Intentions Survey 2021, Future of Workplace Chinese MNCs Workplace Survey Report, T25 China Tech District Rental Index, etc.'
            },
            speaker8: {
                name: 'Ping Lee',
                intro: 'Head of Research, Taiwan, CBRE',
                info: 'Ping leads the research operations for the Taiwan market. In her role, she is responsible for forming the house view on market movements and prospects by monitoring data and obtaining consensus among peers across business lines. The team produces a range of regular and topical publications, covering office, retail, industrial and hotel sectors in Taiwan. Ping also serves as the media spokesperson for CBER Taiwan and is a regular commentators on real estate market trends in the media.'
            },
            speaker9: {
                name: 'Candice Wang',
                intro: 'Executive Director of Capital Markets, China & Head of Capital Markets, Eastern China, CBRE',
                info: 'Candice Wang focuses on providing investment advisory services. She is mainly responsible for maintaining the department’s relationship with domestic and foreign investment institutions and providing professional project management consulting and other services to sellers. She has many years of experience in government and corporate work, and has rich knowledge and understanding of operations, management and sales. Since joining the real estate market, she has focused on providing real estate investment consulting services to domestic and foreign investment institutions, nationwide. The deals that she brokered are diversified, including office, retail, business park, logistics and etc.'
            },
            speaker10: {
                name: 'Joe Zhou',
                intro: 'Executive Director, Capital Markets | Capital Advisor Group, China, CBRE',
                info: 'As the Executive Director of CBRE China Capital Markets | Capital Advisor Group, Joe Zhou works closely with CBRE’s professional teams in China to enhance service offering and shape the development of the Capital Markets operations. He provides advisory expertise across investment advisory, portfolio sales, recapitalization and capital fund-raising services to help drive value and business advantage for our clients. '
            },
            speaker11: {
                name: 'Zhu Gang',
                intro: 'Executive Head of Asset Management Real Estate Fund Department of Huatai Securities',
                info: 'Leading from the front, Zhu Gang has established a full business chain system integrating Huatai Securities’ real estate development fund, real estate securitization and public REITs. He has led his team to successfully issue traditional commercial real estate such as “Poly Real Estate CMBS”, “Magnolia Plaza CMBS” and “Bao Wan Logistics Phase I REITs”. His team has also successfully issued a number of influential securitization products in the infrastructure sector. Zhu has been invited to participate in research, policy discussions and drafting of regulations for public REITs under the auspices of the National Development and Reform Commission and the China Securities Regulatory Commission.'
            },
            // 莫非
            speaker12: {
                name: 'Luke Moffat',
                intro: 'Regional Managing Director, Eastern China & Head of Advisory & Transaction Services, Greater China, CBRE',
                info: 'Luke Moffat has led CBRE in Eastern China since 2014, more than doubling the size of the business within 5 years. In 2020, Luke took on additional responsibilities across Greater China, heading up Advisory & Transaction Services. With more than 20 years of experience in the Commercial Real Estate industry, Luke is an expert especially in key markets such as Shanghai and Singapore. Luke helps to manage CBRE’s key business lines and client relationships in China with a specific focus on Office Leasing, Capital Markets, Retail Leasing, Industrial & Logistics, Valuations & Consulting, Property Management and Project Management.'
            },
            // 张越
            speaker13: {
                name: 'Fion Zhang',
                intro: 'Head of Advisory & Transaction Services I Office, Eastern China, CBRE',
                info: 'Fion Zhang heads up CBRE Eastern China Advisory & Transaction | Office business and brings along 20 years of experience in the commercial real estate market, focusing primarily on Shanghai and other Eastern China cities. She has represented various large and complex Chinese and multinational companies for their lease transactions, driving and leading entire transaction processes. She has also advised the most reputable developers and investors in this market on investor leasing services, including positioning advisory, market research, marketing, leasing and sales agency, as well as lease management.'
            },
            // 张冀苏
            speaker14: {
                name: 'Rayman Zhang',
                intro: 'Head of Advisory & Transaction Services | Office | Occupier, Northern China, CBRE',
                info: 'Rayman Zhang has 14 years of experience in the commercial real estate sector. He is responsible for driving one of CBRE’s major business line in China, and provides tailored office leasing solutions to the clients with different backgrounds. '
            },
            // 吴竞伯
            speaker15: {
                name: 'Jingbo Wu',
                intro: 'Greater China Real Estate Leader | Procurement - Real Estate Services, EY',
                info: 'Jingbo Wu, as EY Greater China Real Estate Leader, is responsible for real estate related matters in GC area. He brings along over 15 years of experience in the commercial real estate industry. He has represented the corporate side to handle various area of real estate, including: site selection, commercial lease transaction, portfolio management, leasing strategy establishment, property disposal and vendor management to drive quality real estate service to internal business & stakeholders.'
            },
            // 李艳娟
            speaker16: {
                name: 'Cindy Li',
                intro: 'Senior Administrative Director, Mininglamp Technology',
                info: 'Li Yanjuan is responsible for the overall management of the Administration Department and Purchasing Department of Mininglamp Technology. She has more than ten years of administrative experience, and has expertise in corporate location strategy, space strategy, office fit-out implementation, and space operation management. She successfully led a number of corporate office space construction projects, paying particular attention to the impact of office space on employee health, corporate culture, efficient collaboration of employees. All this integration is applied in the project of Beijing headquarters office of Mininglamp Technology.'
            },
            // 王红
            speaker17: {
                name: 'Betty Wang',
                intro: 'General Manager of Commercial Management and Office Business China Xintiandi and General Manager of INNO KIC',
                info: 'Betty Wang oversees office property business at Shui On Land and China Xintiandi. With a proven track record of nearly 20 years across residential, office, commercial property and asset management, she has spearheaded many iconic office developments such as Corporate Avenue, KIC, and THE HUB. She led her team to create the brand Shui On Workx and the INNO office brand product line. The motto of “improve oneself like polishing jade until success comes of it” keeps Betty focuses on pushing her limits, putting customers first, and pursuing relentless innovation.'
            },
            // 郝磊
            speaker18: {
                name: 'Hao Lei',
                intro: 'General Manager of JIC Holding',
                info: 'Mr. Hao Lei is currently the Director and General Manager of JIC Holding. Mr. Hao Lei has worked in the real estate finance department of state-owned commercial banks and large state-owned investment companies for a long time. He is familiar with real estate finance, real estate investment and operation and other related industries. He has accumulated comprehensive experience in real estate project transformation and operation.'
            },
             // 金子千夏
            speaker19: {
                name: 'Chinatsu Kaneko',
                intro: 'Head of Advisory & Transaction Services | Workplace Strategy, North Asia, CBRE',
                info: 'With 24 years of experience in commercial interior design, Chinatsu brings a comprehensive understanding of built space, especially in workplace. Her passion comes from her belief in the power of physical environments on human behaviors and on corporate culture, and she is committed to matching clients’ business strategy to the built environments. She has helped many clients around the world in building workplaces that increases productivity, employee engagement, and enriches the client’s work culture.\n\nWith 24 years of experience in commercial interior design and workplace consulting, Chinatsu believes in the power of physical environments on human behaviors and on corporate culture, and she is committed to matching clients’ business strategy to their workplace. She has helped many clients around the world in building workplaces that increases productivity, employee engagement, and enriches the client’s work culture. And as one of CBRE workplace’s global leaders, she has insights into the most current trends of workplace evolution.'
            },
            speaker20: {
                name: 'Cindy Sun',
                intro: 'Head of Advisory & Transaction Services and Capital Markets | Industrial & Logistics Investment, China, CBRE',
                info: 'Cindy joined the industry in 2007, and now boasts more than 14 years’ experience in industrial real estate. She has focused on providing services for many MNC manufacturing and logistics occupiers in site selection, lease acquisition, relocation negotiation, disposal acquisition as well as working closely with investment funds/developers and governments for portfolio transaction and fund raising. She has completed transactions for a total of 130 million square meters of industrial land on behalf of many companies. In the past two years, she has been responsible for nearly 13 billion yuan worth of M&A projects.'
            },
            speaker21: {
                name: 'Ping Liu',
                intro: 'Chief Engineer of Shanghai Municipal Commission of Economy and Informatization',
                info: ''
            },
            speaker22: {
                name: 'Simon Wang',
                intro: 'General Manager of ESR East China and Vice President of ESR China',
                info: 'Simon Wang worked for GLP (China) from 2009 to 2011, before joining ICBC AXA at the beginning of 2011. At the end of the same year, he became a member of ESR group and began his career with ESR. Wang served as the director of ESR China key account and the general manager of ESR Central and ESR Western China. He is currently the general manager of ESR East China and vice president of ESR China. He is mainly responsible for the operation and management of ESR’s project investment, development and construction, external leasing and property management in East China.'
            },
            speaker23: {
                name: 'Alex Chen',
                intro: 'Head of Consulting, China, CBRE',
                info: 'Alex Chen has more than 15 years’ experience in the fields of strategic consulting and management, with extensive hands-on proficiency in urban development strategy, regional planning and development, urban renewal, TOD, etc. He has been a consultant to governments at all levels, state-owned platform companies and large developers for many years, and has successfully led projects nationwide. As an opinion leader across markets, he delivers profound insights and opinions in the press and public forums. With his rich experience and excellent reputation in the industry, Alex has now been appointed as one of the research members of the Shanghai Municipal People’s Government’s Development Research Center and the Shanghai Institute for Global City.'
            },
            speaker24: {
                name: 'Professor Chen Xian',
                intro: 'Professor of Antai College of Economics and Management, Shanghai Jiaotong University; distinguished researcher at the Shanghai Institute for Global City.',
                info: 'Chen Xian is an expert on special government allowance of the State Council. The main teaching and research fields are macroeconomics, public economics, service economy and trade. The main social part-time jobs are vice president of Chinese Association of Market Development, doctoral supervisor of Shanghai Academy of Social Sciences and vice president of Shanghai Economist Association. In recent years, he has focused on entrepreneurship, innovation and regional economic integration. He once attended the symposium of economic situation experts and entrepreneurs by Premier Li Keqiang and made a speech entitled "Economic Stabilization, Kinetic Energy transformation and Mass Entrepreneurship and Innovation ".'
            },
            speaker25: {
                name: 'Allen Xiao',
                intro: 'Head of Consulting, Southern China, CBRE',
                info: 'Allen is highly recognized in the commercial real estate market with his distinguished management skills. For the past15 years, he has been an expert in industrial development strategy, new city development, TOD, urban renewal, and One Belt, One Road initiatives. He has been working with a variety of clients ranging from government to large platform companies. Allen has worked in the UK and many other locations around the world. He is especially well known for his profound understanding of the Greater Bay Area (GBA) markets and strong network in the industry. He is an advisor to Shenzhen government, providing insights for regional industrial development, and is also a member of the Chinese Examiner of RICS.'
            },
            speaker26: {
                name: 'Professor. Xu Lei Qing',
                intro: 'Professor, College of Architecture and Urban Planning, Tongji University; Director, Urban Renewal Center, National Institute of Modernization, Tongji University',
                info: 'Professor Xu Leiqing’s research fields include urban renewal, community renewal, urban design, architectural design, environmental design, etc. His research touched on the important themes of humanized urban space, including health, safety, efficiency, comfort, beauty, satisfaction, and diversity. He is an important scholar and representative of the relationship between urban space environment and behavior, psychology and body. At the same time, he is also a well-known thinker, researcher and designer in the field of urban and community renewal in China. '
            },
            speaker27: {
                name: 'Tony Lu',
                intro: 'Associate Director, Consulting, China, CBRE',
                info: 'Tony Lu has 10 years of urban strategy consulting work experience. He has accumulated rich practical experience in consulting, regional comprehensive development, urban renewal, corporate strategy and other fields. The projects and clients he served are all over China.'
            },
            speaker28: {
                name: 'Jolly Shen',
                intro: 'Project leader of The Rising Lab and Member of editorial board of YiMagazine',
                info: 'Jolly built up The Rising Lab as a business-data-focused city program under Yicai Media Group in 2015 and defined the widely circulated concept about “Emerging New First-tier City” in China. Based on years of experience of business reporting, Shen leads The Rising Lab team analyzing current situation of Chinese cities, exploring the future of them, and providing quality data analysis and services for city dwellers, urban planners and investors.'
            },
            speaker29: {
                name: 'Fred Wang',
                intro: 'Head of Consulting, Jiangsu, CBRE',
                info: 'Fred Wang brings along 10 years of experience in strategic consulting and real estate consulting, focusing primarily on Jiangsu, Anhui province and surrounding cities. He is especially good at consulting services in urban renewal, industrial planning, commercial real estate, etc. He provides real estate developers and governments with the whole process of consulting services, from industry development to investment promotion support. At the same time, Fred has also represented CBRE to participate in various government events, forums and delivered keynote speeches.  '
            },
            speaker30: {
                name: 'Marcos Chan',
                intro: 'Head of Research, Hong Kong, CBRE',
                info: 'Marcos Chan joined CBRE in 2014, he oversees a team of research professionals, covering key property sectors in Hong Kong. He also works closely with the Mainland China, Asia Pacific and Global Research teams to proactively drive CBRE’s ongoing objective to produce first-class industry insight, research and analysis for CBRE clients. Marcos is a member of CBRE’s Asia Pacific Research Executive Committee. A member of the Royal Institute of Chartered Surveyors, Marcos has 22 years of property market experience behind him, 20 of these with top global surveying firms where he held various leadership positions in the firms’ Research teams across Hong Kong and Mainland China. Marcos also has diverse experience in leading research consultancy projects for corporate occupiers and investors. In his earlier days, Marcos worked for a Hong Kong developer where he was engaged in pre-acquisition financial analyses and feasibility studies for investment opportunities in the local and overseas real estate markets. Marcos has a profound understanding of the real estate market in the Greater China region, and with his strong research background in real estate agency, he is well-versed with occupier trends and landlord requirements. Mr. Chan is also an active property market commentator in the media.'
            },
            speaker31: {
                name: 'Reeves Yan',
                intro: 'Head of Capital Markets Hong Kong, CBRE',
                info: 'Reeves Yan is responsible for the Capital Markets business for CBRE in Hong Kong and lead a team of consultants in delivering world class investment solutions to clients. Reeves has over 15 years real estate experience. Reeves started his career in real estate as a valuation surveyor, and progressively moved into property investment space, rising as one of the top deal makers in the industry. With his well proven track record and valuable experience, he delivers high standard of professional services to property developers, institutional fund managers, family offices, high-net-worth individuals, Mainland China and overseas investors, etc. Earlier in his career, he was named Young Surveyor of the Year (then Young Achiever of the Year) by RICS Hong Kong Awards in 2013 in recognition of his contribution to the profession. During 2019-2021, Reeves is the Assessor for RICS HK.'
            },
            speaker32: {
                name: 'Samuel Lai',
                intro: 'Head of Advisory & Transaction Services | Industrial & Logistics, Hong Kong, CBRE',
                info: 'Samuel Lai is responsible for managing the Advisory & Transaction Services – Industrial & Logistics business in Hong Kong. Samuel has extensive experience in transacting industrial properties, mega-sized leasing and project marketing. He is extremely well regarded as a leader in the local market for his dedication and professionalism. Samuel works closely with research team in producing industry white papers and thought leadership pieces, and he also communicates frequently with the public on hot and pressing topics facing the sector. With dedicated focus to the sector, CBRE’s Industrial team has been awarded the RICS Hong Kong Award for Industrial Team of the Year from 2012-2015 and the Goodman Leasing Deal of the Year 2014/5. Samuel brings with him over 17 years of experience in various real estate sectors, 16 of which he has specialized in the industrial sector in Hong Kong. Samuel joined CBRE in 2008, his first major focus was Goodman’s mega warehouse project, Interlink, which was highly successful, with 99% pre-commitment upon the building’s completion in 2012. '
            },
            speaker33: {
                name: 'Ada Fung',
                intro: 'Head of Advisory & Transaction Services | Office, Hong Kong, CBRE',
                info: 'Ada Fung oversees our Advisory & Transaction Services | Office as well as Transaction Management and Workplace Strategy team, with more than 100 real estate professionals altogether. Her teams provide a range of services to both tenants and landlords in the commercial office sector. With over 20 years of experience in the Real Estate industry, Ada has built a strong industry network and worked at every level in the business. Her focus is on creating results for the business and building successful teams. Prior to this role, Ada has lead our Greater China Sales Management and Pitch Management teams; she was also our Business Development Director for Project Management Team. Ada is a chartered Town Planner, she had worked in the Government and also in Sales and Marketing in real estate firm where she starts her career.'
            },
            speaker34: {
                name: 'Lawrence Wan',
                intro: 'Head of Advisory & Transaction Services | Retail, Hong Kong, CBRE',
                info: 'Lawrence Wan is responsible for steering Hong Kong Advisory & Transaction Services | Retail team to deliver exception solutions for clients. Lawrence has more than 15 years of experience in retail leasing. His most recent role was with Dickson International Concepts Limited as Group Property Manager, where he oversaw a 300,000 square feet real estate portfolio of luxury brands such as Harvey Nichols, Brooks Brothers, Rolex and Tommy Hilfiger across Mainland China, Hong Kong and Macau. '
            },
            speaker35: {
                name: 'Manyi Choi',
                intro: 'Head of Advisory & Transaction Services | Occupier Consulting and Workplace Strategy, Greater China, CBRE',
                info: 'Based in Hong Kong, Manyi’s expertise lies in Workplace Strategy and Change Management for corporate clients. She has over ten years’ experience and a wide exposure working for a diverse range of clientele, including financial institutions, technological firms, insurance and other professional services, logistics and food & beverages companies across Hong Kong and Mainland China. She has successfully facilitated her clients to create an effective workplace environment tailored to their business, culture and people. Prior to this position, Manyi has worked as a Strategy and Change consultant in one of the famous design firm in Hong Kong and have been a core member of an independent Workplace Consultant firm from 2017 – 2019. Manyi also have a hands-on experience in working on projects from beginning to the end, from early strategy stage to test-fits, design concept, design details, on-site construction and final delivery.'
            }
        },
        agenda: {
            item1: {
                type: 'Speaker',
                time1: 'A.M. Online',
                time2: '10:00-10:15',
                name: 'LOOK BEYOND | Opening',
                key1: 'Ben Duncan',
                val1: 'President, North Asia, CBRE',
                key2: 'Alan Li',
                val2: 'President, China, CBRE',
                key3: 'Tom Gaffney',
                val3: 'Regional Managing Director, Hong Kong, CBRE',
                key4: 'Cynthia Chu',
                val4: 'Regional Managing Director, Taiwan, CBRE',
            },
            item2: {
                type: 'Speaker',
                time1: 'A.M. Online',
                time2: '10:15-10:50',
                name: 'CHANGE | Greater China Theme Report',
                key1: 'Dr. Henry Chin',
                val1: 'Global Head of Investor Thought Leadership & Head of Research, APAC, CBRE',
                key2: 'Ada Choi',
                val2: 'Head of Occupier Research & Head of Data Intelligence and Management, APAC, CBRE',
                key3: 'Sam Xie',
                val3: 'Head of Research, China, CBRE',
                key4: 'Ping Lee',
                val4: 'Head of Research, Taiwan, CBRE',
            },
            item3: {
                type: 'Speaker',
                time1: 'A.M. Online',
                time2: '10:50-11:00',
                name: 'CHANGE Talks',
                key1: 'Christina Liu',
                val1: 'Regional Managing Director, CBRE Northern China',
                key2: 'Luke Moffat',
                val2: 'Regional Managing Director, CBRE Eastern China',
                key3: 'Denny He',
                val3: 'Deputy General Manager, CBRE Hangzhou',
                key4: 'Fred Wang',
                val4: 'Head of Consulting, CBRE Jiangsu',
                key5: 'Henry Wang',
                val5: 'General Manager, CBRE Wuhan',
                key6: 'Shelly Xie',
                val6: 'General Manager, CBRE Southwest China',
                key7: 'Dylan Li',
                val7: 'General Manager, CBRE Xi’an & Zhengzhou',
                key8: 'Amy Yan',
                val8: 'General Manager, CBRE Guangzhou',
                key9: 'Frank Wu',
                val9: 'General Manager, CBRE Shenzhen',
            },
            item4: {
                type: 'breakouts',
                time1: '上午在线',
                time2: '11:00-12:15',
                name: 'LEADING THE CHANGE | Breakouts',
                key1: 'Break-out I: Investment | Change v.s Chance',
                key2: 'Break-out II: Office | The Next Normal',
                key3: 'Break-out III: Industrial & Logistics | New Forces',
                key4: 'Break-out IV: Consulting Plus | Towards Future City',
                key5: 'Break-out V: Hong Kong CRE I Dynamics of Change',
            },
        },
        live: {
            send: 'Send',
            switching: 'Switching...',
            switched: 'Switch complete',
            items: {
                item1: 'Investment',
                val1: 'Change v.s Chance',
                item2: 'Office',
                val2: 'The Next Normal',
                item3: 'Industrial & Logistics',
                val3: 'New Forces',
                item4: 'Consulting Plus',
                val4: 'Towards Future City',
                item5: 'Hong Kong CRE',
                val5: 'Dynamics of Change'
            }
        },
        faq: {
            more: 'More questions: Send an email to',
            problem: {
                '0': 'How to get the best watching experience?',
                '1': 'How to switch language on the website?',
                '2': 'How to switch the soundtrack of the plenary?',
                '3': 'How to switch between the plenary and the presentation documents？',
                '4': 'How to watch it in full screen?',
                '5': 'How to switch between breakout sessions?',
                '6': 'Can I ask questions to speakers during the live broadcast?',
                '7': 'Can I login with the same account on both PC and mobile devices? ',
                '8': 'How to save the website if I entered it via WeChat?',
                '9': 'How to get the presentation documents?',
                '10': 'How to download report?',
                '11': 'How to get business contact?',
                '12': 'If I missed the live broadcast, can I watch it again?'
            },
            answer: {
                '0': 'Use Google Chrome browser.',
                '1': 'Switch the language by clicking on the language switch function on the upper right corner of the website.',
                '2': 'Switch the soundtrack by clicking "Language" at the bottom-right of the home screen.',
                '3': 'Switch it by clicking the switch button on the secondary screen on the right.',
                '4': 'Click the “full screen” button on the home screen to enlarge the screen.',
                '5': 'Click the small thumbnails for watching the other parallel sessions below.',
                '6': 'Send your questions in the chat box.',
                '7': 'It is recommended to enter through a PC device to enjoy the best watching experience. ',
                '8': 'Keep the floating window on if you have entered the website via WeChat.',
                '9': 'Send an email to marcomms@cbre.com.cn if you are interested in receiving our presentation documents.',
                '10': 'Click the “Report” button on the left menu bar to download after completing the post-event survey.',
                '11': 'Click “Contact us” at the bottom of the website.',
                '12': 'Visit this website for a replay on Tuesday, September 28, after 9 am.'
            }
        },
        local: {
            local: 'Local lnsights',
            items: {
                title1: 'BEIJING',
                title2: 'SHANGHAI',
                title3: 'HANGZHOU',
                title4: 'NANJING',
                title5: 'WUHAN',
                title6: 'XIAN & ZHENGZHOU',
                title7: 'SOUTHWEST CHINA',
                title8: 'GUANGZHOU',
                title9: 'SHENZHEN',
                title10: 'HONG KONG'
            }
        },
        draw: {
            item1: 'Embark on a trip to the universe of colors! ',
            item2: 'Time to refill your energy! ',
            item3: 'Pack up and explore!',
            item4: 'Release your creativity!',
            item5: 'Blow your stress away! ',
            item6: 'Bring the whole world into focus! ',
            item7: 'Something is missing? Maybe it’s TIME!',
            item8: 'Congratulations! Leave your mailing info below and wait for your CHANGE blind box to come.',
            error: 'They are all gone… Better luck next time!',


        },
        write: {
            title1: 'Congratulations! ',
            title2: 'Leave your mailing info below and wait for your CHANGE blind box to come.',
            mobile: 'mobile',
            address: 'Address',
            firstCheck: 'By clicking this checkbox and submitting the information above, you acknowledge that you have read this {0} and that your personal information could be used in accordance with the terms of the Privacy Policy.',
            firstCheck0: 'Privacy Policy',
            submit: 'Submit',
            success: 'The blind box will be dispatched soon; we will notify you by email when the courier is sent out.',
            error: 'Submission failed. Please fill in the correct information'
        }
    }
}
























