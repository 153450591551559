<template>
    <div class="content speakers-content">
        <div class="crumbs">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <i class="fa fa-caret-right"></i>
            <span>{{ $t('nav.speakers') }}</span>
        </div>
        <template v-if="speakers.length > 0">
            <speakers-pc :speakers="speakers"></speakers-pc>
            <speakers-phone :speakers="speakers"></speakers-phone>
        </template>

    </div>
</template>
<script>
import SpeakersPc from './speakers/SpeakersPc.vue'
import SpeakersPhone from './speakers/SpeakersPhone.vue'
import { mapMutations } from 'vuex'
export default {
    components:{
        SpeakersPc,
        SpeakersPhone
    },
    data(){
        return {
            speakers: [
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210810145326.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210810153319.png",
                    "name_zh": "戴柏勤",
                    "intro_zh": "世邦魏理仕北亚区总裁",
                    "info_zh": "戴柏勤负责管辖世邦魏理仕分布在中国内地、香港、台湾、日本、韩国的25家分公司，并与北亚各国的世邦魏理仕管理层致力于缔造卓越的客户服务，从而进一步帮助公司实现成为真正的世界级公司的战略目标。在戴柏勤先生27年的房地产职业生涯里，他先后供职于伦敦、香港和东京三大国际金融中心，为遍及14个国家的各类地产项目提供顾问服务。自1996年起，他已于亚太地区工作了25年，并在项目租赁、战略处置、资产管理等业主服务，以及资产组合管理、业主代表、战略租用规划等租户服务方面积累了丰富的经验。",
                    "name_en": "Ben Duncan",
                    "intro_en": "President of North Asia, CBRE",
                    "info_en": "Ben Duncan is responsible for 25 CBRE offices in Mainland China, Hong Kong,_en Taiwan, Japan and South Korea, and works with our country leadership on enhancing our client offering and furthering our strategic goal of becoming a truly world class organization. During Ben Duncan’s 27 years of real estate experience, he has worked in three of the world’s leading financial markets — London, Hong Kong and Tokyo — and advised clients on projects in 14 countries. In his 25 years of service in Asia Pacific since 1996, his comprehensive experience covers the full spectrum of advisory services from project leasing, strategic dispositions and asset management for owners, to portfolio management, tenant representation and strategic occupancy planning for tenants. ",
                    "index": 1
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910135732.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910135752.png",
                    "name_zh": "李凌",
                    "intro_zh": "世邦魏理仕中国区总裁",
                    "info_zh": "李凌现任世邦魏理仕中国区总裁，负责领导并推动覆盖全业务线和区域的协作计划和综合方案，促进世邦魏理仕在中国商业地产服务和投资业务的发展。凭借其在业务和运营层面的丰富经验，李凌在公司的本土化战略以及关键投资和计划中都发挥了核心作用，同时也主导并积极参与维护主要客户、政府和商业伙伴关系，在其中担任着举足轻重的角色。",
                    "name_en": "Alan Li",
                    "intro_en": "President of China, CBRE",
                    "info_en": "Alan Li is the President of CBRE China. In this worldwide commercial real _enestate services and investment firm, he is responsible for driving the China market through collaborative and integrated programs across all business lines and geographies in the region. With his deep experience at both business and operational level, he has played a central role in CBRE’s localization strategy, as well as many of the company’s most successful investments and initiatives in China. He is also the leading presence in interacting with the company’s key clients and furthering important relationships with government and business partners.",
                    "index": 2
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910135823.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910135909.png",
                    "name_zh": "郭富礼",
                    "intro_zh": "世邦魏理仕香港区域董事总经理",
                    "info_zh": "郭富礼负责监督世邦魏理仕在香港区域的所有商业及运营活动，包括与不同团队和股东合作制定和实践世邦魏理仕的战略，并监督所有主要业务线的运营、客户关系及满意度项目，以及个人发展与人才招募等。郭先生具有广泛的房地产经验，在加入世邦魏理仕前，他曾于投资、商业交易及顾问服务业出任行政职位，领导才能在业内备受尊崇。",
                    "name_en": "Tom Gaffney",
                    "intro_en": "Regional Managing Director, Hong Kong, CBRE",
                    "info_en": "Tom Gaffney oversees business and operational activities within CBRE Hong _enKong, working with a wide range of teams and stakeholders to develop and implement strategy. His primary areas of responsibility include operational management of all major business lines, client relationship and engagement programs, personnel development and recruitment. Before joining CBRE he held executive positions in investment, commercial transactions, and advisory, and he is recognized in the industry for his extensive experience and leadership skills.",
                    "index": 3
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910135836.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910135935.png",
                    "name_zh": "朱幸儿",
                    "intro_zh": "世邦魏理仕台湾区域董事总经理",
                    "info_zh": "朱幸儿现带领世邦魏理仕台湾团队，负责台湾分公司整体的运营及管理，并监督台湾及全球的投资市场与商业地产中介服务。她拥有超过26年以上的商业、工业、零售及住宅不动产专业投资买卖及顾问服务经验。",
                    "name_en": "Cynthia Chu",
                    "intro_en": "Regional Managing Director, Taiwan, CBRE",
                    "info_en": "Cynthia Chu currently heads CBRE Taiwan, and is responsible for the overa_enll operation and management of the office, as well as supervising investment market and commercial agency services both in Taiwan and the worldwide market. Cynthia has over 26 years of professional experience in Taiwan’s commercial, retail, residential, commercial and industrial real estate market.",
                    "index": 4
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140434.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140422.png",
                    "name_zh": "金纬博士",
                    "intro_zh": "世邦魏理仕全球资本市场投资以及亚太区研究部负责人",
                    "info_zh": "金纬博士所领导管理的研究团队，范围涵盖超过40个市场。加入世邦魏理仕之前，金博士曾在保德信金融集团的房地产投资公司担任亚太区研究及策略部主管，并且在德意志银行集团担任多个高级职位。金纬博士还致力于在学术圈贡献所长，目前任职台湾国立台北大学助理教授、英国牛津布鲁克斯大学客座教授、香港大学演讲嘉宾。",
                    "name_en": "Dr. Henry Chin",
                    "intro_en": "Global Head of Investor Thought Leadership & Head of Research, APAC, CBRE",
                    "info_en": "Dr. Henry Chin leads CBRE’s Research team providing a full range of research, analysis and consultancy services in more than 40 markets. Prior to joining CBRE, Dr. Chin was Head of Asia Pacific Research and Strategy at Pramerica Real Estate Investors (PGIM), Prudential Financials Inc., and also held several senior roles with Deutsche Bank Group. Dr. Chin is an Assistant Professor at National Taipei University (Taiwan); Guest Professor at Oxford Brookes University (UK); and Guest Speaker at Hong Kong University (HK).",
                    "index": 5
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140506.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140524.png",
                    "name_zh": "蔡詠嘉",
                    "intro_zh": "世邦魏理仕亚太区租户市场研究部以及数据讯息与管理负责人",
                    "info_zh": "蔡詠嘉负责世邦魏理仕在亚太区的租户市场研究，并监督该地区的数据战略。自2005年加入世邦魏理仕以来，她担任过各种领导职务，包括亚太区资本市场研究部负责人和大中华区研究部负责人。在加入世邦魏理仕之前，蔡詠嘉在香港交易及结算所的规划和研究团队从事金融市场研究工作，她负责研究金融市场的发展趋势，并将香港与其他全球领先的金融中心进行比较。她在亚太区房地产市场有15年的研究经验，并拥有香港城市大学的金融工程和商业定量分析硕士学位，以及香港中文大学的工商管理学士学位。她是特许金融分析师，也是香港金融分析师协会的成员。",
                    "name_en": "Ada Choi",
                    "intro_en": "Head of Occupier Research & Head of Data Intelligence and Management, APAC, CBRE",
                    "info_en": "Ada Choi leads CBRE’s occupier research in Asia Pacific and also oversees data strategy for the region. Since joining CBRE in 2005, Ada has held a variety of leadership roles, including head of capital markets research for Asia Pacific and head of research for Greater China. Prior to joining CBRE, Ada worked in financial markets research for the Hong Kong Exchanges and Clearing’s Planning and Research team, where she led research into financial market development trends and benchmarking Hong Kong against other leading global financial centres. Ada has 15 years research experience in the Asia Pacific real estate market. She holds a MSc in Financial Engineering and Quantitative Analysis for Business from the City University of Hong Kong and a BA in Business Administration from the Chinese University of Hong Kong. She is a Chartered Financial Analyst and a member of the Hong Kong Society of Financial Analysts.",
                    "index": 6
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140547.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140537.png",
                    "name_zh": "谢晨",
                    "intro_zh": "世邦魏理仕中国区研究部负责人",
                    "info_zh": "谢晨所领导的中国区研究团队追踪中国区18个主要城市房地产市场，提供针对各类物业市场的研究咨询、市场预测和策略意见，以帮助客户更明智地制定和执行相关的房地产策略。2020及2021年出版的主要商业地产专题报告包括：《中国战疫十策：商业地产指南》、《亚太区零售租户调查 - 中国内地》、《大中华区2030年展望报告 - 应运而胜》、《2021年中国房地产市场展望》、《2021年中国投资者意向调查报告》、《未来办公：中资企业办公场所调查 | 如何打造未来办公空间》、《T25中国科创中心租金指数》等。",
                    "name_en": "Sam Xie",
                    "intro_en": "Head of Research, China, CBRE",
                    "info_en": "Sam Xie currently leads the China Research team, tracking 18 major citie_ens in China and providing market intelligence, forecast and advice to help clients formulate and execute their real estate strategies. Some of the 2020 and 2021 major reports published by Mr. Xie’s team include: China’s Response to COVID-19: Lessons for Landlords and Occupiers, Asia Pacific Retail Flash Survey - Mainland China, China 2030: New Decade, New Cycle, China Real Estate Market Outlook 2021, China Investor Intentions Survey 2021, Future of Workplace Chinese MNCs Workplace Survey Report, T25 China Tech District Rental Index, etc.",
                    "index": 7
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140558.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140612.png",
                    "name_zh": "李嘉玶",
                    "intro_zh": "世邦魏理仕台湾研究部负责人",
                    "info_zh": "李嘉玶小姐现担任世邦魏理仕台湾研究部负责人，负责研究台湾地区商用不动产市场走向，并定期发表对总体经济、投资市场及租赁市场的预测。李小姐带领团队分析各类型不动产的市场表现与长期发展趋势，据以撰写相关研究报告，内容涵盖商用不动产投资市场、办公室及零售物业租赁市场、旅馆市场及不动产投资信托基金等面向。熟稔中英文，拥有美国康乃尔大学都市与区域计划研究所硕士学位。",
                    "name_en": "Ping Lee",
                    "intro_en": "Head of Research, Taiwan, CBRE",
                    "info_en": "Ping leads the research operations for the Taiwan market. In her role,_en she is responsible for forming the house view on market movements and prospects by monitoring data and obtaining consensus among peers across business lines. The team produces a range of regular and topical publications, covering office, retail, industrial and hotel sectors in Taiwan. Ping also serves as the media spokesperson for CBER Taiwan and is a regular commentators on real estate market trends in the media.",
                    "index": 8
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914171553.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914171540.png",
                    "name_zh": "刘焕丽",
                    "intro_zh": "世邦魏理仕华北区董事总经理",
                    "info_zh": "作为华北区的董事总经理，刘焕丽女士负责监管整个区域所有业务线的日常工作及业绩表现。刘焕丽拥有22年的从业经验，深谙中国商业地产市场。她于2002年正式加入世邦魏理仕，是北京办公室任职时间最长的员工之一。",
                    "name_en": "Christina Liu",
                    "intro_en": "Regional Managing Director, Northern China, CBRE",
                    "info_en": "As Regional Managing Director of CBRE Northern China, Christina Liu has assumed overall responsibility for all business line activities and operational performance from client development and service delivery excellence to financial results. Christina has 22 years of experience with an in-depth market knowledge and a wide array of commercial real estate industry best practices. Joining CBRE in 2002, Christina has become one of the longest serving employees in the Beijing office.",
                    "index": 9
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140954.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141019.png",
                    "name_zh": "莫非",
                    "intro_zh": "世邦魏理仕华东区区域董事总经理兼大中华区顾问及交易服务部负责人",
                    "info_zh": "莫非自2014年起担任世邦魏理仕华东区区域董事总经理一职，在5年内带领华东区团队业务增长2倍以上。自2020年起，莫非兼任管理大中华区顾问及交易服务部。他拥有超过20年的商业地产从业经验，是上海和新加坡办公楼市场的专家。莫非负责管理华东区主要业务，同时维护管理大客户关系，以办公楼租赁、投资、零售、工业物流、专业咨询及项目管理等为工作重点。",
                    "name_en": "Luke Moffat",
                    "intro_en": "Regional Managing Director, Eastern China & Head of Advisory & Transaction Services, Greater China, CBRE",
                    "info_en": "Luke Moffat has led CBRE in Eastern China since 2014, more than doubling the size of the business within 5 years. In 2020, Luke took on additional responsibilities across Greater China, heading up Advisory & Transaction Services. With more than 20 years of experience in the Commercial Real Estate industry, Luke is an expert especially in key markets such as Shanghai and Singapore. Luke helps to manage CBRE’s key business lines and client relationships in China with a specific focus on Office Leasing, Capital Markets, Retail Leasing, Industrial & Logistics, Valuations & Consulting, Property Management and Project Management.",
                    "index": 10
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914171341.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914171408.png",
                    "name_zh": "何达",
                    "intro_zh": "世邦魏理仕杭州分公司副总经理兼杭州战略顾问部负责人",
                    "info_zh": "何达拥有十五年以上的商业地产及片区开发经验，主要从事商业综合体、新城开发、产业规划等领域的工作。作为一名规划领域专家，是多个政府及大型投资商的专家库成员，对城市发展、产业规划有卓越研究及洞见。何达负责管理杭州公司主要业务，同时负责维护大客户关系，以专业开发咨询及项目管理为工作重点。",
                    "name_en": "Denny He",
                    "intro_en": "Deputy General Manager & Head of Consulting, Hangzhou, CBRE",
                    "info_en": "Denny He has more than 15 years’ experience in commercial real estate and area development, mainly engaged in commercial complex, new town development, industrial planning and other fields. As an expert in the field of planning, he is a member of think tanks of many governments and large investors, and has excellent research and insight into urban development and industrial planning. Denny is responsible for managing the main business of CBRE Hangzhou and maintaining key customer relations, focusing on professional development consulting and project management.",
                    "index": 11
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141802.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141752.png",
                    "name_zh": "王飞",
                    "intro_zh": "世邦魏理仕江苏区域战略顾问部负责人",
                    "info_zh": "王飞拥有10年的战略咨询、地产咨询、及专项顾问服务经验，尤其擅长城市更新、产业规划、商业地产等业务领域，主要负责苏皖及周边城市业务，为当地开发商及政府提供从产业发展到宣传推广保障的全流程顾问服务，并多次代表世邦魏理仕参加各地政府的专题活动、论坛并发表主旨演讲。",
                    "name_en": "Fred Wang",
                    "intro_en": "Head of Consulting, Jiangsu, CBRE",
                    "info_en": "Fred Wang brings along 10 years of experience in strategic consulting and r_eneal estate consulting, focusing primarily on Jiangsu, Anhui province and surrounding cities. He is especially good at consulting services in urban renewal, industrial planning, commercial real estate, etc. He provides real estate developers and governments with the whole process of consulting services, from industry development to investment promotion support. At the same time, Fred has also represented CBRE to participate in various government events, forums and delivered keynote speeches.  ",
                    "index": 12
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914172032.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914172044.png",
                    "name_zh": "王祎",
                    "intro_zh": "世邦魏理仕武汉分公司董事总经理",
                    "info_zh": "王祎先生负责领导并推动武汉地区的协作计划和综合方案，促进世邦魏理仕在武汉的商业地产服务及投资业务的发展。他同时担任世邦魏理仕华中区战略顾问部负责人，全面负责华中区战略顾问部业务。王祎先生拥有15年的房地产顾问、研究、招商及运营经验，并在行业内长期担任管理职位。他曾带领团队为各领域客户提供房地产开发可行性研究、市场研究、规划研究及投资领域的专业咨询等服务。他领导并参与的项目类型极其丰富，曾为华中区众多投资机构及开发商提供专业服务，包括办公楼、商业、酒店、公寓、住宅、产业园、物流园区等多种物业类型。在本土经验方面，他也为华中区多个政府提供外商投资、招商引资策略、房地产产业以及区域规划咨询等多项服务。王祎先生持有华中科技大学社会学硕士学位。",
                    "name_en": "Henry Wang",
                    "intro_en": "General Manager, Wuhan, CBRE",
                    "info_en": "Henry Wang is responsible for driving the Wuhan market through collaborative and integrated programs across all business lines in the region. He is also responsible for driving the Consulting business across Central China as Head of Consulting for CBRE Central China. Henry has over 15 years of real estate experience in terms of consulting, research, transaction and operation, and has been undertaking management roles in the industry for years. Under his leadership, a strong team specialize in real estate development feasibility study, market research, urban planning as well as investment advisory. He has led and provided services for institutional investors and in office, retail, residential, industrial parks, logistics parks, service apartments and other type of property. Regarding local experience, he has led the consulting service in terms of investment, industry, and regional planning for the local government in Central China. Henry holds master degree in sociology from Huazhong University of Science and Technology.   ",
                    "index": 13
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914171735.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914171750.png",
                    "name_zh": "谢凌",
                    "intro_zh": "世邦魏理仕西南区董事总经理",
                    "info_zh": "谢凌女士负责领导并推动覆盖西南地区的协作计划和综合方案，促进世邦魏理仕在西南区商业地产服务、投资业务及战略顾问业务的发展。她拥有超过20年的房地产顾问、研究、招商及运营经验，并在行业内长期担任管理职位。她曾带领团队在物业开发、产业研究、城市再开发、资产与投资组合、招商引资等领域在中国西南市场建立了强有力的口碑与卓越地位。她领导并参与的项目类型极其丰富，曾为多个国家和地区的投资机构和开发商提供专业服务，包括办公楼、零售、住宅、产业园、物流园区、服务式公寓等多种物业类型。在本土经验方面，她也为四川省、成都市政府及多个区政府以及重庆、云南、贵州等多地区政府提供外商投资、招商引资策略、房地产产业以及区域规划咨询等多项服务。谢凌女士持有四川大学文学学士学位和澳大利亚悉尼大学工商管理硕士学位，她也是皇家特许测量师学会会员。",
                    "name_en": "Shelly Xie",
                    "intro_en": "General Manager, Southwest China, CBRE",
                    "info_en": "Shelly Xie is responsible for driving the Southwest China market through collaborative and integrated programs across all business lines in the region. Shelly has over 20 years of real estate experience in terms of consulting, research, transaction and operation, and has been undertaking management roles in the industry for years. Under her leadership, a strong team specializing in consulting, industry studies, urban-regenerations as well as property portfolio management was built, establishing a strong reputation and taking the outstanding position in Southwest China. She has led and provided services for institutional investors and developers from many countries and regions in office, retail, residential, industrial parks, logistics parks, service apartments and other type of property. Regarding local experience, she has led the strategic positioning, development consultancy and land promotion services for the local government in Sichuan, which includes Chengdu; Yunnan and Guizhou provinces as well as Chongqing municipality. Shelly holds bachelor degree from Sichuan University and an M.B.A from the University of Sydney. She is also RICS member.",
                    "index": 14
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914171855.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914171842.png",
                    "name_zh": "李健",
                    "intro_zh": "世邦魏理仕西安及郑州分公司董事总经理",
                    "info_zh": "李健负责西安及郑州的经营管理及业务开展，同时整合全国资源做好城市层面规划、有针对性地为大型客户提供全面的服务。在包括产业园、办公楼及商业项目的定位策划、投资管理、招商、运营到退出交易等商业地产产业链的整个流程有将近20年的工作经验。",
                    "name_en": "Dylan Li",
                    "intro_en": "General Manager, Xi’an and Zhengzhou, CBRE",
                    "info_en": "Dylan is responsible for the operation management and business development of CBRE Xi'an and Zhengzhou, integrate national resources, make urban level planning, and provide targeted and comprehensive services for large customers. He has nearly 20 years of working experience in the whole process of commercial real estate industry chain, including industrial park, office building and commercial project positioning planning, investment management, investment attraction, operation to exit transaction.",
                    "index": 15
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914172226.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914172211.png",
                    "name_zh": "严思慧",
                    "intro_zh": "世邦魏理仕广州分公司董事总经理",
                    "info_zh": "严思慧拥有超过20年的房地产经验。自2005年加入世邦魏理仕后，曾担任广州投资部、商业服务部负责人，领导团队成员与客户合作，负责过华南地区众多商业地标项目。2018年起，严思慧任职世邦魏理仕广州分公司董事总经理，负责广州分公司的日常运营和管理，为客户提供商业地产交易代理、咨询顾问、估价、不动产管理以及项目管理等优质服务。她凭借丰富的商业地产经验、强大的客户资源及行业内的极佳声望，领导广州团队助力众多海内外发展商及客户在大湾区及珠三角地区达成发展目标。",
                    "name_en": "Amy Yan",
                    "intro_en": "General Manager, Guangzhou, CBRE",
                    "info_en": "Amy Yan has 20 years of real estate experience. Since she joined CBRE in 2015, Ms. Yan has held positions of Head of Capital Markets, Guangzhou and Head of A&T Retail, Southern China, advising clients on various land mark projects. Since 2018 Ms. Yan has taken on the role of General Manager, CBRE Guangzhou. She is responsible for the general management and daily operation of CBRE Guangzhou office, leading teams to provide clients with the full spectrum of advisory services, including project leasing, consulting, valuation, project management as well as landlord and tenant representation services. Ms. Yan has built outstanding reputation as one of the leading real estate professionals in the GBA and Pearl River Delta area, helping various global and local clients achieve strategic goals and deliver unique advantages.",
                    "index": 16
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210914172238.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210914172422.png",
                    "name_zh": "吴伟冬",
                    "intro_zh": "世邦魏理仕深圳分公司董事总经理",
                    "info_zh": "吴伟冬于2021年1月加入CBRE，目前全面负责CBRE深圳分公司业务发展和经营管理。负责业务范围涵盖大湾区及华南核心城市，如深圳、东莞、惠州及南宁、海口等。吴伟冬在地产领域有超过15年从业经历，拥有丰富的行业经验和专业的业务技能。在加入CBRE之前，吴伟冬曾在万科物流先后担任华南投资负责人和深圳区域总经理，期间主导了近20个、逾100万平米项目的投资和开发，在项目“募投管退”全周期都积累了丰富的经验和资源。",
                    "name_en": "Frank Wu",
                    "intro_en": "General Manager, CBRE Shenzhen",
                    "info_en": "Frank Wu with responsibility for all of CBRE’s business activities and performances in core GBA and Pearl River Delta cities, including Shenzhen, Dongguan, Huizhou in Guangzhou Province as well as adjacent Guangxi and Hainan Provinces. Mr. Wu has more than 15 years of experience in real estate markets. To CBRE, he brings rich business experience and in-depth industry expertise. Prior to joining CBRE, Mr. Wu worked for Vanke and held positions of Head of Investment, Southern China and General Manager, Shenzhen, leading teams to complete nearly 20 investment acquisition projects covering more than 20 million square meters. He has gained wide-ranging experience with regard to investment project lifecycle including all phases of financing, investment, post-investment management and exit transaction.",
                    "index": 17
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140703.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140640.png",
                    "name_zh": "王晶",
                    "intro_zh": "世邦魏理仕中国区投资及资本市场部执行董事兼华东区负责人",
                    "info_zh": "王晶专注于提供投资咨询服务。她主要负责维护部门与境内外投资机构的关系，为卖方提供专业的项目管理咨询等服务。她拥有多年政府及企业工作经历，具有丰富的运营、管理及销售经验。投身房地产市场后，她专注于为国内外投资机构，提供辐射全国的地产投资咨询服务。她所促成的项目较为多元，包括写字楼、商业、产业园区、物流用地等资产类别。",
                    "name_en": "Candice Wang",
                    "intro_en": "Executive Director of Capital Markets, China & Head of Capital Markets, Eastern China, CBRE",
                    "info_en": "Candice Wang focuses on providing investment advisory services. She is mainly responsible for maintaining the department’s relationship with domestic and foreign investment institutions and providing professional project management consulting and other services to sellers. She has many years of experience in government and corporate work, and has rich knowledge and understanding of operations, management and sales. Since joining the real estate market, she has focused on providing real estate investment consulting services to domestic and foreign investment institutions, nationwide. The deals that she brokered are diversified, including office, retail, business park, logistics and etc.",
                    "index": 18
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140718.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140731.png",
                    "name_zh": "周志锋",
                    "intro_zh": "世邦魏理仕中国区投资及资本市场部 | 资本顾问团队执行董事",
                    "info_zh": "周志锋作为世邦魏理仕中国区投资及资本市场部 | 资本顾问团队执行董事，与中国区各条业务线紧密合作，推动业务发展并参与相关决策。作为世邦魏理仕的差异化优势和核心价值，周先生在投资咨询业务、资产包销售业务、资本重组业务以及基金融资业务等多领域为客户提供建设性的专业意见。",
                    "name_en": "Joe Zhou",
                    "intro_en": "Executive Director, Capital Markets | Capital Advisor Group, China, CBRE",
                    "info_en": "As the Executive Director of CBRE China Capital Markets | Capital Advisor Group, Joe Zhou works closely with CBRE’s professional teams in China to enhance service offering and shape the development of the Capital Markets operations. He provides advisory expertise across investment advisory, portfolio sales, recapitalization and capital fund-raising services to help drive value and business advantage for our clients. ",
                    "index": 19
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210915133259.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210915133316.png",
                    "name_zh": "伍佩菡",
                    "intro_zh": "凯德集团（中国）商用中国基金总经理",
                    "info_zh": "伍佩菡负责凯德集团四支29亿美元的私募基金，聚焦投资于中国购物中心和综合体物业，目前资产管理规模超400亿人民币。2018年成功将基金持有约83.7亿人民币资产价值的20个购物中心出售给第三方。2019年成功将基金持有约30亿人民币的3个购物中心循环退出到凯德旗下的S-REIT – CLCT。",
                    "name_en": "Stephanie Ng",
                    "intro_en": "Capitaland (China), General Manager of Retail China Fund",
                    "info_en": "Stephanie manages four CapitaLand Group's private equity funds (\"The Funds\") with fund size of US$2.9 billion, focusing on investing in shopping malls and complex properties in China with a total AUM of more than RMB40 billion. In 2018, The Funds successfully sold 20 shopping malls with an asset value of approximately RMB 8.37 billion held by The Funds to a third party. In 2019, the accretive acquisition between The Funds and the S-REIT managed by CapitaLand – CLCT, based on an agreed value of approximately RMB3 billion for 3 shopping malls in China, is also achieved by Stephanie.",
                    "index": 20
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910140831.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910140822.png",
                    "name_zh": "朱刚",
                    "intro_zh": "华泰证券资产管理不动产基金部执行负责人",
                    "info_zh": "朱刚主导并建立了华泰证券不动产开发基金、不动产证券化与公募REITs为一体的全业务链体系。曾领导团队成功发行包括 “保利置业CMBS”、 “白玉兰广场CMBS”等传统商业不动产及“宝湾物流一期类REITs”等基础设施领域多单在行业颇具影响力的证券化产品。多次受邀参与国家发改委、中国证监会主持的公募REITs行业调研、政策研讨、法规起草等工作。",
                    "name_en": "Zhu Gang",
                    "intro_en": "Executive Head of Asset Management Real Estate Fund Department of Huatai Securities",
                    "info_en": "Leading from the front, Zhu Gang has established a full business chain system integrating Huatai Securities’ real estate development fund, real estate securitization and public REITs. He has led his team to successfully issue traditional commercial real estate such as “Poly Real Estate CMBS”, “Magnolia Plaza CMBS” and “Bao Wan Logistics Phase I REITs”. His team has also successfully issued a number of influential securitization products in the infrastructure sector. Zhu has been invited to participate in research, policy discussions and drafting of regulations for public REITs under the auspices of the National Development and Reform Commission and the China Securities Regulatory Commission.",
                    "index": 21
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141058.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141108.png",
                    "name_zh": "张越",
                    "intro_zh": "世邦魏理仕华东区顾问与交易服务|办公楼部负责人",
                    "info_zh": "张越现任世邦魏理仕华东区顾问及交易服务|办公楼部负责人，拥有20年的商业房地产工作经验，主要负责上海以及周边华东城市的业务。她代表当今市场上大型复杂的中外资企业处理他们的租赁交易，积累了包括企业中长期策略的制定与发展及其实施、选址、财务模块、资产管理、调研、物业购置、处置、重组等的丰富经验，也协助目前市场上最知名的开发商以及投资商在项目前期定位咨询、市场研究、营销推广、租赁和销售代理、资产管理等业主服务方面积累了丰富经验。",
                    "name_en": "Fion Zhang",
                    "intro_en": "Head of Advisory & Transaction Services I Office, Eastern China, CBRE",
                    "info_en": "Fion Zhang heads up CBRE Eastern China Advisory & Transaction | Office business and brings along 20 years of experience in the commercial real estate market, focusing primarily on Shanghai and other Eastern China cities. She has represented various large and complex Chinese and multinational companies for their lease transactions, driving and leading entire transaction processes. She has also advised the most reputable developers and investors in this market on investor leasing services, including positioning advisory, market research, marketing, leasing and sales agency, as well as lease management.",
                    "index": 22
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141136.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141127.png",
                    "name_zh": "张冀苏",
                    "intro_zh": "世邦魏理仕华北区顾问及交易服务 | 办公楼 | 租户部负责人",
                    "info_zh": "张冀苏先生拥有14年商业地产顾问经验，目前他负责世邦魏理仕在中国的主要业务线。他针对不同客户的需求，为其量身定制办公室租赁策略及解决方案，并完成整个顾问及交易服务的流程。",
                    "name_en": "Rayman Zhang",
                    "intro_en": "Head of Advisory & Transaction Services | Office | Occupier, Northern China, CBRE",
                    "info_en": "Rayman Zhang has 14 years of experience in the commercial real estate sector. He is responsible for driving one of CBRE’s major business line in China, and provides tailored office leasing solutions to the clients with different backgrounds. ",
                    "index": 23
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141340.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141351.png",
                    "name_zh": "吴竞伯",
                    "intro_zh": "安永大中华区房地产主管",
                    "info_zh": "吴竞伯现任安永大中华区房地产主管，拥有超过15年的商业房地产工作经验，目前主要负责大中华区（中国内地与港澳台地区）及蒙古房地产有关事务。他作为企业房地产相关人士，积累了包括企业选址、商业地产租赁、资产管理、租赁策略制定、资产处置、供应商管理协同等的丰富经验。",
                    "name_en": "Jingbo Wu",
                    "intro_en": "Greater China Real Estate Leader | Procurement - Real Estate Services, EY",
                    "info_en": "Jingbo Wu, as EY Greater China Real Estate Leader, is responsible for real estate related matters in GC area. He brings along over 15 years of experience in the commercial real estate industry. He has represented the corporate side to handle various area of real estate, including: site selection, commercial lease transaction, portfolio management, leasing strategy establishment, property disposal and vendor management to drive quality real estate service to internal business & stakeholders.",
                    "index": 24
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141254.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141302.png",
                    "name_zh": "李艳娟",
                    "intro_zh": "明略科技集团高级行政总监",
                    "info_zh": "李艳娟负责明略科技集团行政部和采购部的全面管理工作。她在行政领域深耕十余年，对企业选址策略、空间策略、工程实施、空间运营管理有着十分丰富的经验。她成功主导多个企业办公场地建设项目，尤其关注办公场所对员工健康的影响，针对办公场所对企业文化的渗透、员工的高效协作、激发创新的活力等方面深入研究并成功地将多维度视角融合应用在明略科技集团北京总部办公室建设项目中。",
                    "name_en": "Cindy Li",
                    "intro_en": "Senior Administrative Director, Mininglamp Technology",
                    "info_en": "Li Yanjuan is responsible for the overall management of the Admin_enistration Department and Purchasing Department of Mininglamp Technology. She has more than ten years of administrative experience, and has expertise in corporate location strategy, space strategy, office fit-out implementation, and space operation management. She successfully led a number of corporate office space construction projects, paying particular attention to the impact of office space on employee health, corporate culture, efficient collaboration of employees. All this integration is applied in the project of Beijing headquarters office of Mininglamp Technology.",
                    "index": 25
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141328.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141318.png",
                    "name_zh": "王红",
                    "intro_zh": "中国新天地商业管理办公业务总经理，INNO创智项目总经理",
                    "info_zh": "王红负责瑞安房地产及中国新天地旗下办公业务板块。拥有近20年房地产从业经历。经验涵盖住宅、办公、商业以及资产管理领域，打造了企业天地、创智天地、虹桥天地等多个办公项目，她带领团队创建了“瑞安办公SHUI ON WORKX”品牌，并创立了INNO办公产品线。“玉汝于成，功不唐捐”的座右铭也让王红一直不断挑战自我，坚持从客户角度出发，持续创新。",
                    "name_en": "Betty Wang",
                    "intro_en": "General Manager of Commercial Management and Office Business China Xintiandi and General Manager of INNO KIC",
                    "info_en": "Betty Wang oversees office property business at Shui On Land and China Xintiandi. With a proven track record of nearly 20 years across residential, office, commercial property and asset management, she has spearheaded many iconic office developments such as Corporate Avenue, KIC, and THE HUB. She led her team to create the brand Shui On Workx and the INNO office brand product line. The motto of “improve oneself like polishing jade until success comes of it” keeps Betty focuses on pushing her limits, putting customers first, and pursuing relentless innovation.",
                    "index": 26
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141244.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141236.png",
                    "name_zh": "郝磊",
                    "intro_zh": "建投控股总经理",
                    "info_zh": "郝磊，毕业于中国人民大学房地产专业，现任建投控股有限责任公司董事、总经理。郝磊先生曾在国有控股商业银行房地产金融部门，国有大型投资企业长期工作，熟悉房地产金融，不动产投资及运营等相关行业。2015年至今，郝磊先生深入挖掘城市存量不动产市场，陆续完成了北京嘉昱紫金中心，北京嘉昱庄盛广场，上海外滩嘉昱中心，杭州嘉昱建银中心，广州嘉昱中心等不动产再生投资项目，积累了丰富的不动产项目改造及运营经验。",
                    "name_en": "Hao Lei",
                    "intro_en": "General Manager of JIC Holding",
                    "info_en": "Mr. Hao Lei is currently the Director and General Manager of JIC Holdi_enng. Mr. Hao Lei has worked in the real estate finance department of state-owned commercial banks and large state-owned investment companies for a long time. He is familiar with real estate finance, real estate investment and operation and other related industries. He has accumulated comprehensive experience in real estate project transformation and operation.",
                    "index": 27
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141151.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141205.png",
                    "name_zh": "金子千夏",
                    "intro_zh": "世邦魏理仕北亚区顾问及交易服务|工作场所策略部负责人",
                    "info_zh": "金子千夏在商业建筑室内设计和工作场所咨询方面拥有24年的经验，她坚信高效的工作环境将对员工表现及企业文化产生深远影响，并积极致力于为客户打造与其发展战略相一致的办公环境。她助力全球范围内众多客户打造出能够提高了生产效率、员工满意度及丰富工作文化的办公空间。而作为世邦魏理仕顾问及交易服务|工作场所策略部的全球领导者之一，她对工作场所演变的最新趋势有着独到的见解。",
                    "name_en": "Chinatsu Kaneko",
                    "intro_en": "Head of Advisory & Transaction Services | Workplace Strategy, North Asia, CBRE",
                    "info_en": "With 24 years of experience in commercial interior design, Chinatsu brings a comprehensive understanding of built space, especially in workplace. Her passion comes from her belief in the power of physical environments on human behaviors and on corporate culture, and she is committed to matching clients’ business strategy to the built environments. She has helped many clients around the world in building workplaces that increases productivity, employee engagement, and enriches the client’s work culture.\n\nWith 24 years of experience in commercial interior design and workplace consulting, Chinatsu believes in the power of physical environments on human behaviors and on corporate culture, and she is committed to matching clients’ business strategy to their workplace. She has helped many clients around the world in building workplaces that increases productivity, employee engagement, and enriches the client’s work culture. And as one of CBRE workplace’s global leaders, she has insights into the most current trends of workplace evolution.",
                    "index": 28
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141519.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141507.png",
                    "name_zh": "孙洁",
                    "intro_zh": "世邦魏理仕中国区顾问及交易服务部和投资及资本市场部产业地产负责人",
                    "info_zh": "孙洁女士于2007年加入工业地产咨询行业，拥有超过14年的工业选址，投资咨询经验。专注于为许多跨国公司的制造业和终端用户提供选址、搬迁谈判，为众多机构投资者、产业地产开发商提供工业项目收购或处置服务，并与投资人就项目收购或募集基金进行密切合作等。她代表众多企业累计完成了130万平米的工业土地交易，近两年主要负责的并购项目金额接近130亿元。",
                    "name_en": "Cindy Sun",
                    "intro_en": "Head of Advisory & Transaction Services and Capital Markets | Industrial & Logistics Investment, China, CBRE",
                    "info_en": "Cindy joined the industry in 2007, and now boasts more than 14 years’ experience in industrial real estate. She has focused on providing services for many MNC manufacturing and logistics occupiers in site selection, lease acquisition, relocation negotiation, disposal acquisition as well as working closely with investment funds/developers and governments for portfolio transaction and fund raising. She has completed transactions for a total of 130 million square meters of industrial land on behalf of many companies. In the past two years, she has been responsible for nearly 13 billion yuan worth of M&A projects.",
                    "index": 29
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141534.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141545.png",
                    "name_zh": "刘平",
                    "intro_zh": "上海市经济和信息化委员会副主任",
                    "info_zh": "",
                    "name_en": "Ping Liu",
                    "intro_en": "Chief Engineer of Shanghai Municipal Commission of Economy and Informatization",
                    "info_en": "",
                    "index": 30
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141603.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141554.png",
                    "name_zh": "王帅",
                    "intro_zh": "易商华东区总经理、中国区副总裁",
                    "info_zh": "王帅2009-2011年供职于普洛斯（中国）华东区，2011年初就任于工银安盛，同年底加入ESR易商集团，工作至今。王帅在易商曾担任中国区大客户部总监、中西区总经理，现任易商华东区总经理、中国区副总裁，目前主要负责ESR易商在华东大区的项目投资，开发建设，对外租赁和物业管理等全方面的运营管理工作。",
                    "name_en": "Simon Wang",
                    "intro_en": "General Manager of ESR East China and Vice President of ESR China",
                    "info_en": "Simon Wang worked for GLP (China) from 2009 to 2011, before joining ICBC AXA at the beginning of 2011. At the end of the same year, he became a member of ESR group and began his career with ESR. Wang served as the director of ESR China key account and the general manager of ESR Central and ESR Western China. He is currently the general manager of ESR East China and vice president of ESR China. He is mainly responsible for the operation and management of ESR’s project investment, development and construction, external leasing and property management in East China.",
                    "index": 31
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210922134141.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210922134150.png",
                    "name_zh": "陈学海",
                    "intro_zh": "世邦魏理仕中国区战略顾问部负责人",
                    "info_zh": "陈学海先生在战略咨询领域有超过十五年的从业及管理经历，在城市发展战略、片区开发、城市更新、TOD等领域具有丰富的实操经验，多年来作为各级政府、国有平台公司、大型开发商的咨询顾问，成功主导的项目遍布全国。陈学海先生主亦作为一位市场中的思想领导者，在公共及媒体领域发表深刻见解及观点；凭借在行业内积累的丰富经验和卓越声誉，他目前也担任上海市人民政府发展研究中心、全球城市研究院等政府智库机构之研究成员。",
                    "name_en": "Alex Chen",
                    "intro_en": "Head of Consulting, China, CBRE",
                    "info_en": "Alex Chen has more than 15 years’ experience in the fields of strat_enegic consulting and management, with extensive hands-on proficiency in urban development strategy, regional planning and development, urban renewal, TOD, etc. He has been a consultant to governments at all levels, state-owned platform companies and large developers for many years, and has successfully led projects nationwide. As an opinion leader across markets, he delivers profound insights and opinions in the press and public forums. With his rich experience and excellent reputation in the industry, Alex has now been appointed as one of the research members of the Shanghai Municipal People’s Government’s Development Research Center and the Shanghai Institute for Global City.",
                    "index": 32
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141905.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141915.png",
                    "name_zh": "陈宪 教授",
                    "intro_zh": "上海交通大学安泰经济与管理学院教授，上海全球城市研究院特聘研究员",
                    "info_zh": "陈宪为享受国务院政府特殊津贴专家，主要的教学与研究领域是宏观经济学，公共经济学，服务经济与贸易。主要社会兼职是中国市场学会副会长，上海社会科学院博士生导师，上海市经济学会副会长。近年来，关注创业创新和区域经济一体化。曾出席李克强总理主持的经济形势专家和企业家座谈会，做了题为“经济企稳、动能转换与‘双创’发力”的发言。",
                    "name_en": "Professor Chen Xian",
                    "intro_en": "Professor of Antai College of Economics and Management, Shanghai Jiaotong University; distinguished researcher at the Shanghai Institute for Globa_enl City.",
                    "info_en": "Chen Xian is an expert on special government allowance of the State Council. The main teaching and research fields are macroeconomics, public economics, service economy and trade. The main social part-time jobs are vice president of Chinese Association of Market Development, doctoral supervisor of Shanghai Academy of Social Sciences and vice president of Shanghai Economist Association. In recent years, he has focused on entrepreneurship, innovation and regional economic integration. He once attended the symposium of economic situation experts and entrepreneurs by Premier Li Keqiang and made a speech entitled \"Economic Stabilization, Kinetic Energy transformation and Mass Entrepreneurship and Innovation \".",
                    "index": 33
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141703.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141653.png",
                    "name_zh": "肖伟",
                    "intro_zh": "世邦魏理仕华南区战略顾问部负责人",
                    "info_zh": "肖伟先生在战略咨询领域有十五年的从业及管理经历，并长期担任政府及大型平台公司咨询顾问，在产业发展战略、新城及片区开发、TOD、城市更新及“一带一路”等领域有着丰富的实操经验。同时肖先生曾在英国和其他地区拥有工作经验，尤其专注于深耕粤港澳大湾区市场，与市场主流开发商、规划设计机构及产业运营资源保持紧密互动。长期担任深圳市多个区政府产业专家智库成员，为区域产业发展建言献策。他同时担任英国皇家测量师协会（RICS）中国区考官成员。",
                    "name_en": "Allen Xiao",
                    "intro_en": "Head of Consulting, Southern China, CBRE",
                    "info_en": "Allen is highly recognized in the commercial real estate market with his d_enistinguished management skills. For the past15 years, he has been an expert in industrial development strategy, new city development, TOD, urban renewal, and One Belt, One Road initiatives. He has been working with a variety of clients ranging from government to large platform companies. Allen has worked in the UK and many other locations around the world. He is especially well known for his profound understanding of the Greater Bay Area (GBA) markets and strong network in the industry. He is an advisor to Shenzhen government, providing insights for regional industrial development, and is also a member of the Chinese Examiner of RICS.",
                    "index": 34
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141943.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141928.png",
                    "name_zh": "徐磊青 教授",
                    "intro_zh": "同济大学建筑与城市规划学院教授，同济大学国家现代化研究院城市更新中心主任",
                    "info_zh": "徐磊青教授的研究领域包括城市更新，社区更新，城市设计，建筑设计，环境设计等。他的研究触及了人性化城市空间的重要主题，包括健康、安全、效率、舒适、美观、满意和多样性等等。他是城市空间环境与行为、心理、身体关系的重要学者和代表人物。同时也是当前中国城市和社区更新领域的知名思想家、研究者和设计师。",
                    "name_en": "Professor. Xu Lei Qing",
                    "intro_en": "Professor, College of Architecture and Urban Planning, Tongji University; Director, Urban Renewal Center, National Institute of Moder_ennization, Tongji University",
                    "info_en": "Professor Xu Leiqing’s research fields include urban renewal, community renewal, urban design, architectural design, environmental design, etc. His research touched on the important themes of humanized urban space, including health, safety, efficiency, comfort, beauty, satisfaction, and diversity. He is an important scholar and representative of the relationship between urban space environment and behavior, psychology and body. At the same time, he is also a well-known thinker, researcher and designer in the field of urban and community renewal in China. ",
                    "index": 35
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141721.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910141730.png",
                    "name_zh": "陆韬",
                    "intro_zh": "世邦魏理仕中国区战略顾问部副董事",
                    "info_zh": "陆韬拥有10年的城市战略咨询工作经验，多年来作为全国各地政府部门、国有平台公司、大型开发商的咨询顾问，在城市发展战略、政府决策咨询、区域综合开发、城市更新、企业战略等领域积累了丰富的实操经验，服务的项目和客户遍布全国。",
                    "name_en": "Tony Lu",
                    "intro_en": "Associate Director, Consulting, China, CBRE",
                    "info_en": "Tony Lu has 10 years of urban strategy consulting work experience. He has a_enccumulated rich practical experience in consulting, regional comprehensive development, urban renewal, corporate strategy and other fields. The projects and clients he served are all over China.",
                    "index": 36
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910141958.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910142007.png",
                    "name_zh": "沈从乐",
                    "intro_zh": "第一财经·新一线城市研究所主编、《第一财经YiMagazine》杂志编委",
                    "info_zh": "毕业于复旦大学新闻系，从2013年起参与《第一财经周刊》新一线城市栏目的内容策划和生产，深入成都、杭州、武汉、长沙等新一线城市进行长时间的商业报道和调研。2015年以项目负责人身份主导成立新一线城市研究所，主攻以大数据研究方式探索和分析中国城市的商业魅力及生活方式发展与变迁。该机构整合线上线下全面的城市商业大数据，用新鲜视角为城市管理者、城市开拓者和城市人提供丰富有趣、有价值的数据内容和数据服务。",
                    "name_en": "Jolly Shen",
                    "intro_en": "Project leader of The Rising Lab and Member of editorial board of YiMagazine",
                    "info_en": "Jolly built up The Rising Lab as a business-data-focused city program under Yicai Media Group in 2015 and defined the widely circulated concept about “Emerging New First-tier City” in China. Based on years of experience of business reporting, Shen leads The Rising Lab team analyzing current situation of Chinese cities, exploring the future of them, and providing quality data analysis and services for city dwellers, urban planners and investors.",
                    "index": 37
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144424.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144411.png",
                    "name_zh": "陈锦平",
                    "intro_zh": "世邦魏理仕香港研究部负责人",
                    "info_zh": "陈锦平于2014年加入世邦魏理仕，专责带领香港研究部团队，研究区内各范畴的房地产市场。他经常与中国内地、亚太区及全球研究团队紧密合作，积极推动世邦魏理仕的长远承诺—为客户提供专业的业内见解、研究报告和分析。陈先生是世邦魏理仕亚太区研究行政委员会的成员之一。作为皇家特许测量师学会的成员，陈先生拥有逾22年物业市场研究经验，其中20年任职于包括国际级测量师行，于香港及中国内地不同研究部团队担任领导角色。他早期受雇于一家香港地产发展商，从事项目投资前期分析和可行性研究工作，发掘本地及海外房地产市场的投资机会。陈先生对大中华地区的房地产市场有深入的见解，凭借其丰富的房地产市场研究背景，陈先生对租户行业动态和业主需求等了如指掌。陈先生亦活跃于本地和国际媒体，分享多元化的房地产评论。",
                    "name_en": "Marcos Chan",
                    "intro_en": "Head of Research, Hong Kong, CBRE",
                    "info_en": "Marcos Chan joined CBRE in 2014, he oversees a team of research profe_enssionals, covering key property sectors in Hong Kong. He also works closely with the Mainland China, Asia Pacific and Global Research teams to proactively drive CBRE’s ongoing objective to produce first-class industry insight, research and analysis for CBRE clients. Marcos is a member of CBRE’s Asia Pacific Research Executive Committee. A member of the Royal Institute of Chartered Surveyors, Marcos has 22 years of property market experience behind him, 20 of these with top global surveying firms where he held various leadership positions in the firms’ Research teams across Hong Kong and Mainland China. Marcos also has diverse experience in leading research consultancy projects for corporate occupiers and investors. In his earlier days, Marcos worked for a Hong Kong developer where he was engaged in pre-acquisition financial analyses and feasibility studies for investment opportunities in the local and overseas real estate markets. Marcos has a profound understanding of the real estate market in the Greater China region, and with his strong research background in real estate agency, he is well-versed with occupier trends and landlord requirements. Mr. Chan is also an active property market commentator in the media.",
                    "index": 38
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144525.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144543.png",
                    "name_zh": "冯慧诗",
                    "intro_zh": "世邦魏理仕香港顾问及交易服务|办公楼部负责人",
                    "info_zh": "冯慧诗是负责管理香港顾问及交易服务|办公室楼、交易管理和工作场所战略团队，共计100多名房地产专业人士。由她带领的团队擅长为租户或业主提供一系列有关商业办公楼的服务。她拥有20多年的房地产经验，曾在房地产行业内多个层级工作，并建立了强大的行业网络。目前，她专注于为企业缔造成绩，并积极为公司建立出色的团队。在此之前，冯小姐曾领导世邦魏理仕大中华区销售管理和议案管理团队，也是项目管理团队的业务发展总监。她也是一名注册城市规划师，曾在政府部门工作，亦曾于不同房地产公司从事销售和营销工作，以展开其职业生涯。",
                    "name_en": "Ada Fung",
                    "intro_en": "Head of Advisory & Transaction Services | Office, Hong Kong, CBRE",
                    "info_en": "Ada Fung oversees our Advisory & Transaction Services | Office as well as Transaction Management and Workplace Strategy team, with more than 100 real estate professionals altogether. Her teams provide a range of services to both tenants and landlords in the commercial office sector. With over 20 years of experience in the Real Estate industry, Ada has built a strong industry network and worked at every level in the business. Her focus is on creating results for the business and building successful teams. Prior to this role, Ada has lead our Greater China Sales Management and Pitch Management teams; she was also our Business Development Director for Project Management Team. Ada is a chartered Town Planner, she had worked in the Government and also in Sales and Marketing in real estate firm where she starts her career.",
                    "index": 39
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144512.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144502.png",
                    "name_zh": "黎尚文",
                    "intro_zh": "世邦魏理仕香港顾问及交易服务|工业及物流部负责人",
                    "info_zh": "黎尚文先生处理工业物业交易经验广泛，包括特大仓库租赁及项目营销，对行业的热诚及专业服务广为业界认同。通过与研究部团队紧密合作，黎先生多次参与制作行业白皮书和前瞻性主题文章，还经常与公众交流行业热门话题。他负责带领团队发展，并凭借其与香港顾问及交易服务|工业及物流部团队的热忱，该团队从2012至2015年连续四年获得RICS英国皇家测量师学会年度工业代理奖项。黎先生亦勇夺嘉民物流年度最佳成交奖2014/15。黎先生于香港房地产界拥有逾17年经验，其中16年专注于工业物业。他于2008年加入世邦魏理仕，并屡创佳绩，其中一个项目是嘉民的特大仓库项目 – 嘉民领达中心，他于2012年物业落成之前就预租99%空间予客户，获得空前成功。",
                    "name_en": "Samuel Lai",
                    "intro_en": "Head of Advisory & Transaction Services | Industrial & Logistics, Hong Kong, CBRE",
                    "info_en": "Samuel Lai is responsible for managing the Advisory & Transaction Services – Industrial & Logistics business in Hong Kong. Samuel has extensive experience in transacting industrial properties, mega-sized leasing and project marketing. He is extremely well regarded as a leader in the local market for his dedication and professionalism. Samuel works closely with research team in producing industry white papers and thought leadership pieces, and he also communicates frequently with the public on hot and pressing topics facing the sector. With dedicated focus to the sector, CBRE’s Industrial team has been awarded the RICS Hong Kong Award for Industrial Team of the Year from 2012-2015 and the Goodman Leasing Deal of the Year 2014/5. Samuel brings with him over 17 years of experience in various real estate sectors, 16 of which he has specialized in the industrial sector in Hong Kong. Samuel joined CBRE in 2008, his first major focus was Goodman’s mega warehouse project, Interlink, which was highly successful, with 99% pre-commitment upon the building’s completion in 2012. ",
                    "index": 40
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144635.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144558.png",
                    "name_zh": "温运强",
                    "intro_zh": "世邦魏理仕香港顾问及交易服务|商铺部负责人",
                    "info_zh": "温运强先生负责带领世邦魏理仕顾问及交易服务|商铺团队为客户带来卓越解决方案。温先生在零售租赁市场拥有逾15年的丰富经验。在加入世邦魏理仕之前，他于迪生创建（国际）有限公司担任集团物业及行政部经理，负责为奢侈品牌管理位于中国内地、香港及澳门约30万平方呎的房地产资产组合，品牌包括Harvey Nichols、Brooks Brothers、劳力士及Tommy Hilfiger。",
                    "name_en": "Lawrence Wan",
                    "intro_en": "Head of Advisory & Transaction Services | Retail, Hong Kong, CBRE",
                    "info_en": "Lawrence Wan is responsible for steering Hong Kong Advisory & Transaction Services | Retail team to deliver exception solutions for clients. Lawrence has more than 15 years of experience in retail leasing. His most recent role was with Dickson International Concepts Limited as Group Property Manager, where he oversaw a 300,000 square feet real estate portfolio of luxury brands such as Harvey Nichols, Brooks Brothers, Rolex and Tommy Hilfiger across Mainland China, Hong Kong and Macau. ",
                    "index": 41
                },
                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144438.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144452.png",
                    "name_zh": "甄浚岷",
                    "intro_zh": "世邦魏理仕香港资本市场部负责人",
                    "info_zh": "甄浚岷先生负责世邦魏理仕资本市场部在香港的业务发展，带领团队为客户提供专业的投资方案。甄先生在物业投资市场拥有超过15年的丰富经验。他以物业估价师身份开始职业生涯，其后转投了资本投资市场，凭借着他对客户的尽责及对专业的要求，现已成为行内知名的物业投资顾问，并拥有丰富的投资经验及可靠的成功交易案例。他为客户，如地产发展商、机构性投资基金、家族办公室、个人富豪投资者、中国内地及海外投资者等等，提供高质量的专业服务。凭借其对行业的卓越贡献，他于2013年勇夺RICS皇家特许测量师学会香港颁奖礼年度大奖“青年测量师大奖”。而于2019-2021年间， 他更为RICS皇家特许测量师学会香港的考评官。",
                    "name_en": "Reeves Yan",
                    "intro_en": "Head of Capital Markets Hong Kong, CBRE",
                    "info_en": "Reeves Yan is responsible for the Capital Markets business for CBRE in Ho_enng Kong and lead a team of consultants in delivering world class investment solutions to clients. Reeves has over 15 years real estate experience. Reeves started his career in real estate as a valuation surveyor, and progressively moved into property investment space, rising as one of the top deal makers in the industry. With his well proven track record and valuable experience, he delivers high standard of professional services to property developers, institutional fund managers, family offices, high-net-worth individuals, Mainland China and overseas investors, etc. Earlier in his career, he was name_end Young Surveyor of the Year (then Young Achiever of the Year) by RICS Hong _enKong Awards in 2013 in recognition of his contribution to the profession. During 2019-2021, Reeves is the Assessor for RICS _enHK.",
                    "index": 42
                },

                {
                    "cover": "https://img.mofyi.com/Ayto/image/20210910144336.jpg",
                    "photo": "https://img.mofyi.com/Ayto/image/20210910144324.png",
                    "name_zh": "蔡敏怡",
                    "intro_zh": "世邦魏理仕大中华区顾问及交易服务|办公场所策略部负责人",
                    "info_zh": "蔡敏怡拥有超过十年的房地产战略顾问经验，她专为企业客户提供量身定制的工作场所战略和变革管理服务，曾为中国内地和香港地区不同范畴的企业客户提供咨询服务，包括金融机构、科技公司、保险及其他专业服务、物流及食品及饮料公司，成功协助客户创造适合其业务、文化和员工的工作环境。 在加入世邦魏理仕之前，蔡小姐曾在香港一家知名的设计公司担任战略和变革顾问，并在2017年至 2019年期间担任一家独立工作场所顾问公司的核心成员。蔡小姐还拥有从项目启动到入驻的丰富实践经验，范围包括早期的战略阶段到测试拟合、设计概念、设计细节、现场施工到最终交付。",
                    "name_en": "Manyi Choi",
                    "intro_en": "Head of Advisory & Transaction Services | Occupier Consulting and Workplace Strategy, Greater China, CBRE",
                    "info_en": "Based in Hong Kong, Manyi’s expertise lies in Workplace Strategy and Change Management for corporate clients. She has over ten years’ experience and a wide exposure working for a diverse range of clientele, including financial institutions, technological firms, insurance and other professional services, logistics and food & beverages companies across Hong Kong and Mainland China. She has successfully facilitated her clients to create an effective workplace environment tailored to their business, culture and people. Prior to this position, Manyi has worked as a Strategy and Change consultant in one of the famous design firm in Hong Kong and have been a core member of an independent Workplace Consultant firm from 2017 – 2019. Manyi also have a hands-on experience in working on projects from beginning to the end, from early strategy stage to test-fits, design concept, design details, on-site construction and final delivery.",
                    "index": 43
                }
            ]
        }
    },
    methods: {
        ...mapMutations('main', ['setBgStyle']),


    },
    mounted() {
        this.setBgStyle('white')
    },
}
</script>
