<template>
    <div class="live-block-phone">
        <div class="live-video">
            <div id="dplayer"></div>
            <div v-if="!replayStart" class="video-end-cover"></div>
        </div>
        <!-- 关联直播间 -->
        <div class="lives-area" :class="associateData.length == 4 ? 'lives-area2' : ''">
            <div>
                <template v-for="(item, index) of associateData">
                    <template v-if="index + 1 == $route.params.id">
                        <div class="item active" :class="areaDisable ? 'disable' : ''" :key="item.title">
                            <div class="cover">
                                <div class="play-icon">
                                    <i class="iconfont icon-play"></i>
                                </div>
                                <p class="title">{{ item.title }}</p>
                            </div>
                            <div class="lines">
                                <p class="line" v-for="n of item.items" :key="n">{{ n }}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="areaDisable">
                            <div class="item disable" :key="item.title">
                                <div class="cover">
                                    <div class="play-icon">
                                        <i class="iconfont icon-play"></i>
                                    </div>
                                    <p class="title">{{ item.title }}</p>
                                </div>
                                <div class="lines">
                                    <p class="line" v-for="n of item.items" :key="n">{{ n }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <router-link class="item" :key="item.title" :to="'/live/' + (index + 1)">
                                <div class="cover">
                                    <div class="play-icon">
                                        <i class="iconfont icon-play"></i>
                                    </div>
                                    <p class="title">{{ item.title }}</p>
                                </div>
                                <div class="lines">
                                    <p class="line" v-for="n of item.items" :key="n">{{ n }}</p>
                                </div>
                            </router-link>
                        </template>
                    </template>

                </template>
            </div>
        </div>
        <div class="live-message">
            <global-tips class="video-mobile-tips" :tip-text="tipText" :is-live="true"></global-tips>
        </div>
        <div class="live-menu">
            <div :class="aeraShow == 1 ? 'active' : ''" @click="aeraShow = 1">PPT</div>
            <div :class="aeraShow == 0 ? 'active' : ''" @click="aeraShow = 0">CHAT</div>
        </div>

        <div class="live-nr">
            <div class="ppt-area" v-show="aeraShow == 1">
                <img :src="image" alt="">
            </div>
            <div class="interactive-aera" v-show="aeraShow == 0">
                <el-scrollbar ref="scrollbar">
                    <div class="comments">
                        <div v-for="(n, index) of comments" :key="index">
                            <span class="name" :class="n.name == $t('other.liveAssistant') ? 'host-name' : ''">{{ n.name }}</span>
                            <span class="text">{{ n.message }}</span>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="live-bottom">
            <textarea ref="textArea" placeholder="Type your message here..." v-model="message"></textarea>
            <!-- 发送按钮栏 -->
            <div class="operation-line">
                <div class="icon" @click="emojiShow = !emojiShow"><i class="iconfont icon-biaoqing"></i></div>
                <span @click="sendMessage">{{ $t('live.send') }}</span>
            </div>
            <!-- emoji 框 -->
            <div class="emoji-list" :class="emojiShow ? 'emoji-list-show' : ''">
                <ul>
                    <li v-for="(n, index) of emojis" @click="tapEmoji(n)" :key="index">{{ n }}</li>
                </ul>
            </div>
        </div>

        <div v-if="lotteryDisable" class="disable draw-btn"></div>
        <div v-else class="draw-btn" :class="'draw' + drawIndex" @click="drawTap"></div>
    </div>
</template>
<script>

import $ from 'jquery'
import Dplayer from 'dplayer'
import MiceChat from '@/assets/js/mice-chat'
import { mapState } from 'vuex'
import GlobalTips from '@/components/GlobalTips'
export default {
    props:{
        associateData: Array,
        replayStart: Boolean
    },
    computed:{
        ...mapState('main', ['liveData', 'userInfo', 'token'])
    },
    components: {
        GlobalTips
    },
    data(){
        return {
            btnLoading: false,
            aeraShow: 0,
            // 聊天内容
            message: '',
            // video和ppt的位置是否互换
            positionChange: false,
            dp: null,
            miceChat: null,
            emojiShow: false,
            emojis: [ '😀', '😃', '😄', '😁', '😆', '😉', '😊', '😇', '😍', '🤩', '😘', '😗', '😚', '😙', '😋', '😛', '😜', '😝', '🤑', '🤗', '🥳', '😎', '🤓', '🧐', '🤭', '👍','🚀️','❤️',],
            image: 'https://img.mofyi.com/Ayto/image/20210922150553.jpg',

            // 抽奖相关
            drawIndex: 0,
            timer: null,
            drawText: '',

            quIndex: 0,
            comments: [],

            // 控制分会场与抽奖是否可以点击
            areaDisable: false,
            lotteryDisable: true,

            tipText: '',
        }
    },
    methods: {

        getType(){
            let id = this.liveData[Number(this.$route.params.id) - 1].id
            this.$http.post(this.HOST + '/cbre2021/live/flow.state/Nkkz8i/' + id, {}).then(res => {
                console.log(res)
                const data = res.data
                if(data.code == 1){
                    this.dpInit(this.quIndex)
                }
            }).catch(res => {
                console.log(res)
            })
        },
        chatInit(){
            let ppt_id = this.liveData[Number(this.$route.params.id) - 1].id
            this.miceChat = new MiceChat(653, this.token ? this.userInfo.mice_user_id : 0, this.token ? this.userInfo.live_name : '');
            this.miceChat.on('connection', () => {
                // 获取历史消息
                this.miceChat.getHistoryMessage(1)
                if(ppt_id === 653){
                    this.miceChat.getImage();
                }
            }).on('message', (name, avatar, message) => {
                console.log('新聊天消息：' + message)
                // 聊天信息
                this.comments.push({
                    name: name,
                    message: message,
                    avatar: avatar
                })
                this.toTheBottom()
            }).on('liveStart', () => {
                // 开始直播了。
                console.log('开始直播了')
                setTimeout(() => {
                    this.dp.destroy()
                    $('#dplayer').html('')
                    $('#dplayer')[0].className = ''
                    this.dpInit(this.quIndex)
                }, 10000)

            }).on('hostMessage', (message) => {
                // 显示聊天
                this.toTheBottom()
                this.comments.push({
                    name: this.$t('other.liveAssistant'),
                    message: message,
                    avatar: ''
                })

            }).on('getHistoryMessage', (list) => {
                list.forEach(n => {
                    this.comments.push({
                        name: n.name === '主持人' ? this.$t('other.liveAssistant') : n.name,
                        message: n.message,
                        avatar: ''
                    })
                })
                this.toTheBottom(1)

            }).on('reopen', function () {
                console.log('已从其他窗口打开该直播间，当前页面已失效。')
                // TODO 暂停直播
            }).on('messageError', (message) => {
                this.$cbreAlert({
                    icon: 'warning',
                    message: message
                })
            }).on('other', (data) => {
                if(data.type == 50){
                    this.areaDisable = data.data.live == 1? false : true
                    this.lotteryDisable = data.data.lottery == 1? false : true
                    this.tipText = localStorage.getItem('locale') == 'en' ? data.data.notify_en : data.data.notify
                }
            }).on('imageChange', (url) => {
                if(ppt_id === 653){
                    if (url) {
                        var newImg = new Image()
                        newImg.src = url
                        newImg.onerror = () => { // 图片加载错误时的替换图片
                            newImg.src = 'https://img.mofyi.com/Ayto/image/20210922150553.jpg'
                        }
                        newImg.onload = () => { // 图片加载成功后把地址给原来的img
                            this.image = newImg.src
                        }
                    }
                }

            })

            this.miceChat.init();
        },
        getDisable(){
            this.$http.post(this.HOST + '/cbre2021/lottery.state', {}).then(res => {
                const data = res.data
                this.areaDisable = data.live_click == 1? false : true
                this.lotteryDisable = data.lottery == 1? false : true
            }).catch(res => {
                console.log(res)
            })
        },
        toTheBottom(_init){
            setTimeout(() => {
                let scroll = this.$refs.scrollbar.wrap
                let maxScroll = scroll.scrollHeight - scroll.offsetHeight
                if(maxScroll - scroll.scrollTop < 100 || _init){
                    scroll.scrollTop = maxScroll
                }
            }, 100)
        },
        pptChatInit(){
            let ppt_id = this.liveData[Number(this.$route.params.id) - 1].id
            if(ppt_id === 653){
                return
            }
            this.pptChat = new MiceChat(ppt_id, this.token ? this.userInfo.mice_user_id : 0, this.token ? this.userInfo.live_name : '');
            this.pptChat.on('connection', () => {
                // 获取图片
                this.pptChat.getImage();

            }).on('imageChange', (url) => {
                if (url) {
                    var newImg = new Image()
                    newImg.src = url
                    newImg.onerror = () => { // 图片加载错误时的替换图片
                        newImg.src = 'https://img.mofyi.com/Ayto/image/20210922150553.jpg'
                    }
                    newImg.onload = () => { // 图片加载成功后把地址给原来的img
                        this.image = newImg.src
                    }
                }
            })
            this.pptChat.init();
        },
        // 发送聊天记录
        sendMessage(){
            if(!this.token){
                this.$cbreAlert({
                    icon: 'warning',
                    message: this.$t('other.needLogin')
                })
                return false
            }
            if (this.message) {
                this.miceChat.sendMessage(this.message)
                this.message = ''
                // console.log('弹幕发送');
            }
        },
        // 插入emoji
        tapEmoji(_data){
            let index = this.getCaretPosition(this.$refs['textArea'])
            this.message = this.message.slice(0, index) + _data + this.message.slice(index)
            this.emojiShow = false
            this.$refs['textArea'].focus()

        },
        getCaretPosition(element) {
            var cursorPos = 0;
            if (document.selection) {//IE
                var selectRange = document.selection.createRange();
                selectRange.moveStart('character', -element.value.length);
                cursorPos = selectRange.text.length;
            } else if (element.selectionStart || element.selectionStart == '0') {
                cursorPos = element.selectionStart;
            }
            return cursorPos;
        },
        dpInit(_index){
            this.dp = new Dplayer({
                container: document.getElementById('dplayer'),
                autoplay: true, // 自动播放
                preload: 'auto', // 预加载
                lang: localStorage.getItem('locale') == 'en' ? 'en' : 'zh-cn',
                contextmenu: [],
                video: {
                     quality: [
                        {
                            name: '中',
                            url : this.liveData[Number(this.$route.params.id) - 1].hls_zh,
                            type: 'mp4',
                        },
                        {
                            name: 'EN',
                            url : this.liveData[Number(this.$route.params.id) - 1].hls_en,
                            type: 'mp4',
                        },
                    ],
                    defaultQuality: _index,
                    pic : "https://img.mofyi.com/Ayto/image/20210928092839.jpeg"
                },
                pluginOptions: {

                },
                theme: '#09a557',
                live: false,
                danmaku: true,
                apiBackend: {
                    read:  () => {
                        document.querySelector('.dplayer-danloading').style.display = 'none'
                        document.querySelector('.dplayer-comment').style.display = 'none'
                        document.querySelector('.dplayer-full-in-icon').style.display = 'none'
                        document.querySelector('.dplayer-setting').style.display = 'none'
                        document.querySelector('.dplayer-menu').remove()
                        document.querySelector('.dplayer-setting').remove()


                        var self = this
                        $('.dplayer-quality-item').click(function(){
                            var index = $(this).data('index')

                            self.dp.destroy()
                            $('#dplayer').html('')
                            $('#dplayer')[0].className = ''
                            self.quIndex = index
                            self.dpInit(self.quIndex)
                        })
                        // $('.dplayer-video').attr('playsinline', true)
                        // $('.dplayer-video').attr('webkit-playsinline', true)
                        // $('.dplayer-video').attr('x5-playsinline', true)
                        // $('.pc-video-block-vice .dplayer-controller').hide()
                    }
                },
                mutex: false
            })
            this.dp.on('quality_start', () => {

                this.dp.notice(this.$t('live.switching'))
            })
            this.dp.on('quality_end', () => {

                this.dp.notice(this.$t('live.switched'))
            })
            this.dp.play()
        },
        drawAniStart(_d){
            console.log(_d)
            if(this.timer){
                clearInterval(this.timer)
            }
            this.timer = setInterval(() => {
                if(_d == 1){
                    if(this.drawIndex >= 13){
                        clearInterval(this.timer)
                        setTimeout(() => {
                            this.drawAniStart(0)
                        }, 500)
                        return
                    }
                    this.drawIndex ++
                }else{
                    if(this.drawIndex <= 0){
                        clearInterval(this.timer)
                        return
                    }
                    this.drawIndex --
                }
            }, 30)
        },
        drawTap(){
            this.drawAniStart(1)

            if(this.btnLoading){
                return false
            }
            this.btnLoading = true
            this.$http.post(this.HOST + '/cbre2021/lottery', {
                token: localStorage.getItem('token')
            }).then(res => {
                const data = res.data
                this.btnLoading = false
                if(data.code === 401){
                    this.$cbreAlert({
                        message: this.$t('other.needLogin'),
                        icon: 'warning'
                    })
                }else if(data.code == 1){
                    localStorage.setItem('draw_text', this.$t('draw.item' + data.type))
                    this.$router.push('/write')
                }else if(data.code == 2){
                    this.$cbreAlert({
                        message: this.$t('draw.error'),
                        icon: 'warning'
                    })
                }else if(data.code == 3){
                    this.$cbreAlert({
                        message: this.$t('draw.error'),
                        icon: 'warning'
                    })
                }else{
                    this.$cbreAlert({
                        message: this.$t('draw.error'),
                        icon: 'cuoguodingdan'
                    })
                }
            }).catch(res => {
                this.btnLoading = false
                console.error(res)
            })
        }
    },
    mounted() {
        this.getDisable()
        // this.getType()
        if(this.replayStart){
            this.dpInit(this.quIndex)
        }
        this.chatInit()
        this.pptChatInit()
    },
    watch: {
        replayStart(n){
            if(n){
                this.dpInit(this.quIndex)
            }
        }
    },
    destroyed() {
        // console.log('离开直播')
         if(this.miceChat){
            this.miceChat.close()
        }
        if(this.pptChat){
            this.pptChat.close()
        }
        this.dp.destroy()
    },
}
</script>
