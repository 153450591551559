// axios
import axios from 'axios'
import router from './router'

const baseURL = ''
const headers = {'X-REQUESTED-WITH': 'xmlhttprequest'}
const instance = axios.create({
    baseURL,
    headers
})
instance.interceptors.response.use((response)=>{
    return response
}, (error)=>{
    if (error && error.response) {
        if(error.response.status === 401){
            localStorage.removeItem('token')
            localStorage.removeItem('user_info')
            router.push('/login')
        }
    }
    return Promise.reject(error)
});
export default instance
