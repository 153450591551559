<template>
    <div class="content">
        <div class="window">
            <div class="crumbs">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <i class="fa fa-caret-right"></i>
                <span>{{ $t('header.register') }}</span>
            </div>
            <div class="window-main">
                <div class="close-btn" @click="goBack"><i class="iconfont icon-close"></i></div>
                <div class="register-block">
                    <el-row :gutter="30">
                        <el-col :lg="12" :md="12" :sm="24">
                            <div class="title">
                                <h3>{{ $t('user.registerTitle') }}</h3>
                                <h4>{{ $t('user.title') }}</h4>
                            </div>
                        </el-col>
                        <el-col :lg="12" :md="12" :sm="24">
                            <cbre-input class="email-input" :title="$t('user.name')">
                                <input type="text" v-model="formData.name" autocomplete="off">
                            </cbre-input>
                            <cbre-input class="email-input" :title="$t('user.email')">
                                <input type="text" v-model="formData.email" autocomplete="off">
                            </cbre-input>
                            <p class="email-tips"><i class="iconfont icon-wara"></i> {{ $t('user.emailTips') }}</p>
                        </el-col>
                        <el-col :span="24">
                            <cbre-input class="email-input" :title="$t('user.company')">
                                <input type="text" v-model="formData.company" autocomplete="off">
                            </cbre-input>
                        </el-col>
                        <el-col :lg="12" :md="12" :sm="24">
                            <cbre-input class="email-input" :title="$t('user.jobTitle')">
                                <input type="text" v-model="formData.name697121627635257871" autocomplete="off">
                            </cbre-input>
                        </el-col>
                        <el-col :lg="12" :md="12" :sm="24">
                            <cbre-input class="email-input" :title="$t('user.city')">
                                <input type="text" v-model="formData.name929331627635271808" autocomplete="off">
                            </cbre-input>
                        </el-col>
                        <el-col :span="24">
                            <el-checkbox class="cbre-checkbox" true-label="是" false-label="" v-model="formData.checked">
                                <div class="text">
                                    <i18n path="user.firstCheck" tag="span" for="user.firstCheck0">
                                        <a target="_blank" href="https://www.cbre.com.cn/zh-cn/about/privacy-policy">{{ $t('user.firstCheck0') }}</a>
                                    </i18n>
                                </div>
                            </el-checkbox>
                        </el-col>
                        <el-col :span="24">
                            <el-checkbox class="cbre-checkbox" true-label="是" false-label="" v-model="formData.name584351627635315675">
                                <div class="text">{{ $t('user.secondCheck') }}</div>
                            </el-checkbox>
                        </el-col>
                        <el-col :span="24">
                            <div class="btn-line">
                                <button class="theme-btn light" :class="btnDisable ? 'disable' : ''" :disabled="btnDisable" @click="submit">{{ $t('user.registerSubmit') }}</button>
                                <i18n path="user.loginTips" tag="span" for="user.loginTips0">
                                    <router-link to="/login">{{ $t('user.loginTips0') }}</router-link>
                                </i18n>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="canvas-bg-login">
            <canvas-bg></canvas-bg>
        </div>
    </div>
</template>
<script>
    import CbreInput from '@/components/CbreInput'
    import CanvasBg from '@/components/CanvasBg'
import { mapState, mapMutations } from 'vuex'
    export default {
        components: {
            CbreInput,
            CanvasBg
        },
        data() {
            return {
                btnLoading: false,
                checked: 0,
                formData: {
                    name: '',
                    email: '',
                    company: '',
                    name697121627635257871: '', // 工作职位
                    name929331627635271808: '', // 城市
                    checked: '',
                    name584351627635315675: '', // 同意接收宣传材料
                }
            }
        },
        computed: {
            ...mapState('main', ['prevRouterName']),
            btnDisable(){
                let bol = false
                for(let i in this.formData){
                    if(i === 'name584351627635315675'){
                        continue
                    }
                    if(this.formData[i].trim() === ''){
                        bol = true
                    }
                    if(i === 'email'){
                        const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
                        if(!reg.test(this.formData[i])){
                            bol = true
                        }
                    }
                }
                return bol
            }
        },
        methods: {
            ...mapMutations('main', ['setToken', 'setUserInfo']),
            goBack() {
                this.prevRouterName ? this.$router.go(-1) : this.$router.push('/')
            },
            submit(){
                if(this.btnLoading){
                    return false
                }
                this.btnLoading = true
                this.$http.post(this.HOST + '/cbre2021/register', this.formData).then(res => {
                    const data = res.data
                    if(data.code == 1){
                        this.login(this.formData.email)
                    }else{
                          this.$cbreAlert({
                            message: this.$t('other.registerError'),
                            icon: 'warning'
                        })
                        this.btnLoading = false
                    }
                }).catch(() => {
                    this.$cbreAlert({
                        message: this.$t('other.reqError'),
                        icon: 'warning'
                    })
                    this.btnLoading = false
                })
            },
            login(_email){
                this.$http.post(this.HOST + '/cbre2021/login', {
                    email: _email
                }).then(res => {
                    const data = res.data
                    if(data.code == 1){
                        this.$cbreAlert({
                            message: this.$t('other.loginSuccess'),
                            icon: 'check'
                        })
                        localStorage.setItem('token', data.token)
                        this.setToken(data.token)
                        localStorage.setItem('user_info', JSON.stringify(data.data))
                        this.setUserInfo(data.data)
                        setTimeout(() => {
                            this.$router.push('/')
                        }, 1000)
                    }else{
                        this.btnLoading = false
                        this.$cbreAlert({
                            message: this.$t('other.loginError'),
                            icon: 'warning'
                        })
                    }
                }).catch(() => {
                    this.btnLoading = false
                    this.$cbreAlert({
                        message: this.$t('other.reqError'),
                        icon: 'warning'
                    })
                })
            }
        }
    }
</script>
