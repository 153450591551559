<template>
    <div class="content report-content">
        <div class="crumbs">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <i class="fa fa-caret-right"></i>
            <span>{{ $t('report.crumbs') }}</span>
             <i class="fa fa-caret-right"></i>
            <span>{{ $t('nav.report') }}</span>
        </div>
        <div class="report-block">
            <div v-if="isDone !== null">
                <template v-if="isDone === 0">
                    <div class="report-header">
                        <div class="left">
                            <h4>{{ $t('report.title') }}</h4>
                            <p>{{ $t('report.info') }}</p>
                        </div>
                        <div class="right">
                            <span>{{ $t('nav.report') }}</span>
                            <span>2021</span>
                        </div>
                    </div>
                    <ul class="report-form">
                        <template v-for="(item, index) of formContent">
                            <li :class="failNames.indexOf(forms[index]) > -1 ? 'fail-li ' + item.type + '-li' : item.type + '-li'" :key="index">
                                <template v-if="item.type === 'radio'">
                                    <div class="title">
                                        <p>{{ item.title }}</p>
                                        <span class="text">{{ item.text }}</span>
                                        <span class="num">{{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}</span>
                                    </div>
                                    <el-radio-group v-model="form[forms[index]]" @change="radioChange(forms[index], form[forms[index]])">
                                        <el-radio class="cbre-radio" v-for="option of radioOptions" :key="option" :label="option">{{ option }}</el-radio>
                                    </el-radio-group>
                                </template>
                                <template v-else>
                                    <textarea rows="5" v-model="form[forms[index]]" :placeholder="item.placeholder"></textarea>
                                </template>
                            </li>
                        </template>
                    </ul>
                    <div style="text-align: right;">
                        <button class="theme-btn" :class="btnDisable ? 'disable' : ''" @click="submit">{{ $t('report.buttonText') }}</button>
                    </div>
                </template>
                <template v-else-if="isDone === 1">
                    <div class="done-style">
                        <i class="iconfont icon-check"></i>
                        <p class="text">{{ $t('report.submitSuccess') }}</p>
                        <a target="_blank" href="https://img.mofyi.com/cbre/CBRE%20Forum%202021_Change_Chinese%20Version_Final.pdf" download="报告">
                             <button class="theme-btn"><i class="iconfont icon-danganziliao-mobanxiazai"></i> {{ $t('report.download') }}</button>
                        </a>

                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
const forms = [
            'name485041627886203410',
            'name997781627886218805',
            'name136851627886233251',
            'name31701627886279794',
            'name411191627886292808',
            'name725991631523432761',
            'name766021631523457778',
            'name659721631523475451',
            'name556211631523486171',
            'name548521627886308309',
            'name368601627886321358',
            'name156651627886346647',
            'name438401627886429100'
        ]

const form = {}
forms.forEach(n => {
    form[n] = ''
})
export default {
    computed: {
        btnDisable(){
            let bol = false
            for(let i in this.form){
                if(i === 'name156651627886346647' || i === 'name438401627886429100'){
                    continue
                }
                if(this.form[i].trim() === ''){
                    bol = true
                }
            }
            return bol
        }
    },
    data(){
        return {
            btnLoading: false,
            isDone: null,
            form: form,
            forms: forms,
            formContent: [
                {
                    type: 'radio',
                    title: this.$t('report.formTitle1'),
                    text: this.$t('report.formText1'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle2'),
                    text: this.$t('report.formText2'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle3'),
                    text: this.$t('report.formText3'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle4'),
                    text: this.$t('report.formText4'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle5'),
                    text: this.$t('report.formText5'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle6'),
                    text: this.$t('report.formText6'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle7'),
                    text: this.$t('report.formText7'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle8'),
                    text: this.$t('report.formText8'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle9'),
                    text: this.$t('report.formText9'),
                },
                 {
                    type: 'radio',
                    title: this.$t('report.formTitle10'),
                    text: this.$t('report.formText10'),
                },
                {
                    type: 'radio',
                    title: this.$t('report.formTitle11'),
                    text: this.$t('report.formText11'),
                },
                {
                    type: 'textarea',
                    placeholder: this.$t('report.formText12')
                },
                {
                    type: 'textarea',
                    placeholder: this.$t('report.formText13')
                },
            ],
            radioOptions: [this.$t('report.radioOptions1'), this.$t('report.radioOptions2'), this.$t('report.radioOptions3'), this.$t('report.radioOptions4'), this.$t('report.radioOptions5')],
            failNames: [],


        }
    },
    methods: {
        ...mapMutations('main', ['setBgStyle']),
        getIsDone(){
            this.$http.post(this.HOST + '/cbre2021/check.questionnaire', {
                token: localStorage.getItem('token')
            }).then(res => {
                const data = res.data
                this.isDone = data.code
            }).catch((res) => {
                if(res.response.status === 401){
                    this.$cbreAlert({
                        message: this.$t('other.needLogin'),
                        icon: 'warning'
                    })
                }

            })
        },
        radioChange(_name, _val){
            console.log(_name, _val)
            let index = this.failNames.indexOf(_name)
            if(index > -1 && _val){
                this.failNames.splice(index, 1)
            }
        },
        formCheckFail(){
            this.failNames = []
            let bol = false
            for(let i in this.form){
                if(i === 'name156651627886346647' || i === 'name172471627886404365' || i === 'name438401627886429100'){
                    continue
                }
                if(this.form[i].trim() === ''){
                    this.failNames.push(i)
                    bol = true
                }
            }
            return bol
        },
        submit(){

            if(this.formCheckFail()){
                return false
            }
            this.form.token = localStorage.getItem('token')

            if(this.btnLoading){
                return false
            }
            this.btnLoading = true
            this.$http.post(this.HOST + '/cbre2021/questionnaire', this.form).then(res => {
                const data = res.data
                this.btnLoading = false
                if(data.code == 1){
                    this.$cbreAlert({
                        message: this.$t('other.success'),
                        icon: 'check'
                    })
                    this.isDone = 1
                }else{
                    this.$cbreAlert({
                        message: this.$t('other.reqError'),
                        icon: 'warning'
                    })
                }
            }).catch(() => {
                this.btnLoading = false
                this.$cbreAlert({
                    message: this.$t('other.reqError'),
                    icon: 'warning'
                })
            })
        }
    },
    mounted() {
        this.setBgStyle('white')
        this.getIsDone()
    },
}
</script>
