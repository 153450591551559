import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import store from '../store/index'
import CbreAlert from '@/assets/js/cbreAlert.js'
import i18n from '@/i18n/i18n'


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Main',
        component: Main,
        children: [
            // 页面
            {
                path: '',
                name: 'Home',
                component: () => import('../views/pages/Home.vue')
            },
            {
                path: '/home',
                name: 'HomeDemo',
                component: () => import('../views/pages/Home.vue')
            },
            {
                path: '/intro',
                name: 'Intro',
                component: () => import('../views/pages/Intro.vue')
            },
            {
                path: '/agenda',
                name: 'Agenda',
                component: () => import('../views/pages/Agenda.vue')
            },
            {
                path: '/speakers',
                name: 'Speakers',
                component: () => import('../views/pages/Speakers.vue')
            },
            {
                path: '/report',
                name: 'Report',
                component: () => import('../views/pages/Report.vue')
            },
            {
                path: '/live/:id',
                name: 'Live',
                component: () => import('../views/pages/Live.vue')
            },
            {
                path: '/local/:id',
                name: 'Local',
                component: () => import('../views/pages/Local.vue')
            },

            // 弹窗
            {
                path: '/login',
                name: 'Login',
                component: () => import('../views/window/Login.vue')
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('../views/window/Register.vue')
            },
            {
                path: '/write',
                name: 'Write',
                component: () => import('../views/window/Write.vue')
            },
            {
                path: '/disclaimer',
                name: 'Disclaimer',
                component: () => import('../views/window/Disclaimer.vue')
            },
            {
                path: '/faq',
                name: 'Faq',
                component: () => import('../views/window/Faq.vue')
            },

            // 测试页面
            {
                path: '/agenda_test',
                name: 'AgendaTest',
                component: () => import('../views/pages/Agenda.vue')
            },
            {
                path: '/speakers_test',
                name: 'SpeakersTest',
                component: () => import('../views/pages/Speakers.vue')
            },
            {
                path: '/report_test',
                name: 'ReportTest',
                component: () => import('../views/pages/Report.vue')
            },
            {
                path: '/live_test/:id',
                name: 'LiveTest',
                component: () => import('../views/pages/Live.vue')
            },
            {
                path: '/local_test/:id',
                name: 'LocalTest',
                component: () => import('../views/pages/Local.vue')
            },

            // 弹窗
            {
                path: '/disclaimer_test',
                name: 'DisclaimerTest',
                component: () => import('../views/window/Disclaimer.vue')
            },
            {
                path: '/faq_test',
                name: 'FaqTest',
                component: () => import('../views/window/Faq.vue')
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

// 敬请期待页面
const comingSoonPage = process.env.NODE_ENV === 'development' ? [] : []
// ['Agenda', 'Speakers', 'Report', 'Live', 'Disclaimer', 'Faq']

router.beforeEach((to, from, next) => {
    store.commit('main/setIsLive', to.name === 'Live')
    store.commit('main/setNavHidden', false)
    store.commit('main/setNavPhoneShow', false)
    store.commit('main/setPrevRouterName', from.name)
    if(to.query.lang == 'en'){
        localStorage.setItem('locale', 'en')
        window.location.href = to.fullPath.replace('?lang=en', '')
    }else if(to.query.lang == 'zh'){
        localStorage.setItem('locale', 'zh')
        window.location.href = to.fullPath.replace('?lang=zh', '')
    }

    if(to.query.area == 'hk'){
        localStorage.setItem('area', 'hk')
    }else if(to.query.area == 'default'){
        localStorage.setItem('area', 'default')
    }

    if(comingSoonPage.indexOf(to.name) > -1){
        CbreAlert({
            message: i18n.t('other.comingSoon'),
            icon: 'wara'
        })
        return;
    }
    if(to.name === 'Home'){
        let stamp = new Date().getTime()
        if(stamp >= 1632790800000){
            if(localStorage.getItem('area') == 'hk'){
                router.push('/live/5')
            }else{
                router.push('/live/1')
            }
            return;
        }
    }else if(to.name === 'Live' && to.params.id == 5){
        let stamp = new Date().getTime()
        if(stamp <= store.state.main.activityStartTime){
            router.push('/')
            return;
        }
    }else if(to.name === 'Write'){
        if(!localStorage.getItem('draw_text')){
            router.push('/')
            return;
        }
    }else if(to.name === 'Report'){
        if(!localStorage.getItem('token')){
            CbreAlert({
                message: i18n.t('other.needLogin'),
                icon: 'warning'
            })
            return;
        }
    }

    next()
})



export default router
