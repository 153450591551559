<template>
    <div class="nav" :class="navHidden ? 'nav-hidden' : ''">

        <p class="number"><span></span> {{ homeIndex - 1 }}</p>

        <div class="live-btns">

            <div class="live-btn">
                <router-link class="live-link" :to="'/live/' + (area == 'hk' ? 5 : 1)"><span>{{ $t('nav.live') }}</span><span class="dian"></span></router-link>
            </div>
            <!-- <div class="live-btn local-btn" style="margin-top: 0">
                <span class="live-link"><span>{{ $t('nav.live') }}</span></span>
            </div> -->

            <div class="live-btn local-btn">
                <router-link class="live-link" :to="'/local/' + 1"><span>{{ $t('nav.local') }}</span></router-link>
            </div>
             <!-- <div class="live-btn local-btn">
                <span class="live-link"><span>{{ $t('nav.local') }}</span></span>
            </div> -->


        </div>
        <div class="nav-main">
            <ul>
                <li v-for="(item, index) of navs" :key="item.title">
                    <router-link @click.native="homeRefresh(item.id)" class="link" :class="$route.path === item.url ? 'active' : ''" :to="item.url">
                        {{ item.title }}
                        <span class="num">0{{ index + 1}}</span>
                    </router-link>


                </li>
            </ul>
        </div>


    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
    computed: {
        ...mapState('main', ['activityStartTime', 'liveEntranceShow', 'navHidden', 'homeIndex', 'liveData']),
    },
    data(){
        return {
            area: localStorage.getItem('area'),
            liveOpen: false,
            navs: [
                {
                    id: 1,
                    title: this.$t('nav.home'),
                    url: '/'
                },
                {
                    id: 2,
                    title: this.$t('nav.intro'),
                    url: '/intro'
                },
                {
                    id: 3,
                    title: this.$t('nav.agenda'),
                    url: '/agenda'
                },
                {
                    id: 4,
                    title: this.$t('nav.speakers'),
                    url: '/speakers'
                },
                {
                    id: 5,
                    title: this.$t('nav.report'),
                    url: '/report'
                }
            ]
        }
    },
    setLiveOpen(){
        let stamp = new Date().getTime()
        if(stamp >= this.activityStartTime){
            this.liveOpen = true
        }
    },
    methods: {
        ...mapMutations('main', ['setHomeRefreshNumber']),
        homeRefresh(_id){
            if(_id === 1 && this.$route.name === 'Home'){
                // 此时首页swiper跳转至kv页
                this.setHomeRefreshNumber()
            }
        }
    },
}
</script>
