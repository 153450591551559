var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?1c3560cb0aa9e5f94d6e490385ff87a8";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


(function (doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize' ,
        recalc = function () {
            window.clientWidth = docEl.clientWidth;
            if (! window.clientWidth) return;
            if(window.clientWidth > 1920){
                window.clientWidth = 1920
            }
            docEl. style.fontSize = 20 * (window.clientWidth / 320) + 'px';
            window.base = 20 * ( window.clientWidth / 320);
        };
    // Abort if browser does not support addEventListener
    if (! doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener( 'DOMContentLoaded', recalc, false);
})(document, window);

export default {
    rem: 20 * ( document.documentElement.clientWidth / 320)
}
