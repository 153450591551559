export default {

    // 活动开始时间
    activityStartTime: 1632326400000,
    // 倒计时时间
    countdownTime: 1632362400000,
    prevRouterName: null,
    bgStyle: 'dark',
    isLive: false,
    navHidden: false,
    homeIndex: 0,
    // 控制直播间按钮是否显示
    liveEntranceShow: false,

    userInfo: JSON.parse(localStorage.getItem('user_info')) || {},
    token: localStorage.getItem('token') || null,

    navPhoneShow: false,

    homeRefreshNumber: 0,

    liveData: [
        {
          "name": "investment",
          "hls_zh": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/da9064c43701925925157661703/v.f100040.mp4",
          "hls_en": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/e6c4c17d3701925925106236380/v.f100040.mp4",
          "id": 653
        },
        {
          "name": "office building",
          "hls_zh": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/ada889523701925925153964589/v.f100040.mp4",
          "hls_en": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/f87383f63701925925154887939/v.f100040.mp4",
          "id": 655
        },
        {
          "name": "industrial real estate",
          "hls_zh": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/f70523c23701925925106923427/v.f100040.mp4",
          "hls_en": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/98b9f59b3701925925153070263/v.f100040.mp4",
          "id": 657
        },
        {
          "name": "strategic adviser",
          "hls_zh": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/00b25eee3701925925110061203/v.f100040.mp4",
          "hls_en": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/92d9dfcb3701925925163656448/v.f100040.mp4",
          "id": 659
        },
        {
          "name": "Hong Kong",
          "hls_zh": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/1dc9529a3701925925158201117/v.f100040.mp4",
          "hls_en": "https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/ec81ca273701925925113291859/v.f100040.mp4",
          "id": 661
        }
    ]
};
