<template>
    <div class="main">
        <div class="ie-tips" v-if="ie">
            <i18n path="other.ieTips" tag="p">
                <a place="0" href="https://www.google.cn/chrome/">{{ $t('other.ieTips0') }}</a>
                <a place="1" href="http://www.firefox.com.cn/">{{ $t('other.ieTips1') }}</a>
                <span place="2" @click="ie = false">{{ $t('other.ieTips2') }}</span>
            </i18n>
        </div>
        <header-block></header-block>
        <nav-block></nav-block>
        <nav-phone-block></nav-phone-block>
        <transition :name="transitionName" mode="out-in">
            <router-view :key="randomId" />
        </transition>
        <footer-block></footer-block>
    </div>
</template>
<script>
import HeaderBlock from "@/views/layouts/Header";
import NavBlock from "@/views/layouts/Nav";
import NavPhoneBlock from "@/views/layouts/NavPhone";
import FooterBlock from "@/views/layouts/Footer";
import { mapMutations } from 'vuex'
export default {

    components: {
        HeaderBlock,
        NavBlock,
        NavPhoneBlock,
        FooterBlock
    },
    data() {
        return {
            transitionName: 'slide-right',
            // 标记为窗口的页面 name
            widnowNames: ['Login', 'Register', 'Disclaimer'],
            // 需要把背景改成白色的页面 name
            whitePageNames: ['Report', 'Speakers'],
            ie: false,
        }
    },
    computed:{
        randomId(){
            return this.$route.fullPath + new Date()
        }

    },
    methods: {
        ...mapMutations('main', ['setBgStyle']),
        isIe() {
            var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
            var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
            var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
            var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
            if(isIE || isEdge || isIE11) {
                this.ie = true
            }
        }
    },
    mounted() {
        this.isIe()
    },
    beforeRouteUpdate(to, from, next) {
        this.transitionName = this.widnowNames.indexOf(to.name) > -1 ? 'slide-right' : 'slide-left';
        if(to.name === from.name){
            this.transitionName = ''
        }
        const bgStyle = this.whitePageNames.indexOf(to.name) > -1 ? 'white' : 'dark'
        this.setBgStyle(bgStyle)
        next();
    }
};
</script>
<style lang="scss">
    .ie-tips{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        z-index: 99999;
        background: #fff;
        color: #000;
        font-size: 16px;
        text-align: center;

        a, span{
            color: #02904e;
            font-weight: bold;
            line-height: 100px;
        }
        span{
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>
