<template>
    <div class="content live-content">
        <div class="crumbs">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <i class="fa fa-caret-right"></i>
            <span>{{ $t('nav.live') }}</span>
        </div>
        <live-pc v-if="isPc" :associate-data="associateData" :replay-start="replayStart"></live-pc>
        <live-phone v-else :associate-data="associateData" :replay-start="replayStart"></live-phone>
        <div class="canvas-bg-live">
            <canvas-bg></canvas-bg>
        </div>
    </div>
</template>
<script>
window.Hls = require('hls.js');
import CanvasBg from '@/components/CanvasBg'
import LivePc from './live/LivePc'
import livePhone from './live/LivePhone'
export default {
    computed:{

    },
    components: {
        CanvasBg,
        LivePc,
        livePhone
    },
    data(){
        return {
            replayStart: false,
            timer: null,
            isPc: document.documentElement.clientWidth > 960,
            associateData: [
                {
                    icon: 'https://img.mofyi.com/Ayto/image/20210811095844.png',
                    title: this.$t('live.items.item1'),
                    link: '#',
                    items: [this.$t('live.items.val1')]
                },
                {
                    icon: 'https://img.mofyi.com/Ayto/image/20210811095856.png',
                    title: this.$t('live.items.item2'),
                    link: '#',
                    items: [this.$t('live.items.val2')]
                },
                {
                    icon: 'https://img.mofyi.com/Ayto/image/20210811095910.png',
                    title: this.$t('live.items.item3'),
                    link: '#',
                    items: [this.$t('live.items.val3')]
                },
                {
                    icon: 'https://img.mofyi.com/Ayto/image/20210811095918.png',
                    title: this.$t('live.items.item4'),
                    link: '#',
                    items: [this.$t('live.items.val4')]
                }

            ],
        }
    },
    methods: {
        setReplayStart(){
            const startTime = 1632790800000
            const stamp = new Date().getTime()
            if(stamp >= startTime){
                console.log('回放已开始')
                this.replayStart = true
                return
            }
            console.log('回放未开始')
            this.timer = setInterval(() => {
                const current = new Date().getTime()
                // console.log('距离回放开始还有：' + parseInt((startTime - current) / 1000 / 60 / 60) + '小时')
                if(current >= startTime){
                    console.log('回放现在开始了')
                    this.replayStart = true
                    clearInterval(this.timer)
                }
            }, 1000)
        }
    },
    mounted() {
        this.setReplayStart()
        if(localStorage.getItem('area') === 'hk' || this.$route.query.area === 'hk'){
            this.associateData.push({
                icon: 'https://img.mofyi.com/Ayto/image/20210811095925.png',
                title: this.$t('live.items.item5'),
                link: '#',
                items: [this.$t('live.items.val5')]
            })
        }
    },
    destroyed() {
        // console.log('离开直播')
        if(this.timer != null){
            clearInterval(this.timer)
        }
    },
}
</script>
