<template>
    <div class="footer">
        <ul>
            <li v-for="item of links" :key="item.title">
                <router-link v-if="item.internal == 1 && item.target === '_self'" :to="item.url">{{ item.title }}</router-link>
                <a v-else :target="item.target" :href="item.url">{{ item.title }}</a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return {
            links: [
                {
                    title: this.$t('footer.about'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com.cn/zh-cn/about'
                },
                {
                    title: this.$t('footer.contact'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com.cn/zh-cn/about/contact-us'
                },
                {
                    title: this.$t('footer.faq'),
                    internal: 1, // 是否为内部链接
                    target: '_self', // 是否打开新链接
                    url: '/faq'
                },
                {
                    title: this.$t('footer.disclaimer'),
                    internal: 1, // 是否为内部链接
                    target: '_self', // 是否打开新链接
                    url: '/disclaimer'
                },
                {
                    title: this.$t('footer.terms'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com/about/disclaimer-terms-of-use'
                }
            ]
        }
    }
}
</script>
