<template>
    <div class="canvas-bgs">
        <canvas id="canvas-bg"></canvas>
        <canvas id="canvas-bg2"></canvas>
    </div>
</template>
<script>
export default {
    name: "CanvasBg",
    props: {
        title: String
    },
    computed: {},
    data() {
        return {};
    },
    methods: {

        init() {
            var cw = 1000;
            var ch = 666.6666;

            var canvas = document.getElementById("canvas-bg");
            var ctx = canvas.getContext("2d");

            var ratio = getPixelRatio(ctx)
            canvas.width = cw * ratio;
            canvas.height = ch * ratio;
            ctx.scale(ratio, ratio);

            var points = [];
            function Point() {
                this.dead = false;
                this.size = randomFn(3, 7);
                var x = randomFn(150, cw - this.size - 150);
                this.startX = x;
                this.x = x;
                this.y = ch;
                this.opacity = randomFn(5, 10) / 10;
                this.speed = randomFn(10, 25);
                this.speedX = randomFn(5, 15);
                this.color = randomFn(0, 10) < 7;
                this.xMin = randomFn(50, 150) * -1;
                this.xMax = randomFn(50, 150);
                this.direction = randomFn(0, 10) < 5 ? "right" : "left";
            }
            Point.prototype.draw = function() {
                this.y -= this.speed / 10;

                var cha = this.x - this.startX;
                if (this.direction === "right") {
                    this.x += this.speedX / 10;
                    if (cha > this.xMax) {
                        this.direction = "left";
                    }
                } else if (this.direction === "left") {
                    this.x -= this.speedX / 10;
                    if (cha < this.xMin) {
                        this.direction = "right";
                    }
                }
                if (this.y < 30) {
                    if (this.opacity > 0) {
                        this.opacity -= 0.1;
                    } else {
                        this.opacity = 0;
                        this.dead = true
                    }
                }

                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, 2 * Math.PI);
                ctx.fillStyle = this.color
                    ? "rgba(67, 135 , 109, " + this.opacity + ")"
                    : "rgba(255, 231, 173, " + this.opacity + ")";
                ctx.fill();
                ctx.closePath();
            };

            for (var i = 0; i < 5; i++) {
                points.push(new Point());
            }

            var num = 0;
            function main() {
                num++;

                if (num % 2 == 0) {
                    ctx.clearRect(0, 0, cw, ch);
                    points.forEach((n, index) => {
                        if(!n.dead){
                            n.draw(index);
                        }
                    });

                }
                if (num % 60 == 0) {
                    points.push(new Point());
                }
                if (num > 1000) {
                    points.forEach((n, index) => {
                        if(n.dead){
                            points.splice(index, 1);
                        }
                    });
                    num = 0;
                }
                requestAnimationFrame(main);
            }
            main();

            function randomFn(a, b) {
                return parseInt(Math.random() * (b - a + 1) + a);
            }

            function getPixelRatio(context) {
                var backingStore = context.backingStorePixelRatio ||
                    context.webkitBackingStorePixelRatio ||
                    context.mozBackingStorePixelRatio ||
                    context.msBackingStorePixelRatio ||
                    context.oBackingStorePixelRatio ||
                    context.backingStorePixelRatio || 1;
                return (window.devicePixelRatio || 1) / backingStore;
            }
        }
    },
    mounted() {
        this.init()
    },
};
</script>
<style lang="scss" scoped>
.canvas-bgs{
    position: relative;
    width: 100%;
    height: 100%;

    #canvas-bg, #canvas-bg2 {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    #canvas-bg{
        z-index: 2;
    }
}

</style>
