<template>
    <div class="content">
        <div class="window">
            <div class="window-main">
                <div class="close-btn" @click="goBack"><i class="iconfont icon-close"></i></div>
                <div class="write-block">
                    <div class="title">
                        <h3><i class="iconfont icon-wara"></i> {{$t('write.title1')}} </h3>
                        <h4>{{$t('write.title2')}}</h4>
                    </div>
                    <div class="line1">
                        <p>{{ text }}</p>
                        <img src="https://img.mofyi.com/Ayto/image/20210907105150.png" alt="">
                    </div>
                    <div class="login-form">
                        <el-row :gutter="30">
                            <el-col :lg="12" :md="12" :sm="24">
                                <cbre-input class="email-input" :title="$t('user.name')">
                                    <input type="text" v-model="formData.name" autocomplete="off">
                                </cbre-input>
                            </el-col>
                            <el-col :lg="12" :md="12" :sm="24">
                                <cbre-input class="email-input" :title="$t('write.mobile')">
                                    <input type="text" v-model="formData.mobile" autocomplete="off">
                                </cbre-input>
                            </el-col>
                            <el-col :span="24">
                                <cbre-input class="email-input" :title="$t('write.address')">
                                    <input type="text" v-model="formData.address" autocomplete="off">
                                </cbre-input>
                            </el-col>
                             <el-col :span="24">
                                <el-checkbox class="cbre-checkbox" true-label="是" false-label="" v-model="formData.checked">
                                    <div class="text">
                                        <i18n path="write.firstCheck" tag="span" for="write.firstCheck0">
                                            <a target="_blank" href="https://www.cbre.com.cn/zh-cn/about/privacy-policy">{{ $t('write.firstCheck0') }}</a>
                                        </i18n>
                                    </div>
                                </el-checkbox>
                            </el-col>
                             <el-col :span="24">
                                <div class="btn-line">
                                    <button class="theme-btn light" :class="btnDisable ? 'disable' : ''" :disabled="btnDisable" @click="submit">{{ $t('write.submit') }}</button>
                                </div>
                            </el-col>
                        </el-row>

                    </div>
                </div>
            </div>
        </div>
        <div class="canvas-bg-login">
            <canvas-bg></canvas-bg>
        </div>
    </div>
</template>
<script>
    import CbreInput from '@/components/CbreInput'
    import { mapState, mapMutations } from 'vuex'
    import CanvasBg from '@/components/CanvasBg'
    export default {
        data() {
            return {
                text: localStorage.getItem('draw_text'),
                formData: {
                    token: localStorage.getItem('token'),
                    name: '',
                    mobile: '',
                    address: '',
                    checked: ''
                }
            }
        },
        computed: {
            ...mapState('main', ['prevRouterName']),
            btnDisable(){
                let bol = false
                if(this.formData.mobile === '' || this.formData.name === '' || this.formData.address === '' || this.formData.checked === ''){
                    bol = true
                }
                return bol
            }
        },
        components: {
            CbreInput,
            CanvasBg
        },
        methods: {
            ...mapMutations('main', ['setToken', 'setUserInfo']),
            goBack() {
                this.prevRouterName ? this.$router.go(-1) : this.$router.push('/')
            },
            submit(){
                this.$http.post(this.HOST + 'cbre2021/save.lottery', this.formData).then(res => {
                    const data = res.data
                    if(data.code == 1){
                        this.$cbreAlert({
                            message: this.$t('write.success'),
                            icon: 'check'
                        })
                        localStorage.removeItem('draw_text')
                        setTimeout(() => {
                            this.goBack()
                        }, 2000)

                    }else{
                        this.$cbreAlert({
                            message: this.$t('write.error'),
                            icon: 'warning'
                        })
                    }
                }).catch(() => {
                    this.$cbreAlert({
                        message: this.$t('other.reqError'),
                        icon: 'warning'
                    })
                })
            }
        },
    }
</script>
