<template>
    <div class="content home-content">
        <swiper class="home-swiper" ref="homeSwiper" :options="swiperOptions">
            <swiper-slide class="page0" key="page0"></swiper-slide>
            <swiper-slide class="page1" key="page1">
                <div id="svg-bg1" class="svg-bg"></div>
                <div class="anima-bg2 anima-bg">
                    <div class="kv-ani" :class="{'kv-ani-show': kvAniShow, 'kv-ani-keep': kvAniKeep}">
                        <div class="debris" v-for="n of 23" :key="n" :style="'background-image: url(https://img.mofyi.com/cbre/kv'+ (23 - n + 1) +'.png)'" @transitionend="kvTsnEnd(n)"></div>
                    </div>
                </div>
                <transition name="slide-left">
                    <div class="kv" v-show="nrShow[0]">
                        <img class="kv-img" :src="isPc ? 'https://img.mofyi.com/Ayto/image/20210806153324.png' : 'https://img.mofyi.com/202108/2314545989491.png'" alt="">

                        <button class="play-btn" @click="videoMaskShow">
                            <img src="https://img.mofyi.com/202108/2315013557508.png" alt="">
                            <span>Play video</span>
                        </button>

                        <div class="btns">
                            <div class="live-btn local-btn" style="margin-top: 0">
                                <router-link :to="'/live/' + (area == 'hk' ? 5 : 1)" class="live-link"><span>{{ $t('nav.live') }}</span></router-link>
                                <!-- <span class="live-link"><span>{{ $t('nav.live') }}</span></span> -->
                            </div>
                            <div class="live-btn local-btn">
                                <router-link to="/local/1" class="live-link"><span>{{ $t('nav.local') }}</span></router-link>
                                <!-- <span class="live-link"><span>{{ $t('nav.local') }}</span></span> -->
                            </div>
                        </div>
                    </div>

                </transition>
            </swiper-slide>
            <swiper-slide class="page2" key="page2">
                <div>
                    <div id="svg-bg2" class="svg-bg"></div>
                    <transition name="slide-left">
                        <div class="video-block" v-show="nrShow[1]">
                            <div v-if="isPc" class="video-parent">
                                <!-- <video src="https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/32b8faac3701925923661477960/v.f100040.mp4" poster="https://img.mofyi.com/Ayto/image/20210902151846.jpg" controlslist="nodownload noremoteplayback" :disablePictureInPicture="true" controls></video>
                                <div class="full-btn"></div> -->
                                <div id="dplayer"></div>
                                <div class="v-play-btn" :class="!isPlay ? 'v-play-btn-show' : ''" @click="dp.play()">
                                    <i class="iconfont icon-play"></i>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </swiper-slide>
            <swiper-slide class="page3" key="page3">
                <div id="svg-bg3" class="svg-bg"></div>
                <div class="anima-bg3 anima-bg">
                    <template v-for="n of 10">
                        <div class="item animated" :class="ani3[n - 1] + ' item' + n" v-show="homeIndex == 3"  :key="n" :style="'background-image: url(https://habit-img-cdn.oss-cn-beijing.aliyuncs.com/Ayto/cbreani/3_'+ n +'.png)'"></div>
                    </template>
                </div>
                <transition name="slide-left">
                    <div class="nr-title" v-show="nrShow[2]">
                        <div>
                            <p class="title">
                                <span>{{ $t('home.intro') }}</span>
                            </p>
                            <div class="nr">
                                <p class="info">{{ $t('home.introText') }}</p>
                                <router-link to="/intro">{{ $t('home.enter') }}</router-link>
                            </div>
                        </div>
                    </div>
                </transition>
            </swiper-slide>
            <swiper-slide class="page4" key="page4">
                <div id="svg-bg4" class="svg-bg"></div>
                <div class="anima-bg4 anima-bg" v-show="!agendaShow">
                    <template v-for="n of 13">
                        <div class="item animated" :class="ani4[n - 1] + ' item' + n" v-show="homeIndex == 4"  :key="n" :style="'background-image: url(https://habit-img-cdn.oss-cn-beijing.aliyuncs.com/Ayto/cbreani/4_'+ n +'.png)'"></div>
                    </template>
                </div>
                <transition name="slide-left">
                    <div class="nr-title" v-show="nrShow[3] && !agendaShow">
                        <div>
                            <p class="time">2021.09.23</p>
                            <p class="title">
                                <span>{{ $t('home.agenda') }}</span>
                            </p>
                            <div class="nr">
                                <router-link to="/agenda">{{ $t('home.enter') }}</router-link>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="index-agenda-p" :class="agendaShow ? 'index-agenda-p-show' : ''">
                    <div class="crumbs">
                        <span style="color: #fff; cursor: pointer;" @click="agendaShow = false">返回</span>
                    </div>
                    <agenda-pc></agenda-pc>
                </div>
            </swiper-slide>
            <swiper-slide class="page5" key="page5">
                <div id="svg-bg5" class="svg-bg"></div>
                <div class="anima-bg5 anima-bg">
                    <template v-for="n of 3">
                        <div class="item" :class="'item' + n" v-show="homeIndex == 5"  :key="n"></div>
                    </template>
                </div>
                <transition name="slide-left">
                    <div class="nr-title" v-show="nrShow[4]">
                        <div>
                            <p class="title">
                                <span>{{ $t('home.speakers') }}</span>
                            </p>
                            <div class="nr">
                                <p class="info">{{ $t('home.speakersText') }}</p>
                                <router-link to="/speakers">{{ $t('home.enter') }}</router-link>
                            </div>
                        </div>
                    </div>
                </transition>
            </swiper-slide>
            <swiper-slide class="page6" key="page6">
                <div id="svg-bg6" class="svg-bg"></div>
                <div class="anima-bg6 anima-bg">
                    <template v-for="n of 4">
                        <div class="item run" :class="'item' + n" v-show="homeIndex == 6"  :key="n"></div>
                    </template>
                </div>
                <transition name="slide-left">
                    <div class="nr-title" v-show="nrShow[5]">
                        <div>
                            <p class="title">
                                <span>{{ $t('home.report') }}</span>
                            </p>
                            <div class="btn-p">
                                <p class="info">{{ $t('home.reportText') }}</p>
                                <router-link to="/report">
                                    <button class="theme-btn light"><i class="iconfont icon-danganziliao-mobanxiazai"></i>{{ $t('home.reportButtonText') }}</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </transition>
            </swiper-slide>
        </swiper>
        <div class="next-btn" :class="homeIndex === 6 ? 'prev-btn' : ''" @click="swiperNext">
            <i class="iconfont icon-arrow"></i>
        </div>
        <count-down class="home-time"></count-down>
        <global-tips></global-tips>
        <div class="canvas-bg-home">
            <canvas-bg></canvas-bg>
        </div>
        <div class="video-mask" :class="videoShow ? 'video-mask-show' : ''">
            <i @click="videoMaskHide" class="iconfont icon-close"></i>
            <div>
                <video ref="maskVideo" src="https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/bcf16bd03701925923649219991/v.f100040.mp4" x5-playsinline playsinline webkit-playsinline controlslist="nodownload noremoteplayback" :disablePictureInPicture="true" controls></video>
            </div>
        </div>
    </div>
</template>

<script>
import Vivus from 'vivus'
import p1svg from '@/assets/svg/p1.svg'
import p2svg from '@/assets/svg/p2.svg'
import p3svg from '@/assets/svg/p3.svg'
import p4svg from '@/assets/svg/p4.svg'
import p5svg from '@/assets/svg/p5.svg'
import p6svg from '@/assets/svg/p6.svg'
import { mapMutations, mapState } from 'vuex'
import CanvasBg from '@/components/CanvasBg'
import AgendaPc from '@/views/pages/agenda/AgendaPc'
import CountDown from '@/components/CountDown'
import GlobalTips from '@/components/GlobalTips'
import Dplayer from 'dplayer'
export default {
    name: "Home",
    computed: {
        ...mapState('main', ['homeIndex', 'homeRefreshNumber']),
        swiper() {
            return this.$refs.homeSwiper.swiper
        }
    },
    components: {
        CanvasBg,
        AgendaPc,
        CountDown,
        GlobalTips
    },
    data() {
        return {
            area: localStorage.getItem('area'),
            isPc: document.documentElement.clientWidth > 960,
            liveOpen: false,
            isPlay: false,
            kvAniShow: false,
            kvAniKeep: false,
            videoShow: false,
            autoNext: true,
            agendaShow: false,
            nrShow: [false, false, false, false, false, false],
            svgs: [p1svg, p2svg, p3svg, p4svg, p5svg, p6svg],
            vivus: [null, null, null, null, null, null],
            ani3: ['fadeInRightBig', 'fadeInLeftBig', 'fadeInRightBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig'],
            ani4: ['fadeInDownBig', 'fadeInDownBig', 'fadeInDownBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig', 'fadeInUpBig'],
            swiperOptions: {
                initialSlide: 0,
                direction : 'vertical',
                speed: 350,
                mousewheelControl: true,
                onInit: () => {
                    this.vivus.forEach((n, index) => {

                        if(!n){
                            this.vivusInit(index + 1)
                        }
                    })
                },
                onSlidePrevStart: () => {

                    const index = this.swiper.activeIndex
                    // 手机端跳过视频页
                    if(!this.isPc && index === 2){
                        this.swiper.slideTo(1, 350);
                    }
                },
                onSlideNextStart: () => {

                    const index = this.swiper.activeIndex
                    // 手机端跳过视频页
                    if(!this.isPc && index === 2){
                        this.swiper.slideTo(3, 350);
                    }
                },
                onTransitionStart: () => {
                    const index = this.swiper.activeIndex
                    if(index === 1){
                        this.swiper.lockSwipeToPrev()
                        this.kvAniShow = true
                    }else{
                        this.swiper.unlockSwipeToPrev()
                    }

                    this.setNavHidden(index > 2)
                    this.setHomeIndex(index)
                    if(index > 0){
                        // 判断是否已经初始化过
                        if(this.vivus[index -1]){
                            this.vivus.forEach(n => {
                                if(n){
                                    n.reset().stop()
                                }
                            })
                            this.vivus[index - 1].reset().play()
                        }
                    }
                    if(this.isPc){
                        // 如果有操作将取消自动跳转至视频页
                        if(index > 1){
                            this.autoNext = false
                        }
                        // 在日程页时倒计时打开日程详情
                        this.agendaShow = false
                        if(index == 4){
                            setTimeout(() => {
                                this.agendaShow = true
                            }, 2000)
                        }
                    }

                    setTimeout(() => {
                        for(var i = 0; i < this.nrShow.length; i++){
                            this.$set(this.nrShow, i, false)
                        }
                        this.$set(this.nrShow, index - 1, true)
                    }, 500)
                }
            }
        }
    },

    methods: {
        ...mapMutations('main', ['setNavHidden', 'setHomeIndex']),
        vivusInit(_index){
            this.vivus[_index - 1] = new Vivus('svg-bg' + _index, {
                file: this.svgs[_index - 1],
                type: 'oneByOne',
                duration: 80,
                reverseStack: false,
                onReady: () => {
                    // console.log('初始化完成 svg' + _index)
                }
            });
        },
        swiperNext(){
            if(this.homeIndex === 6){
                this.swiper.slidePrev()
            }else{
                this.swiper.slideNext()
            }
        },
        videoMaskShow(){
            this.videoShow = true
            this.$refs['maskVideo'].play()
        },
        videoMaskHide(){
            this.videoShow = false
            this.$refs['maskVideo'].pause()
        },
        kvTsnEnd(_index){
            if(_index == 23){
                this.kvAniKeep = true
            }
        },
        setLiveOpen(){
            let stamp = new Date().getTime()
            if(stamp >= this.activityStartTime){
                this.liveOpen = true
            }
        },
        dpInit(){
            this.dp = new Dplayer({
                container: document.getElementById('dplayer'),
                autoplay: false, // 自动播放
                preload: 'auto', // 预加载
                lang: localStorage.getItem('locale') == 'en' ? 'en' : 'zh-cn',
                contextmenu: [],
                video: {
                    url: 'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/32b8faac3701925923661477960/v.f100040.mp4',
                    pic : "https://img.mofyi.com/Ayto/image/20210902151846.jpg"
                },
                theme: '#09a557',
                danmaku: true,
                apiBackend: {
                    read: () => {
                        document.querySelector('.dplayer-danloading').style.display = 'none'
                        document.querySelector('.dplayer-comment').style.display = 'none'
                        document.querySelector('.dplayer-full-in-icon').style.display = 'none'
                        document.querySelector('.dplayer-setting').style.display = 'none'
                        document.querySelector('.dplayer-menu').remove()
                        document.querySelector('.dplayer-bezel-icon').remove()
                        document.querySelector('.dplayer-setting').remove()
                    }
                }
            })
            this.dp.on('play', () => {
                this.isPlay = true
            })
            this.dp.on('pause', () => {
                this.isPlay = false
            })

        },
    },
    watch: {
        homeRefreshNumber(){
            this.swiper.slideTo(1, 350);
        }
    },
    mounted() {

        this.$nextTick(() => {
            this.swiperNext()
            if(this.isPc){
                this.dpInit()
                setTimeout(() => {
                    if(this.autoNext){
                        this.swiperNext()
                    }
                }, 3000)
            }

        })
    },
};
</script>
<style lang="scss" scoped>

</style>
