/**
 * 麦斯聊天室
 * @param {int} room_id
 * @param {string} name
 */
function MiceChat(room_id, user_id, name, avatar) {
    var _this = this;
    // websocket
    var ws;
    // 事件列表
    var eventList = {};
    // 上次发消息时间
    var lastMessageTime = null
    // 执行事件
    var doEvent = function (event, data) {
        if (eventList[event]) {
            if (data) {
                eventList[event].apply(null, data);
            } else {
                eventList[event]();
            }
        }
    }

    // 心跳检测, 每隔一段时间检测连接状态，如果处于连接中，就向server端主动发送消息，来保持跟server的连接，如果断开就重新连接
    var heartCheck = {
        timeout         : 1000 * 40,
        serverTimeoutObj: null,
        reset           : function (is_next) {
            clearTimeout(this.timeoutObj);
            clearTimeout(this.serverTimeoutObj);

            //判断是否继续发送心跳包，false 不继续发送
            if(is_next){
                this.serverTimeoutObj = setInterval(function () {
                    var myDate = new Date();
                    console.log(myDate.toLocaleString());
                    if (ws.readyState == 1) {
                        ws.send(JSON.stringify({type: 0, room_id: room_id}));
                        heartCheck.reset(is_next);
                    } else {
                        _this.init();
                        console.log('重连');
                    }
                }, this.timeout)
            }
        }
    }

    // 初始化websocket连接
    this.init = function () {
        ws = null;
        ws = new WebSocket("wss://socket.micecube.com/ws?room_id=" + room_id + "&user_id=" + user_id);

        ws.onopen = function () {
            // 重置心跳检测
            heartCheck.reset(true);

            ws.send(JSON.stringify({ type: 3, room_id: room_id, name: name, avatar: avatar }));

            doEvent('connection');
        }

        ws.onmessage = function (evt) {
            // 重置心跳检测
            heartCheck.reset(true);

            var data = evt.data;
            data = JSON.parse(data);

            if (data.type == 1) {
                // 直播开始
                doEvent('liveStart');
            } else if (data.type == 2) {
                // 直播结束
                doEvent('liveEnd');
            } else if (data.type == 3) {
                // 有人进入直播间
                doEvent('peopleEnter', [data.num, data.user_id, data.name, data.avatar]);
            } else if (data.type == 4) {
                // 有人离开直播间
                doEvent('peopleLeave', [data.num, data.user_id]);
            } else if (data.type == 5) {
                // 直播间公告
                doEvent('topMessage', [data.data]);
            } else if (data.type == 6) {
                // 主持人后台发消息
                doEvent('hostMessage', [data.message, data.id]);
            } else if (data.type == 7) {
                // 用户前台发消息
                doEvent('message', [data.name, data.avatar, data.message, data.is_self, data.id]);
            } else if (data.type == 8) {
                // 点赞
                doEvent('like', [data.num]);
            } else if (data.type == 9) {
                // 消息发送失败
                doEvent('messageError', [data.message]);
            } else if (data.type == 10) {
                // 消息删除
                doEvent('messageDelete', [data.id]);
            } else if (data.type == 11) {
                // 图片更换
                doEvent('imageChange', [data.url]);
            } else if (data.type == 12) {
                // 打开相同直播间
                doEvent('reopen', []);
            } else if (data.type == 20) {
                // 获取当前在线用户
                doEvent('getOnlineUser', [data.list]);
            } else if (data.type == 21) {
                doEvent('getHistoryMessage', [data.list]);
            } else if (data.type == 50) {
                //other
                doEvent('other', [data]);
            }
        }

        ws.onerror = function (evt, e) {
            doEvent('error', [e]);
        }

        ws.onclose = function () {
            doEvent('close');
        }
    }

    // 绑定事件
    this.on = function (event, callback) {
        var list = ['connection', 'message', 'liveStart', 'liveEnd', 'peopleEnter', 'peopleLeave', 'topMessage', 'hostMessage', 'message', 'like', 'error', 'close', 'messageError', 'messageDelete', 'imageChange', 'getOnlineUser', 'getHistoryMessage', 'reopen', 'other'];
        if (list.indexOf(event) == -1) {
            return false;
        }

        eventList[event] = callback;

        return this;
    }

    // 用户发消息
    this.sendMessage = function (message) {
        // 3秒钟内不可重复发消息
        var time = new Date().getTime();
        if (time < lastMessageTime + 3000) {
            doEvent('messageError', ['发的太快了，请稍后再试'])
            return false
        }
        this.lastMessageTime = time;

        var data = JSON.stringify({
            type: 7,
            room_id: room_id,
            name: name,
            avatar: avatar,
            message: message
        });
        ws.send(data);
    }

    //关闭socket连接 处理
    this.close = function () {
        //停止心跳包定时器
        heartCheck.reset(false);
        console.log('执行关闭');
        //关闭socket连接
        ws.close(1000);
    }

    this.like = function () {
        var data = JSON.stringify({
            type: 8,
            room_id: room_id,
        });
        ws.send(data);
    }

    this.getOnlineUser = function () {
        var data = JSON.stringify({
            type: 20,
            room_id: room_id,
        });
        ws.send(data);
    }

    this.getHistoryMessage = function (type) {
        var time = null
        if (type == 1) {
            time = '12'
        } else {
            time = 'all'
        }
        var data = JSON.stringify({
            type: 21,
            room_id: room_id,
            time: time,
        });
        ws.send(data);
    }

    this.getImage = function () {
        var send = JSON.stringify({
            type: 11,
            room_id: room_id,
        });
        ws.send(send);
    }
    this.onOther = function (data) {
        var send = JSON.stringify({
            type: 50,
            room_id: room_id,
            data: data
        });
        ws.send(send);
    }
}

export default MiceChat
