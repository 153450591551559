import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import '@babel/polyfill'
import "core-js/stable"
import 'regenerator-runtime/runtime';

Vue.use(ElementUI);

import dayjs from 'dayjs'
Vue.prototype.$day = dayjs

import '@/style/element-variables.scss'


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

import i18n from './i18n/i18n'

import rem from '@/assets/js/rem.js'
Vue.prototype.$rem = rem

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import CbreAlert from '@/assets/js/cbreAlert.js'
Vue.prototype.$cbreAlert = CbreAlert


// import VueDPlayer from "vue-dplayer";
// import "vue-dplayer/dist/vue-dplayer.css";
// Vue.use(VueDPlayer);

process.env.NODE_ENV === 'development' ? Vue.prototype.HOST = '/api' : Vue.prototype.HOST = ''

import '@/style/main.scss'
Vue.config.productionTip = false
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')


