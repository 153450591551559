<template>
    <div class="nav-phone" :class="navPhoneShow ? 'nav-phone-show' : ''">
        <div class="navs">
            <ul>
                <li v-for="(item, index) of navs" :key="item.title">
                    <router-link class="link" :class="$route.path === item.url ? 'active' : ''" :to="item.url">
                        {{ item.title }}
                        <span class="num">0{{ index + 1}}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <template v-if="token">
            <div class="login-type">
                <div class="line1">
                    <div class="icon"><i class="iconfont icon-user"></i></div>
                    <p>{{ $t('header.welcome') }}, {{ userInfo.name }}</p>
                </div>
                <button @click="logout" class="theme-btn dark">{{ $t('header.logout') }}</button>
            </div>
        </template>
        <template v-else>
            <div class="not-login-type">
                <div class="btns">
                    <router-link to="/login"><button class="theme-btn light">{{ $t('header.login') }}</button></router-link>
                    <router-link to="/register"><button class="theme-btn dark">{{ $t('header.register') }}</button></router-link>
                </div>
            </div>
        </template>
        <div class="footer-phone">
            <div class="share">
                <div class="name">{{ $t('footer.share') }}: </div>
                <ul>
                    <li>
                        <span @click="wxShow = true"><i class="fa fa-fw fa-wechat"></i></span>
                    </li>
                     <li>
                        <a href="https://www.facebook.com/CBRETAIWAN/"><i class="fa fa-fw fa-facebook"></i></a>
                    </li>
                     <li>
                        <a href="https://www.linkedin.com/company/cbre-asia-pacific/"><i class="fa fa-fw fa-linkedin"></i></a>
                    </li>
                     <li>
                        <a href="https://www.instagram.com/cbre_hongkong/"><i class="iconfont icon-instagram"></i></a>
                    </li>
                </ul>
            </div>
            <ul class="footer-links">
                <li v-for="item of links" :key="item.title">
                    <router-link v-if="item.internal == 1 && item.target === '_self'" :to="item.url">{{ item.title }}</router-link>
                    <a v-else :target="item.target" :href="item.url">{{ item.title }}</a>
                </li>
            </ul>
        </div>

        <div class="wx-share" v-show="wxShow">
            <i class="iconfont icon-close" @click="wxShow = false"></i>
            <div>
                <img src="https://www.micecube.com/qrcode?content=https://www.cbreforum.com/" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
    computed: {
        ...mapState('main', ['token', 'liveEntranceShow', 'navHidden', 'homeIndex', 'liveData', 'userInfo', 'navPhoneShow']),
    },
    data(){
        return {
            wxShow: false,
            navs: [
                {
                    id: 1,
                    title: this.$t('nav.home'),
                    url: '/'
                },
                {
                    id: 2,
                    title: this.$t('nav.intro'),
                    url: '/intro'
                },
                {
                    id: 3,
                    title: this.$t('nav.agenda'),
                    url: '/agenda'
                },
                {
                    id: 4,
                    title: this.$t('nav.speakers'),
                    url: '/speakers'
                },
                {
                    id: 5,
                    title: this.$t('nav.report'),
                    url: '/report'
                }
            ],
            links: [
                {
                    title: this.$t('footer.about'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com.cn/zh-cn/about'
                },
                {
                    title: this.$t('footer.contact'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com.cn/zh-cn/about/contact-us'
                },
                {
                    title: this.$t('footer.faq'),
                    internal: 1, // 是否为内部链接
                    target: '_self', // 是否打开新链接
                    url: '/faq'
                },
                {
                    title: this.$t('footer.disclaimer'),
                    internal: 1, // 是否为内部链接
                    target: '_self', // 是否打开新链接
                    url: '/disclaimer'
                },
                {
                    title: this.$t('footer.terms'),
                    internal: 1, // 是否为内部链接
                    target: '_blank', // 是否打开新链接
                    url: 'https://www.cbre.com/about/disclaimer-terms-of-use'
                }
            ]
        }
    },
    watch: {
        navPhoneShow(){
            this.wxShow = false
        }
    },
    methods: {
        ...mapMutations('main', ['setToken', 'setUserInfo']),
        logout(){
            localStorage.removeItem('token')
            this.setToken(null)
            localStorage.removeItem('user_info')
            this.setUserInfo({})

             this.$cbreAlert({
                message: this.$t('other.logoutSuccess'),
                icon: 'check'
            })
            this.$router.push('/')
        },
    },
}
</script>
