import $ from 'jquery'
function cbreAlert(_options){
    let stamp = new Date().getTime()
    let html = '<div class="cbre-alert" id="cbre-alert-'+ stamp +'"> <div class="close"> <i class="iconfont icon-close"></i> </div> <div class="icon"> <i class="iconfont icon-'+ _options.icon +'"></i> </div> <p class="text">'+ _options.message +'</p> </div>'
    $('.main').append(html)
    $('#cbre-alert-'+ stamp +' > .close').on('click', function(){
        $(this).parent().remove()
    })

    setTimeout(function(){
        $('#cbre-alert-' + stamp).remove()
    }, 5000)
}

export default cbreAlert
