<template>
    <div class="live-block-phone local-block-phone">
        <div class="live-video">
            <div id="dplayer"></div>
        </div>
        <div class="local-message">
            <global-tips class="local-mobile-tips"></global-tips>
        </div>
        <div class="local-content">

            <!-- 关联直播间 -->
            <div class="lives-area-local">
                <template v-for="(item, index) of lives">
                    <template v-if="index + 1 == $route.params.id">
                        <div class="item active" :key="item.title">
                            <div class="cover">
                                <p class="title">{{ item.title }}</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <a v-if="index == 9" target="_blank" href="https://apac.onlinexperiences.com/Launch/Event/ShowKey=3124" class="item" :key="item.title">
                            <div class="cover">
                                <p class="title">{{ item.title }}</p>
                            </div>
                        </a>
                        <router-link v-else class="item" :key="item.title" :to="'/local/' + (index + 1)">
                            <div class="cover">
                                <p class="title">{{ item.title }}</p>
                            </div>
                        </router-link>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import Dplayer from 'dplayer'
import { mapState } from 'vuex'
import GlobalTips from '@/components/GlobalTips'
export default {
    props: {
        videoUrl: String
    },
    computed:{
        ...mapState('main', ['liveData', 'userInfo', 'token'])
    },
     components: {
        GlobalTips
    },
    data(){
        return {
            aeraShow: 0,
            // 聊天内容
            message: '',
            // video和ppt的位置是否互换
            positionChange: false,
            dp: null,
            miceChat: null,
            emojiShow: false,
            emojis: [ '😀', '😃', '😄', '😁', '😆', '😉', '😊', '😇', '😍', '🤩', '😘', '😗', '😚', '😙', '😋', '😛', '😜', '😝', '🤑', '🤗', '🥳', '😎', '🤓', '🧐', '🤭', '👍','🚀️','❤️',],
            image: 'https://img.mofyi.com/202108/1015282532081.gif',
            lives: [],
            comments: []
        }
    },
    methods: {
        dpInit(){
            this.dp = new Dplayer({
                container: document.getElementById('dplayer'),
                autoplay: true, // 自动播放
                preload: 'auto', // 预加载
                lang: localStorage.getItem('locale') == 'en' ? 'en' : 'zh-cn',
                contextmenu: [],
                video: {
                    url: this.videoUrl
                },
                pluginOptions: {

                },
                theme: '#09a557',
                // live: true,
                danmaku: true,
                apiBackend: {
                    read: function () {
                        document.querySelector('.dplayer-danloading').style.display = 'none'
                        document.querySelector('.dplayer-comment').style.display = 'none'
                        document.querySelector('.dplayer-full-in-icon').style.display = 'none'
                        document.querySelector('.dplayer-setting').style.display = 'none'
                        document.querySelector('.dplayer-menu').remove()
                        document.querySelector('.dplayer-setting').remove()

                        // $('.dplayer-video').attr('playsinline', true)
                        // $('.dplayer-video').attr('webkit-playsinline', true)
                        // $('.dplayer-video').attr('x5-playsinline', true)
                        // $('.pc-video-block-vice .dplayer-controller').hide()
                    }
                },
                mutex: false
            })
            this.dp.on('quality_start', () => {

                this.dp.notice(this.$t('live.switching'))
            })
            this.dp.on('quality_end', () => {

                this.dp.notice(this.$t('live.switched'))
            })
            this.dp.play()
        },
        setLives(){
            let local = this.$t('local.items')
            console.log(local)
            for(var i in local){
                this.lives.push({
                    title: local[i]
                })
            }
        }

    },
    mounted() {
        this.dpInit()
        this.setLives()
    },
    destroyed() {
        // console.log('离开直播')
    },
}
</script>
