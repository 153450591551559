<template>
    <div class="content agenda-content">
        <div class="crumbs">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <i class="fa fa-caret-right"></i>
            <span>{{ $t('nav.agenda') }}</span>
        </div>
        <agenda-pc></agenda-pc>
        <agenda-phone></agenda-phone>
    </div>
</template>
<script>
import AgendaPc from '@/views/pages/agenda/AgendaPc'
import AgendaPhone from '@/views/pages/agenda/AgendaPhone'

export default {
    components: {
        AgendaPc,
        AgendaPhone
    },
    data(){
        return {

        }
    },
    methods: {

    },
}
</script>
