import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './i18nData'

Vue.use(VueI18n)

const getBrowserLang = function() {
    let browserLang = navigator.language ? navigator.language: navigator.browserLanguage
    let defaultBrowserLang = ''
    if(browserLang.toLowerCase().indexOf('zh') > -1 || browserLang.toLowerCase().indexOf('cn') > -1){
        defaultBrowserLang = 'zh'
    }if(browserLang.toLowerCase().indexOf('en') > -1){
        defaultBrowserLang = 'en'
    } else {
        defaultBrowserLang = 'zh'
    }
    return defaultBrowserLang
}

const locale = localStorage.getItem('locale') || getBrowserLang() || 'zh'

if(!localStorage.getItem('locale')){
    localStorage.setItem('locale', locale)
}
export default new VueI18n({
    locale,
    messages
})
