<template>
    <div class="content">
        <div class="window">
            <div class="crumbs">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <i class="fa fa-caret-right"></i>
                <span>{{ $t('header.login') }}</span>
            </div>
            <div class="window-main">
                <div class="close-btn" @click="goBack"><i class="iconfont icon-close"></i></div>
                <div class="login-block">
                    <div class="title">
                        <h3>{{ $t('user.loginTitle') }}</h3>
                        <h4>{{ $t('user.title') }}</h4>
                    </div>
                    <div class="login-form">
                        <cbre-input class="email-input" :title="$t('user.email')">
                            <input type="text" @keydown.enter="submit" v-model="formData.email" autocomplete="off">
                        </cbre-input>
                        <p class="text">{{ $t('user.loginText') }}</p>
                        <div class="btn-line">
                            <button class="theme-btn light" :class="btnDisable ? 'disable' : ''" :disabled="btnDisable" @click="submit">{{ $t('user.loginSubmit') }}</button>
                             <i18n path="user.registerTips" tag="span" for="user.registerTips0">
                                <router-link to="/register">{{ $t('user.registerTips0') }}</router-link>
                            </i18n>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="canvas-bg-login">
            <canvas-bg></canvas-bg>
        </div>
    </div>
</template>
<script>
    import CbreInput from '@/components/CbreInput'
    import { mapState, mapMutations } from 'vuex'
    import CanvasBg from '@/components/CanvasBg'
    export default {
        data() {
            return {
                formData: {
                    email: ''
                }
            }
        },
        computed: {
            ...mapState('main', ['prevRouterName']),
            btnDisable(){
                let bol = false
                const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
                if(!reg.test(this.formData['email'])){
                    bol = true
                }
                return bol
            }
        },
        components: {
            CbreInput,
            CanvasBg
        },
        methods: {
            ...mapMutations('main', ['setToken', 'setUserInfo']),
            goBack() {
                if(this.prevRouterName == 'Report' || this.prevRouterName == 'ReportTest'){
                    this.$router.push('/')
                    return
                }
                this.prevRouterName ? this.$router.go(-1) : this.$router.push('/')
            },
            submit(){
                this.$http.post(this.HOST + '/cbre2021/login', this.formData).then(res => {
                    const data = res.data
                    if(data.code == 1){
                        this.$cbreAlert({
                            message: this.$t('other.loginSuccess'),
                            icon: 'check'
                        })
                        localStorage.setItem('token', data.token)
                        this.setToken(data.token)
                        localStorage.setItem('user_info', JSON.stringify(data.data))
                        this.setUserInfo(data.data)
                        setTimeout(() => {
                            this.$router.push('/')
                        }, 1000)
                    }else{
                        this.$cbreAlert({
                            message: this.$t('other.loginError'),
                            icon: 'warning'
                        })
                    }
                }).catch(() => {
                    this.$cbreAlert({
                        message: this.$t('other.reqError'),
                        icon: 'warning'
                    })
                })
            }
        },
    }
</script>
