<template>
    <div class="content">
        <div class="window">
            <div class="crumbs">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <i class="fa fa-caret-right"></i>
                <span>{{ $t('footer.disclaimer') }}</span>
            </div>
            <div class="window-main">
                <div class="close-btn" @click="goBack"><i class="iconfont icon-close"></i></div>
                <div class="disclaimer-block">
                    <h3>{{ $t('disclaimer.title') }}</h3>
                    <div class="disclaimer-conetnt">{{ $t('disclaimer.content') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        computed:{
            ...mapState('main', ['prevRouterName'])
        },
        methods: {
            goBack() {
                this.prevRouterName ? this.$router.go(-1) : this.$router.push('/')
            }
        },
    }
</script>
