<template>
    <div class="content">
        <div class="window">
            <div class="crumbs">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <i class="fa fa-caret-right"></i>
                <span>{{ $t('footer.faq') }}</span>
            </div>
            <div class="window-main">
                <div class="close-btn" @click="goBack"><i class="iconfont icon-close"></i></div>
                <div class="faq-block">
                    <h3>FAQ</h3>
                    <div class="faq-conetnt">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item v-for="(n, index) of faqData" :key="index" :name="index + 1">
                                <template slot="title">
                                    <div class="title">{{ n.title }} <i class="header-icon fa fa-fw" :class="activeNames.indexOf(index + 1) > -1 ? 'fa-minus' : 'fa-plus'"></i></div>
                                </template>
                                <div>{{ n.content }}</div>
                            </el-collapse-item>
                        </el-collapse>
                        <p class="more-line">{{ $t('faq.more') }} <a href="mailto:marcomms@cbre.com.cn">marcomms@cbre.com.cn.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    export default {
        data() {
            return {
                 activeNames: [1],
                 faqData: []
            }
        },
        computed:{
            ...mapState('main', ['prevRouterName'])
        },
        methods: {
            faqDataInit(){
                const problem = this.$t('faq.problem')
                const answer = this.$t('faq.answer')
                for(var i in problem){
                    this.faqData.push({
                        title: problem[i],
                        content: answer[i]
                    })
                }
            },
            goBack() {
                this.prevRouterName ? this.$router.go(-1) : this.$router.push('/')
            }
        },
        mounted() {
            this.faqDataInit()
        },
    }
</script>
