<template>
    <div class="speakers-block">
        <div class="speaker-detail">
            <div class="left">
                <div class="imgs">
                    <img v-for="(n, index) of speakers" :key="'avatar' + index" :class="activeIndex == index ? 'img-show' : ''" :src="n.photo">
                </div>
            </div>
            <div class="right">
                <div>
                    <p class="name">{{ speakers[activeIndex]['name_' + locale] }}</p>
                    <p class="intro">{{ speakers[activeIndex]['intro_' + locale] }}</p>
                    <p class="info">{{ speakers[activeIndex]['info_' + locale] }}</p>
                </div>
            </div>
        </div>
        <swiper class="speakers-siwper" ref="speakersSwiper" :options="swiperOptions">
            <swiper-slide v-for="(n, index) of speakers" :key="'speaker' + index">
                <div class="cover">
                    <div class="avatar" :style="'background-image: url('+ n.cover +')'"></div>
                    <p class="name">{{ n['name_' + locale] }}</p>
                    <p class="info"><span>{{ n['intro_' + locale] }}</span></p>
                    <i class="iconfont icon-triangle-bottom"></i>
                </div>
            </swiper-slide>
        </swiper>
        <i class="iconfont icon-left" @click="swiperPrev"></i>
        <i class="iconfont icon-right" @click="swiperNext"></i>
    </div>
</template>
<script>
export default {
    props: {
        speakers: Array
    },
    computed: {
        swiper() {
            return this.$refs.speakersSwiper.swiper
        }
    },
    data(){
        return {
            locale: localStorage.getItem('locale'),
            activeIndex: 0,
            swiperOptions: {
                slidesPerView: 5,
                loop: true,
                speed: 350,
                slideToClickedSlide: true,
                onInit: (swiper) => {
                    if(this.$route.query.index !== undefined){
                        const index = this.$route.query.index
                        this.activeIndex = index - 1
                        let toIndex = Number(index) + 4
                        if(toIndex > this.speakers.length){
                            toIndex = toIndex - this.speakers.length
                        }
                        swiper.slideTo(toIndex, 0)
                    }

                },
                onTransitionStart: () => {
                    if(this.$refs.speakersSwiper.swiper){
                        const index = this.$refs.speakersSwiper.swiper.realIndex
                        this.activeIndex = index
                    }
                }
            }
        }
    },
    methods: {
        swiperPrev(){
            const swiper = this.$refs.speakersSwiper.swiper
            swiper.slidePrev()
        },
        swiperNext(){
            const swiper = this.$refs.speakersSwiper.swiper
            swiper.slideNext()
        }
    },
    mounted() {

    },
}
</script>
