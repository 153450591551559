<template>
    <div class="speakers-block-phone">
         <swiper class="speakers-siwper" v-if="dataReady" ref="speakersSwiper" :options="swiperOptions">
            <swiper-slide v-for="(n, index) of speakers" :key="'speaker' + index">
                <div class="cover">
                    <div class="avatar" :style="'background-image: url('+ n.photo +')'"></div>
                    <div class="line1">
                        <p class="name">{{ n['name_' + locale] }}</p>
                        <p class="info">{{ n['intro_' + locale] }}</p>
                    </div>
                    <div class="line2">
                        <p class="name">{{ n['info_' + locale] }}</p>
                    </div>
                </div>
            </swiper-slide>
            <div class="speakers-phone-pagination" slot="pagination"></div>
        </swiper>
        <i class="iconfont swiper-icon icon-left" @click="swiperPrev"></i>
        <i class="iconfont swiper-icon icon-right" @click="swiperNext"></i>
    </div>
</template>
<script>
export default {
     props: {
        speakers: Array
    },
    computed: {
        swiper() {
            return this.$refs.speakersSwiper.swiper
        }
    },
    data(){
        return {
            locale: localStorage.getItem('locale'),
            activeIndex: 0,
            dataReady: true,
            swiperOptions: {
                loop: true,
                speed: 350,
                autoHeight: true, //高度随内容变化
                pagination: {
                    el: '.speakers-phone-pagination'
                },
                onInit: (swiper) => {
                    const index = this.$route.query.index
                    this.activeIndex = index - 1
                    swiper.slideTo(index, 0)
                },
                onTransitionStart: () => {
                    if(this.$refs.speakersSwiper.swiper){
                        const index = this.$refs.speakersSwiper.swiper.realIndex
                        this.activeIndex = index
                    }
                }
            },
        }
    },
    methods: {
        swiperPrev(){
            const swiper = this.$refs.speakersSwiper.swiper
            swiper.slidePrev()
        },
        swiperNext(){
            const swiper = this.$refs.speakersSwiper.swiper
            swiper.slideNext()
        }
    },
    mounted() {

    }
}
</script>
