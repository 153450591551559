<template>
    <div class="header" :class="headerClass">
        <div class="logo" @click="reload">
            <img :src="bgStyle === 'white' ? 'https://img.mofyi.com/Ayto/image/20210810095933.png' : 'https://img.mofyi.com/Ayto/image/20210802103744.png'" alt="">
        </div>
        <div class="center">
            <div v-show="isLive" class="live-img">
                <img src="https://img.mofyi.com/Ayto/image/20210811140001.png" alt="">
            </div>
            <count-down v-show="!isLive"></count-down>
        </div>
        <div class="right">
            <div class="user">
                <template v-if="token">
                    <div class="login-type">
                        <div class="icon"><i class="iconfont icon-user"></i></div>
                        <p>{{ $t('header.welcome') }}, {{ userInfo.name }}</p>
                        <button @click="logout" class="theme-btn dark">{{ $t('header.logout') }}</button>
                    </div>
                </template>
                <template v-else>
                    <div class="btns">
                        <router-link to="/login"><button class="theme-btn light">{{ $t('header.login') }}</button></router-link>
                        <router-link to="/register"><button class="theme-btn dark">{{ $t('header.register') }}</button></router-link>
                    </div>
                </template>
            </div>
            <div class="lang">
                <div class="btns">
                    <button class="theme-btn dark" @click="langSwitch('en')">EN</button>
                    <button class="theme-btn border" @click="langSwitch('zh')">中</button>
                </div>
            </div>
        </div>
        <div class="phone-right">
            <span @click="langToggle">{{ locale === 'en' ? '中' : 'EN' }}</span>
            <i class="iconfont icon-Homehomepagemenu home-icon" @click="goHome"></i>
            <i class="iconfont menu-icon" :class="navPhoneShow ? 'icon-close2' : 'icon-caidan'" @click="navPhoneToggle"></i>
        </div>

    </div>
</template>
<script>

import { mapMutations, mapState } from 'vuex';
import CountDown from '@/components/CountDown'

export default {
    computed: {
        ...mapState('main', ['bgStyle', 'isLive', 'token', 'userInfo', 'navPhoneShow'])
    },
    components: {
        CountDown
    },
    data(){
        return{
            st: 0,
            langs: ['zh', 'en'],
            headerClass: {
                'header-scroll': false,
                'header-scroll-show': false,
            },

            locale: localStorage.getItem('locale')
        }
    },
    methods: {
        ...mapMutations('main', ['setToken', 'setUserInfo', 'setNavPhoneShow']),
        // 语言切换
        langSwitch(_data){
            localStorage.setItem('locale', _data)
            window.location.reload()
        },
        reload(){
            this.$router.push('/')
        },
        logout(){
            localStorage.removeItem('token')
            this.setToken(null)
            localStorage.removeItem('user_info')
            this.setUserInfo({})

             this.$cbreAlert({
                message: this.$t('other.logoutSuccess'),
                icon: 'check'
            })
            this.$router.push('/')
        },
        navPhoneToggle(){
            const bol = !this.navPhoneShow
            this.setNavPhoneShow(bol)
        },
        langToggle(){
            const lang = this.locale === 'en' ? 'zh' : 'en'
            localStorage.setItem('locale', lang)
            window.location.reload()
        },
        goHome(){
            if(this.$route.name == 'Home'){
                window.location.reload()
            }else{
                this.$router.push('/')
            }
        }
    },
    mounted() {


        this.st = document.documentElement.scrollTop || document.body.scrollTop
        let self = this
        let stArr = [0, 0]
        let bol = false
        window.addEventListener('scroll', function(){

            if(bol){
                return false
            }
            bol = true
            self.st = document.documentElement.scrollTop || document.body.scrollTop
            stArr.push(self.st)
            stArr.shift()
            if(self.st >= 20){
                if(stArr[0] < stArr[1]){
                    // 向下
                    self.headerClass['header-scroll'] = true
                    self.headerClass['header-scroll-show'] = false
                }else{
                    // 向上
                    self.headerClass['header-scroll-show'] = true
                }
            }else{
                self.headerClass['header-scroll'] = false
                self.headerClass['header-scroll-show'] = false
            }
            setTimeout(() => {
                bol = false
            }, 50)

        })
    },
}
</script>
