<template>
    <div class="content live-content">
        <div class="crumbs">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <i class="fa fa-caret-right"></i>
            <span>{{ $t('nav.local') }}</span>
        </div>
        <local-pc v-if="isPc" :video-url="videos[this.$route.params.id - 1]"></local-pc>
        <local-phone v-else :video-url="videos[this.$route.params.id - 1]"></local-phone>
        <div class="canvas-bg-live">
            <canvas-bg></canvas-bg>
        </div>
    </div>
</template>
<script>
window.Hls = require('hls.js');
import CanvasBg from '@/components/CanvasBg'
import LocalPc from './local/LocalPc'
import LocalPhone from './local/LocalPhone'
export default {
    computed:{

    },
    components: {
        CanvasBg,
        LocalPc,
        LocalPhone
    },
    data(){
        return {
            isPc: document.documentElement.clientWidth > 960,
            videos: [
                // 北京
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/3c3e62293701925924810740949/v.f100040.mp4',
                // 上海
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/6e8503043701925924743503474/v.f100040.mp4',
                // 杭州
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/3e72072a3701925924810825586/v.f100040.mp4',
                // 南京
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/e5c01af53701925924819299727/v.f100040.mp4',
                // 武汉
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/3e4d49c63701925924810804585/v.f100040.mp4',
                // 西安郑州
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/d89c4a523701925924812854869/v.f100040.mp4',
                // 西南
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/ec29d5443701925924819510472/v.f100040.mp4',
                // 广州
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/78c0c9403701925924811021497/v.f100040.mp4',
                // 深圳
                'https://tvod.mofyi.com/dd73b8c3vodtransgzp1253238220/3e4dc4be3701925924810805153/v.f100040.mp4',
            ]
        }
    },
    methods: {

    },
    mounted() {

    },
    destroyed() {
        // console.log('离开直播')
    },
}
</script>
