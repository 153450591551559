<template>
    <div id="app" class="wrap" :class="bgStyle === 'white' ? 'white-wrap' : ''">
        <router-view />
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState('main', ['bgStyle'])
    }
};
</script>
<style lang="scss">

</style>
