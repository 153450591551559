export default {
    // 设置全局背景样式
    setBgStyle(state, _data){
        state.bgStyle = _data
    },
    setIsLive(state, _data){
        state.isLive = _data
    },
    setNavHidden(state, _data){
        state.navHidden = _data
    },
    setHomeIndex(state, _number){
        state.homeIndex = _number
    },
    // 设置是否显示直播间入口
    setLiveEntranceShow(state, _bol){
        state.liveEntranceShow = _bol
    },
    // 设置token与userInfo
    setToken(state, _str){
        state.token = _str
    },
    setUserInfo(state, _data){
        state.userInfo = _data
    },
    // 设置手机菜单是否显示
    setNavPhoneShow(state, _bol){
        state.navPhoneShow = _bol
    },
    setPrevRouterName(state, _data){
        state.prevRouterName = _data
    },
    setHomeRefreshNumber(state){
        state.homeRefreshNumber ++
    }
};
